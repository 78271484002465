import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BitResource} from '../bits.models';
import {PreparationNoteBit} from './preparation-note.models';

@Component({
  selector: 'bitmark-preparation-note',
  templateUrl: './preparation-note.component.html',
  styleUrls: ['../bits.scss']
})
export class PreparationNoteComponent {
  @Input() bit?: PreparationNoteBit;
  @Input() readOnly = false;
  @Input() hasFeedback = false;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() changed = new EventEmitter<any>();

  change(evt: Event) {
    const answer = (evt.target as any)?.value;
    this.bit.answer = {text: answer};
    this.changed.emit();
  }
}
