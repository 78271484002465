// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resource-container {
  padding: var(--bitmark-resource-video-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-resource-video-size-margin, var(--bitmark-x-size-margin, 0));
  line-height: 0;
}

.resource-video-caption {
  font-family: var(--bitmark-resource-video-caption-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-resource-video-caption-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-resource-video-caption-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-resource-video-caption-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-resource-video-caption-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-resource-video-caption-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-resource-video-caption-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-resource-video-caption-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-resource-video-caption-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-resource-video-caption-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
  margin: var(--bitmark-resource-video-caption-size-margin, var(--bitmark-x-size-margin, 0));
  padding: var(--bitmark-resource-video-caption-size-padding, var(--bitmark-x-size-padding, 0));
}

.play-button {
  border-top: var(--bitmark-resource-video-play-button-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-resource-video-play-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-resource-video-play-button-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-resource-video-play-button-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-resource-video-play-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-resource-video-play-button-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-resource-video-play-button-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-resource-video-play-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-resource-video-play-button-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-resource-video-play-button-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-resource-video-play-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-resource-video-play-button-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-resource-video-play-button-border-radius, var(--bitmark-x-border-radius, y));
  color: var(--bitmark-resource-video-play-button-color);
}

.play-button-icon-play {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bitmark-resource-video-play-button-icon-color, transparent);
}

.error {
  border-radius: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
