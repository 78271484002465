import {Mark, mergeAttributes} from '@tiptap/core';
import {MarkedOptions} from '../marked/marked.node';

export const FootnoteNode = Mark.create<MarkedOptions>({
  name: 'footnote',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [
      {
        tag: 'sup[class="bitmark-text-footnote"]'
      }
    ]
  },

  renderHTML({HTMLAttributes}) {
    const customAttributes = {
      class: 'bitmark-text-footnote'
    };
    return ['sup', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, customAttributes), 0];
  }
});
