import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MultipleChoiceBit, MultipleChoiceOption, MultipleChoiceQuiz} from './multiple-choice.models';
import {BitResource} from '../bits.models';
import {BitEvalResult} from "../bit-eval.service";

@Component({
  selector: 'bitmark-multiple-choice',
  templateUrl: './multiple-choice.component.html',
  styleUrls: ['./multiple-choice.component.scss', '../bits.scss']
})
export class MultipleChoiceComponent implements OnInit, OnChanges {
  @Input() bit?: MultipleChoiceBit;
  @Input() bitId: string;
  @Input() readOnly = false;
  @Input() hasFeedback = false;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() changed = new EventEmitter<any>();

  answersCss = {};

  ngOnInit() {
    this.initializeExample();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hasFeedback']) {
      this.bit.quizzes?.forEach((q: MultipleChoiceQuiz, idx: number) => {
        q.choices?.forEach((c: MultipleChoiceOption, cidx: number) => {
          this.answersCss[idx * 10 + cidx] = this.handleFeedback(q.answer?.choice, c, this.hasFeedback);
        });
      });
    }

    if (changes['hasFeedback']?.previousValue && !changes['hasFeedback']?.currentValue) {
      this.initializeExample();
    }
  }

  setQuizAnswer(quiz: MultipleChoiceQuiz, selectedChoice: string) {
    quiz.answer = {choice: selectedChoice};
    this.changed.emit();
  }

  private initializeExample() {
    if (!this.bit.isExample) {
      return;
    }

    this.bit.quizzes?.forEach((q: MultipleChoiceQuiz, idx: number) => {
      if (q.isExample) {
        q.choices?.forEach((c: MultipleChoiceOption, cidx: number) => {
          if (c.example) {
            q.answer = {choice: c.choice};
          }
          this.answersCss[idx * 10 + cidx] = this.handleFeedback(q.answer?.choice, c, q.isExample);
        });
      }
    });
  }

  private handleFeedback(response: string, c: MultipleChoiceOption, responseHasFeedback: boolean) {
    return !responseHasFeedback
      ? ''
      : c.isCorrect
        ? 'correct'
        : !c.isCorrect && response === c.choice
          ? 'incorrect'
          : '';
  }

  static evalAnswer(bit: MultipleChoiceBit): BitEvalResult {
    return {
      totalAnswers: bit.quizzes.length,
      answeredAnswers: bit.quizzes.filter(p => !!p.answer?.choice).length,
      notAnsweredAnswers: bit.quizzes.filter(p => !p.answer?.choice).length,
      correctAnswers: bit.quizzes.filter(p => p.answer?.choice && p.choices.filter(c => c.isCorrect).map(c => c.choice).includes(p.answer.choice)).length,
      incorrectAnswers: bit.quizzes.filter(p => p.answer?.choice && !p.choices.filter(c => c.isCorrect).map(c => c.choice).includes(p.answer.choice)).length,
    };
  }
}
