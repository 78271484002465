// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  word-break: break-word;
}

.bit-container[lang] {
  -webkit-hyphens: auto;
          hyphens: auto;
}

.feedback-button-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.footnotes {
  display: grid;
  grid-template-columns: auto auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  row-gap: 5px;
  background-color: var(--bitmark-footnotes-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-footnotes-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-footnotes-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-footnotes-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-footnotes-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-footnotes-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-footnotes-background-animation-name) var(--bitmark-footnotes-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-footnotes-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-footnotes-background-backdrop-filter);
  width: var(--bitmark-footnotes-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-footnotes-size-height, var(--bitmark-x-size-height, inherit));
  margin: var(--bitmark-footnotes-size-margin, var(--bitmark-x-size-margin, 10px 0 0 0));
  padding: var(--bitmark-footnotes-size-padding, var(--bitmark-x-size-padding, 0));
  font-family: var(--bitmark-footnotes-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-footnotes-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-footnotes-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-footnotes-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-footnotes-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-footnotes-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-footnotes-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-footnotes-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-footnotes-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-footnotes-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
  border-top: var(--bitmark-footnotes-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-footnotes-border-style, var(--bitmark-x-border-style)) var(--bitmark-footnotes-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-footnotes-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-footnotes-border-style, var(--bitmark-x-border-style)) var(--bitmark-footnotes-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-footnotes-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-footnotes-border-style, var(--bitmark-x-border-style)) var(--bitmark-footnotes-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-footnotes-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-footnotes-border-style, var(--bitmark-x-border-style)) var(--bitmark-footnotes-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-footnotes-border-radius, var(--bitmark-x-border-radius, y));
  counter-reset: footnotes;
}
.footnotes .auto-numbered::before {
  counter-increment: footnotes;
  content: counter(footnotes);
}

.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

@keyframes fadeInDown {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 70px;
    opacity: 1;
  }
}
.fadeInDown {
  animation-name: fadeInDown;
}

.full-screen {
  height: 100%;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
