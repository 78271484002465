// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-wrapper {
  display: flex;
  flex-direction: column;
  align-items: var(--bitmark-reader-cover-block-align, flex-start);
  width: var(--bitmark-reader-cover-block-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-reader-cover-block-size-height, var(--bitmark-x-size-height, inherit));
  margin: var(--bitmark-reader-cover-block-size-margin, var(--bitmark-x-size-margin, 0));
  padding: var(--bitmark-reader-cover-block-size-padding, var(--bitmark-x-size-padding, 0));
}
.summary-wrapper .summary-cover-container {
  display: var(--bitmark-reader-cover-show, flex);
  flex-direction: column;
  align-items: var(--bitmark-reader-cover-align, flex-start);
}
.summary-wrapper .summary-title-container {
  display: var(--bitmark-reader-title-show, block);
}
.summary-wrapper .summary-title-container .title {
  font-family: var(--bitmark-title-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-title-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-title-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-title-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-title-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-title-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-title-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-title-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-title-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-title-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
  width: var(--bitmark-title-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-title-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-title-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-title-size-margin, var(--bitmark-x-size-margin, 0));
  word-break: break-word;
}
.summary-wrapper .summary-title-container .subtitle {
  font-family: var(--bitmark-subtitle-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-subtitle-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-subtitle-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-subtitle-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-subtitle-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-subtitle-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-subtitle-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-subtitle-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-subtitle-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-subtitle-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
  width: var(--bitmark-subtitle-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-subtitle-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-subtitle-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-subtitle-size-margin, var(--bitmark-x-size-margin, 0));
  word-break: break-word;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
