// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.book-source {
  border-radius: 10px;
  border: 1px solid #000000;
  padding: 5px 10px;
  margin: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.book-source .btn-circle {
  padding: 3px !important;
}
.book-source .btn-circle {
  padding: 0;
  margin-left: 10px;
  border-radius: 100%;
  min-width: inherit;
  border: none;
}
.book-source.branded-bs {
  width: var(--bitmark-book-sources-button-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-book-sources-button-size-height, var(--bitmark-x-size-height, inherit));
  border-top: var(--bitmark-book-sources-button-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-book-sources-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-book-sources-button-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-book-sources-button-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-book-sources-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-book-sources-button-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-book-sources-button-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-book-sources-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-book-sources-button-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-book-sources-button-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-book-sources-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-book-sources-button-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-book-sources-button-border-radius, var(--bitmark-x-border-radius, y));
  padding: var(--bitmark-book-sources-button-size-padding, var(--bitmark-x-size-padding, 0));
  background-color: var(--bitmark-book-sources-source-background, white);
}
.book-source a {
  font-size: 12px;
}
.book-source::ng-deep .book-source-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 20px;
  max-width: 270px;
}
.book-source::ng-deep .book-source-title p {
  line-height: 30px;
  font-size: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-width: 100%;
  margin: 0;
}
.book-source::ng-deep .book-source-title.branded-bst p {
  font-family: var(--bitmark-book-sources-source-title-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-book-sources-source-title-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-book-sources-source-title-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-book-sources-source-title-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-book-sources-source-title-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-book-sources-source-title-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-book-sources-source-title-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-book-sources-source-title-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-book-sources-source-title-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-book-sources-source-title-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
}
.book-source::ng-deep .book-source-content {
  display: -webkit-box;
  -webkit-line-clamp: var(--bitmark-book-sources-line-clamp-content, 2);
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 12px;
  max-width: 270px;
}
.book-source::ng-deep .book-source-content img, .book-source::ng-deep .book-source-content em, .book-source::ng-deep .book-source-content video, .book-source::ng-deep .book-source-content figure, .book-source::ng-deep .book-source-content caption, .book-source::ng-deep .book-source-content figcaption, .book-source::ng-deep .book-source-content table, .book-source::ng-deep .book-source-content input, .book-source::ng-deep .book-source-content button, .book-source::ng-deep .book-source-content h1, .book-source::ng-deep .book-source-content h2, .book-source::ng-deep .book-source-content h3, .book-source::ng-deep .book-source-content h4, .book-source::ng-deep .book-source-content h5 {
  display: none;
}
.book-source::ng-deep .book-source-content ::ng-deep :not(p, span, b, strong, em) {
  display: none !important;
}
.book-source::ng-deep .book-source-content ::ng-deep * {
  margin: 0;
}
.book-source::ng-deep .book-source-content p {
  margin: 0 !important;
}
.book-source::ng-deep .book-source-content.branded-bsc * {
  font-family: var(--bitmark-book-sources-source-content-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-book-sources-source-content-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-book-sources-source-content-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-book-sources-source-content-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-book-sources-source-content-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-book-sources-source-content-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-book-sources-source-content-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-book-sources-source-content-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-book-sources-source-content-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-book-sources-source-content-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
}
.book-source::ng-deep .book-source-content.branded-bsc {
  font-family: var(--bitmark-book-sources-source-content-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-book-sources-source-content-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-book-sources-source-content-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-book-sources-source-content-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-book-sources-source-content-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-book-sources-source-content-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-book-sources-source-content-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-book-sources-source-content-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-book-sources-source-content-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-book-sources-source-content-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
