// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.module-header {
  background-color: var(--bitmark-module-header-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-module-header-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-module-header-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-module-header-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-module-header-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-module-header-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-module-header-background-animation-name) var(--bitmark-module-header-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-module-header-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-module-header-background-backdrop-filter);
}

.edit-container {
  margin: var(--bitmark-bit-negative-padding, 0);
  margin-top: 0 !important;
}

.note {
  min-height: 120px;
}

.text-placeholder:before {
  content: " ";
}

.btn-background-calm.open-button {
  background: var(--bitmark-module-button-background-shorthand, var(--bitmark-module-button-background, #543199));
  border-top: var(--bitmark-module-button-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-module-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-module-button-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-module-button-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-module-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-module-button-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-module-button-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-module-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-module-button-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-module-button-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-module-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-module-button-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-module-button-border-radius, var(--bitmark-x-border-radius, 30px));
  width: var(--bitmark-module-button-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-module-button-size-height, var(--bitmark-x-size-height, inherit));
  margin: var(--bitmark-module-button-size-margin, var(--bitmark-x-size-margin, 0));
  padding: var(--bitmark-module-button-size-padding, var(--bitmark-x-size-padding, 0));
  font-family: var(--bitmark-module-button-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-module-button-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-module-button-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-module-button-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-module-button-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-module-button-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-module-button-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-module-button-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-module-button-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-module-button-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
}
.btn-background-calm.open-button.buy-button-promotion {
  top: -4px;
  position: relative;
  margin-left: 5px;
}

.book-progress-container {
  width: 90%;
}

.view-resources-toggle {
  position: absolute;
  right: 10px;
  bottom: 5px;
  color: #543199;
}

.book-progress-checkbox {
  height: 20px;
  width: 20px;
  margin: 2.5px;
}
.book-progress-checkbox input[type=checkbox] {
  opacity: 0;
  position: absolute;
}
.book-progress-checkbox input[type=checkbox] + label::after {
  content: none;
}
.book-progress-checkbox input[type=checkbox]:checked + label::after {
  content: "✓" !important;
}
.book-progress-checkbox label {
  margin: 0;
  padding: 0;
  position: relative;
  word-break: break-word;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.book-progress-checkbox label bitmark-text {
  padding-left: 10px;
  max-width: calc(100% - var(--bitmark-radio-size-width, 30px));
}
.book-progress-checkbox label span {
  padding-left: 10px;
}
.book-progress-checkbox label::before {
  position: static;
  content: "";
  align-self: flex-start;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 2px solid #543199;
  background-color: var(--bitmark-checkbox-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-checkbox-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-checkbox-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-checkbox-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-checkbox-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-checkbox-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-checkbox-background-animation-name) var(--bitmark-checkbox-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-checkbox-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-checkbox-background-backdrop-filter);
}
.book-progress-checkbox label::after {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  content: "✓";
  width: 20px;
  height: 20px;
  font-size: 15px;
  color: #543199;
  border-radius: 4px;
  background-color: white;
  border: 2px solid #543199;
}
.book-progress-checkbox.correct input[type=checkbox]:not(:checked) + label::after, .book-progress-checkbox.incorrect input[type=checkbox]:not(:checked) + label::after {
  content: "";
}
.book-progress-checkbox.correct label::after {
  background-color: var(--bitmark-input-correct-background-color) !important;
}
.book-progress-checkbox.incorrect label::after {
  background-color: var(--bitmark-input-incorrect-background-color) !important;
}

.page-product-container {
  justify-content: var(--bitmark-page-product-list-flex-align, flex-start) !important;
}

.book-cover {
  height: 200px;
  width: 170px;
  width: var(--bitmark-page-product-list-image-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-page-product-list-image-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-page-product-list-image-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-page-product-list-image-size-margin, var(--bitmark-x-size-margin, 0));
}

.book-publisher {
  color: #656769;
  font-family: var(--bitmark-page-product-list-publisher-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-page-product-list-publisher-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-page-product-list-publisher-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-page-product-list-publisher-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-page-product-list-publisher-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-page-product-list-publisher-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-page-product-list-publisher-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-page-product-list-publisher-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-page-product-list-publisher-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-page-product-list-publisher-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
}

.book-title {
  word-wrap: break-word;
  color: black;
  font-family: var(--bitmark-page-product-list-title-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-page-product-list-title-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-page-product-list-title-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-page-product-list-title-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-page-product-list-title-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-page-product-list-title-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-page-product-list-title-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-page-product-list-title-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-page-product-list-title-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-page-product-list-title-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
}

.book-texts {
  padding-left: 2px;
  padding-right: 2px;
  width: 170px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.book-shadow {
  filter: drop-shadow(5px 0 0 white) drop-shadow(0 5px 0 white) drop-shadow(-5px 0 0 white) drop-shadow(0 -5px 0 white);
}

.image-container .resource-image {
  display: block;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
