import {Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {ClipboardService} from 'ngx-clipboard';
import {from, Observable, of, Subject} from 'rxjs';
import {concatMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {generateHTML} from '@tiptap/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DeviceDetectorService} from 'ngx-device-detector';
import {
  BaseBit,
  BitApiAnnotation,
  BitApiWrapper,
  BitEditorStatus,
  BitResource,
  BitsViewPortVisibility,
  BitType,
  ReaderEditorOperationType,
} from '../../../bits/bits.models';
import {BitmarkPagination, BookConfiguration, BookEntity, ReaderContext, ReaderModes} from '../../reader.models';
import {BitbookApiService} from '../../bitbook-api.service';
import {ReaderContentService} from './reader-content.service';
import {BitbookMqService} from '../../bitbook-mq.service';
import {ReaderTocService} from '../../reader-toc.service';
import {
  BitTranslateLanguagePickerComponent,
  DropdownItemModel,
  ScrollDirective,
  UrlRewriteService,
} from '../../../shared';
import {LearningPathLtiBit} from '../../../bits/learning-path/learning-path-lti/learning-path-lti.models';
import {
  BitAnnotationDefaultColor, BitBranding,
  BitmarkFormat,
  DefaultBitAnnotationColors, ProductFamily,
} from '../../../shared/models/bitmark.models';
import {BookPosition} from '../../reader-tracking.service';
import {ReaderBasketService} from '../../reader-basket.service';
import {ReaderClipboardService} from '../../reader-clipboard.service';
import {ChapterBit} from '../../../bits/chapter/chapter.models';
import {ArticleBit} from '../../../bits/article/article.models';
import {AnnotationBaseBit} from '../../../bits/annotations/annotation-base.models';
import {TocItem} from '../reader-toc-sidebar/reader-toc-sidebar.component';
import {highlightRange, removeHighlights} from '@funktechno/texthighlighter/lib';
import {normalizeHighlights} from '@funktechno/texthighlighter/lib/Library';
import {AnnotationBookmarkBit} from '../../../bits/annotations/annotation-bookmark/annotation-bookmark.models';
import {BitmarkPipe, BitmarkReversePipe} from '../../../bits';
import {AnnotationNoteBit} from '../../../bits/annotations/annotation-note/annotation-note.models';
import {BitmarkConfig} from '../../../bitmark.module';
import {QuoteBit} from '../../../bits/quote/quote.models';
import {defaultContent} from './reader-content-notebook/default-content';
import {ParserApiService} from '../../parser-api.service';
import {BrandingRenderService} from '../../branding-render.service';
import {ReaderTipTapTapService, TipTapEditorType} from '../../tiptap/reader-tiptap.service';
import {ReaderTextEditorComponent} from '../../reader-editor/reader-text-editor/reader-text-editor.component';
import {
  ReaderSimpleTextEditorComponent,
} from '../../reader-editor/reader-simple-text-editor/reader-simple-text-editor.component';
import {InternalLinkBit} from '../../../bits/internal-link/internal-link.models';
import {DomUtilsService} from '../../../shared/dom/dom-utils.service';
import {FileUtilsService} from '../../../shared/utils/file-utils.service';
import {memoize} from '../../../shared/decorators/memoize.decorator';
import {DomObserverService} from '../../../shared/dom/dom-observer.service';
import {Flashcard1Bit} from '../../../bits/flashcard-1/flashcard-1.models';
import {AnalyticsService} from '../../../shared/analytics/analytics.service';
import {RolesApiService} from '../../roles-api.service';
import bitTemplates from './reader-bit-templates.json';
import {readerEditableBits} from './reader-editable-bits';
import StringCaseService from '../../../shared/utils/string-case.service';
import * as bpgLib from '@gmb/bitmark-parser-generator/dist/browser/bitmark-parser-generator.min.js';
import {BitmarkParserGenerator} from '@gmb/bitmark-parser-generator';
import {ArrayService} from '../../../shared/utils/array.service';

@Component({
  template: '',
})
export class ReaderContentCommonComponent {
  @Input() bitBook: BookEntity;
  @Input() configuration?: BookConfiguration;
  @Output() sendBits = new EventEmitter<Array<BitApiWrapper>>();
  @Output() sendBitsToClass = new EventEmitter<Array<BitApiWrapper>>();
  @Output() saveBits = new EventEmitter<Array<BitApiWrapper>>();
  @Output() isWorking = new EventEmitter<boolean>();
  @Output() findInBook = new EventEmitter<BitApiWrapper>();
  @Output() copyLinkToBit = new EventEmitter<BitApiWrapper>();
  @Output() navigateToBook = new EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily, queryParams?: any }>();
  @Output() toggleToc = new EventEmitter<any>();
  @Output() fail = new EventEmitter<any>();
  @Output() closeBook = new EventEmitter<any>();

  @ViewChild('annotationsMenuTemplate') annotationsMenuTemplate: ElementRef;
  @ViewChild('annotationsMenuHighlightTemplate') annotationsMenuHighlightTemplate: ElementRef;
  @ViewChild('annotationsMenuPopup') annotationsMenuPopup: ElementRef;
  bitbookContentVersion: number;

  BitType = BitType;
  context: ReaderContext = 'notebook';
  lastEditTimestamp = 0;
  shouldProtectAgainstUserCopy = false;
  shouldProtectAgainstUserCopyTimeouts: Array<NodeJS.Timeout> = [];
  bitBookContent: Array<BitApiWrapper> = [];
  isAtTheTop = false;
  isAtTheBottom = false;
  isLoading = true;
  isLoadingAdditionalContent = false;
  isLoadingAdditionalContentTop = false;
  isLoadingAdditionalContentBottom = false;
  isEditingWholeBook = false;
  bitEditorStatus = null;
  showBitEditor = false;
  showJsonEditor = false;
  showHtmlTranslatorEditor = false;
  bitEditorPreviousBit: BitApiWrapper;
  bitEditorCurrentEditedBit: BitApiWrapper;
  isNewRelease = false;
  globalNotebookApiQueryParams: any = null;
  isScrolledToBottom = false;
  isScrolledToTop = false;
  brandingThemeClass: string;
  skeletonLoaderActions: Array<DropdownItemModel> = [];
  pageSize = 50;
  protected scrollUnlisten;
  protected scrollEndUnlisten;
  protected readerContentQueryType: string;
  protected bitAnnotationActions: Array<DropdownItemModel> = [];
  protected bitEditorActions: Array<DropdownItemModel> = [];
  protected annotationMenuInstance: any = null;
  focusedBitId: string = null;
  protected mouseUpEventBound = null;
  protected pagination: BitmarkPagination = {
    pageSize: 50,
    pageNumber: 1,
    startBitId: null,
  };
  private currentAfterBit: DropdownItemModel;
  private annotationMenuHighlightInstance: any = null;
  private isBrowserSafari: boolean = null;
  private parserGenerator: BitmarkParserGenerator;

  constructor(@Inject('BitmarkConfig') protected bitmarkConfig: BitmarkConfig,
              protected bitBookApiService: BitbookApiService,
              protected readerContentService: ReaderContentService,
              protected bitbookMqService: BitbookMqService,
              protected readerTocService: ReaderTocService,
              protected readerBasketService: ReaderBasketService,
              protected readerClipboard: ReaderClipboardService,
              protected clipboardService: ClipboardService,
              protected basketSvc: ReaderBasketService,
              protected translate: TranslateService,
              protected parserApiService: ParserApiService,
              protected router: Router,
              protected location: Location,
              protected brandingRenderService: BrandingRenderService,
              protected readerTipTapService: ReaderTipTapTapService,
              protected ngbModal: NgbModal,
              protected scrollDirective: ScrollDirective,
              protected domUtilsService: DomUtilsService,
              protected domObserverService: DomObserverService,
              protected fileUtilsService: FileUtilsService,
              protected deviceDetectorService: DeviceDetectorService,
              protected analyticsService: AnalyticsService,
              protected rolesApiService: RolesApiService,
              protected stringCaseService: StringCaseService,
              protected arrayService: ArrayService) {
    this.parserGenerator = new bpgLib.BitmarkParserGenerator();
  }

  handleNewRelease() {
    this.isNewRelease = this.router.url.indexOf('/new-release/') !== -1;
  }

  populateAddBitsMenu(courseId?: string) {
    this.brandingRenderService.getBookThemeBrandingProps(this.bitBook).subscribe(bookBranding => {
      const editorMenuBits = this.getEditorMenuBits(bookBranding?.themeBranding);
      const placeholderIndex = this.bitEditorActions.findIndex(x => x.id === 'branding-placeholder');
      let brandingMenuItems: Array<DropdownItemModel> = [];
      editorMenuBits.forEach(bitType => {
        const enBitBitmark = bitTemplates[bitType] ? bitTemplates[bitType]['en'] : null;
        const bitBitmark = bitTemplates[bitType] ? bitTemplates[bitType][this.bitmarkConfig.userLanguage] : null;
        brandingMenuItems.push({
          label: bitBitmark?.label || enBitBitmark?.label || 'Add ' + bitType,
          handler: (dropdownItemModel: DropdownItemModel) => {
            this.createBitFromBitmark(dropdownItemModel, bitBitmark?.bitmark || `[.${bitType}]`, courseId);
          }
        });
      });

      if (brandingMenuItems.length && placeholderIndex > 0) {
        brandingMenuItems = [{isSeparator: true}, ...this.arrayService.sortBy(brandingMenuItems, 'label', true)];
      }
      this.bitEditorActions.splice(placeholderIndex, 0, ...brandingMenuItems);
    });
  }

  private getEditorMenuBits(branding?: BitBranding): Array<BitType> {
    if (!branding) {
      return [];
    }

    const editorMenuBits = [];
    Object.values(this.BitType).forEach(bitType => {
      const brandingProp = this.stringCaseService.kebabToCamel(bitType);
      if (branding[brandingProp]?.showInEditor) {
        editorMenuBits.push(bitType);
      }
    });

    return editorMenuBits;
  }

  private createBitFromBitmark(dropdownItemModel: DropdownItemModel, bitmark: string, courseId?: string) {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }

    let createPromise: Observable<any>;
    if (this.bitmarkConfig.localParser?.enabled) {
      createPromise = new Observable(obs => {
        this.parserGenerator.convert(bitmark, {bitmarkVersion: 2}).then((parsedBits: Array<BitApiWrapper>) => {
          if (parsedBits?.length) {
            const newBit = parsedBits[0]?.bit;
            this.bitBookApiService.insertBitAfterBit(this.bitBook.externalId, newBit, bitWrapper?.id, courseId, this.globalNotebookApiQueryParams).subscribe(x => {
              obs.next(x);
              obs.complete();
            });
          } else {
            console.error('Invalid bitmark', bitmark);
            obs.error({});
            obs.complete();
          }
        });
      });
    } else {
      createPromise = this.bitBookApiService.addBitmarkBitAfterBit(this.bitBook.externalId, bitmark, bitWrapper?.id, courseId, this.globalNotebookApiQueryParams);
    }

    createPromise
      .subscribe((res: { bits: Array<BitApiWrapper>, toc: any }) => {
        if (readerEditableBits.includes(res.bits[0]?.bit?.type)) {
          res.bits[0].isBeingEdited = true;
          const editorCreatedSub = this.bitbookMqService.onEditorCreated()
            .subscribe((editor: ReaderTextEditorComponent | ReaderSimpleTextEditorComponent) => {
              this.domUtilsService.waitForDifferentStyleValue(`#bit-${res.bits[0].id}`, 'visibility', 'hidden')
                .subscribe(() => {
                  editor.focus();
                });
              editorCreatedSub.unsubscribe();
            });
        }
        this.addBitsToBookContent(res.bits, bitWrapper?.id);
      });
  }


  onScrollUp() {
    requestAnimationFrame(() => {
      if (!this.bitBookContent?.length || this.isLoadingAdditionalContent || this.isLoading || this.isAtTheTop) {
        return;
      }
      this.setIsWorkingState(true);
      this.isLoadingAdditionalContentTop = true;
      this.readerContentService.handleScrollUp(this.bitBook, this.bitBookContent, this.readerContentQueryType)
        .subscribe((res: { newContent: Array<BitApiWrapper>, isAtTop: boolean }) => {
          this.resetWorkingState();
          this.bitBookContent = res.newContent;
          this.isAtTheTop = res.isAtTop;
          this.isAtTheBottom = false;
        });
    });
  }

  onScrollDown() {
    requestAnimationFrame(() => {
      if (!this.bitBookContent?.length || this.isLoadingAdditionalContent || this.isLoading || this.isAtTheBottom) {
        return;
      }
      this.setIsWorkingState(true);
      this.isLoadingAdditionalContentBottom = true;
      this.readerContentService.handleScrollDown(this.bitBook, this.bitBookContent, this.readerContentQueryType)
        .subscribe((res: { newContent: Array<BitApiWrapper>, isAtBottom: boolean }) => {
          const prepareForTime = new Date().getTime();
          this.scrollDirective.prepareFor('down');

          this.resetWorkingState();
          this.bitBookContent = res.newContent;
          this.isAtTheBottom = res.isAtBottom;
          this.isAtTheTop = false;

          setTimeout(() => {
            if (new Date().getTime() - prepareForTime < 750) {
              this.scrollDirective.restore();
            }
            this.setIsWorkingState(false);
          });
        });
    });
  }

  private resetWorkingState() {
    const sub$ = this.domObserverService.observeResize2('.reader-content .bits-wrapper').subscribe(() => {
      sub$.unsubscribe();
      this.isLoadingAdditionalContentBottom = false;
      this.setIsWorkingState(false);
    });
  }

  handleBitSelected(selectedBitId: string, shouldComputeVisibility = false, s?: Subject<void>) {
    console.log('handle bit selected common');
    this.location.replaceState(
      this.router.createUrlTree(
        [],
        {
          fragment: `${selectedBitId}`,
          queryParamsHandling: 'merge',
        })
        .toString(),
    );
    this.initializeUserCopyProtection(shouldComputeVisibility);
    this.loadBit(selectedBitId).subscribe(() => {
      s?.next();
      s?.complete();
    });
  }

  @memoize()
  getBitId(bit: BitApiWrapper) {
    return bit.updatedTimestamp ? `${bit.id}-${bit.updatedTimestamp}` : bit.id;
  }

  openEditBookEditor() {
    this.bitEditorPreviousBit = null;
    this.bitEditorCurrentEditedBit = null;
    this.isEditingWholeBook = true;
    this.showBitEditor = true;
  }

  openJsonBookEditor() {
    this.showJsonEditor = true;
  }

  openHtmlTranslatorEditor() {
    this.showHtmlTranslatorEditor = true;
  }

  showBitEditProgress() {
    this.bitEditorStatus = BitEditorStatus.Updating;
  }

  showBitEditCompleted(data: { success: boolean, tocEntry?: TocItem }) {
    this.bitbookMqService.notifyRefreshBit({id: this.bitBook.id, tocEntry: data.tocEntry});
    this.readerTocService.setIsTocReloadNeeded(this.bitBook.externalId, true).subscribe();
    if (data?.success) {
      this.setReaderAsReady();
    } else {
      this.bitEditorStatus = BitEditorStatus.Failed;
    }
  }

  protected initializeUserCopyProtection(shouldComputeVisibility = true) {
    if (!this.isUserProtectionEnabled()) {
      return;
    }
    this.shouldProtectAgainstUserCopyTimeouts.forEach(tid => clearTimeout(tid));
    this.shouldProtectAgainstUserCopyTimeouts = [];
    this.shouldProtectAgainstUserCopy = false;

    if (shouldComputeVisibility) {
      this.readerContentService.computeAndNotifyBitsVisibility(() => this.bitBookContent, 2000);
    }

    const timeoutId = setTimeout(() => {
      this.shouldProtectAgainstUserCopy = true;
      this.shouldProtectAgainstUserCopyTimeouts = this.shouldProtectAgainstUserCopyTimeouts.filter(x => x !== timeoutId);
    }, 10000);
    this.shouldProtectAgainstUserCopyTimeouts.push(timeoutId);
  }

  protected stopUserCopyProtection() {
    this.shouldProtectAgainstUserCopy = false;
  }

  protected resumeUserCopyProtection() {
    if (!this.isUserProtectionEnabled()) {
      return;
    }
    this.readerContentService.computeAndNotifyBitsVisibility(() => this.bitBookContent);
    setTimeout(() => {
      this.shouldProtectAgainstUserCopy = true;
    }, 1000);
  }

  private toggleUserCopyProtection(enabled: boolean) {
    if (!this.isUserProtectionEnabled()) {
      this.shouldProtectAgainstUserCopyTimeouts = [];
      return;
    }
    if (this.shouldProtectAgainstUserCopyTimeouts?.length) {
      this.shouldProtectAgainstUserCopyTimeouts.forEach(tid => clearTimeout(tid));
      this.shouldProtectAgainstUserCopyTimeouts = [];
    }
    this.shouldProtectAgainstUserCopy = enabled;
  }

  private isUserProtectionEnabled(): boolean {
    if (this.isBrowserSafari === null) {
      this.isBrowserSafari = this.deviceDetectorService.getDeviceInfo()?.browser?.toLowerCase() === 'safari';
    }

    return !(this.context === 'new-release' || this.isBrowserSafari || this.bitmarkConfig.space !== 'swisslife');
  }

  protected initializeMouseUpListener() {
    const template = this.annotationsMenuTemplate.nativeElement;
    const highlightTemplate = this.annotationsMenuHighlightTemplate.nativeElement;
    this.annotationMenuInstance = template;
    this.annotationMenuHighlightInstance = highlightTemplate;

    this.mouseUpEventBound = this.handleMouseUp.bind(this, template, highlightTemplate);
    document.addEventListener('mouseup', this.mouseUpEventBound);
  }

  protected removeMouseUpListener() {
    if (this.mouseUpEventBound) {
      document.removeEventListener('mouseup', this.mouseUpEventBound);
      this.mouseUpEventBound = null;
    }
  }

  private handleMouseUp(template: any, highlightTemplate: any, event: MouseEvent) {
    this.handleAnnotationsMouseUp(template, highlightTemplate);
    this.handleFocusBitMouseUp(event);
  }

  private handleAnnotationsMouseUp(template: any, highlightTemplate: any) {
    if (this.annotationMenuInstance.style.display !== 'none') {
      this.annotationMenuInstance.style.display = 'none';
    }
    if (this.annotationMenuHighlightInstance.style.display !== 'none') {
      this.annotationMenuHighlightInstance.style.display = 'none';
    }
    if (window.getSelection().toString().length) {
      if (this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'reader-text-editor')) {
      } else if (this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'bit-wrapper1')) {
        const shouldShow = !this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'bit-type-bit-book-summary') &&
          !this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'bit-type-annotation-bookmark') &&
          !this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'bit-type-bit-book-ending') &&
          !this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'disable-annotations');

        if (shouldShow) {
          const selection = window.getSelection();
          if (!selection.isCollapsed) {
            const range = selection.getRangeAt(0);
            const {left, top} = range.getBoundingClientRect();
            template.style.left = `${left}px`;
            template.style.top = `${document.querySelector('.infinite-scroll-container').scrollTop + top}px`;
            template.style.width = `auto`;
            template.style.display = 'block';

            this.readerContentService.handleAnnotationMenuPopupPosition(template, this.annotationsMenuPopup.nativeElement);

            const shouldShowHighlights = this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'bit-body-allow-highlight') &&
              this.elementOrAncestorHasClass(window.getSelection().focusNode, 'bit-body-allow-highlight') &&
              !this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'tiptap-image-copyright') &&
              !this.elementOrAncestorHasClass(window.getSelection().focusNode, 'tiptap-image-copyright') &&
              !this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'tiptap-image-caption') &&
              !this.elementOrAncestorHasClass(window.getSelection().focusNode, 'tiptap-image-caption');
            highlightTemplate.style.display = shouldShowHighlights ? 'block' : 'none';
          }
        }
      }
    }
  }

  private handleFocusBitMouseUp(event: MouseEvent) {
    const targetNode: any = event.target;

    if (!targetNode) {
      this.focusedBitId = null;
      return;
    }

    const bitWrapper = targetNode?.closest('.bit-wrapper1');
    this.focusedBitId = bitWrapper?.id?.replace('bit-', '') || null;
  }

  protected popStateListenerAction() {
    if (!window.location.pathname.startsWith(`/space/${this.bitmarkConfig.space}/reader`)) {
      return;
    }
    const fragment = decodeURIComponent(window.location.hash).replace('#', '');
    this.setIsWorkingState(true);
    this.renderPage(fragment, true);
  }

  protected renderPage(fragment?: string, refreshToc?: boolean, additionalOptions?: {
    readerMode?: ReaderModes,
    ignoreStartBit?: boolean
  }) {
    this.isLoading = true;
    this.setIsWorkingState(true);
    this.bitBook.isNewRelease = this.isNewRelease;
    // additionalOptions = Object.assign({}, additionalOptions, this.configuration);
    this.readerContentService.loadContent(this.bitBook, this.pagination, fragment, false, this.readerContentQueryType, this.globalNotebookApiQueryParams, additionalOptions)
      .pipe(concatMap((res: {
        bitBookContent: Array<BitApiWrapper>,
        lastPos: BookPosition,
        isAtTop: boolean,
        isAtBottom: boolean
      }) => {
        const scrollObs = this.readerContentService.scrollToLastPosition(res.bitBookContent, res.lastPos);

        this.bitBookContent = res.bitBookContent;
        this.isAtTheTop = res.isAtTop;
        this.isAtTheBottom = res.isAtBottom;
        this.bitbookContentVersion = new Date().getTime();

        if (this.bitBookContent?.length > 2) {
          this.brandingThemeClass = this.bitBook?.theme;
        }

        return scrollObs;
      }))
      .subscribe((hasScrolled) => {
        if (!hasScrolled) {
          setTimeout(() => {
            this.readerContentService.computeAndNotifyBitsVisibility(() => this.bitBookContent);
          }, 250);
        }

        this.scrollUnlisten = this.readerContentService.listenToScroll(this.bitBook,
          () => {
            this.toggleUserCopyProtection(false);
          },
          () => this.bitBookContent);
        this.scrollEndUnlisten = this.readerContentService.listenToScrollEnd(this.bitBook,
          () => {
            const timeoutId = setTimeout(() => {
              this.toggleUserCopyProtection(true);
            }, 2000);
            this.shouldProtectAgainstUserCopyTimeouts.push(timeoutId);
          },
          () => this.bitBookContent);
        setTimeout(() => {
          if (refreshToc && fragment) {
            this.bitbookMqService.notifyRefreshToc({id: this.bitBook.id, shouldInitializeBitVisibility: true});
          }
          this.readerContentService.computeAndNotifyBitsScrollVisibility(() => this.bitBookContent);
        }, 250);
        this.isLoading = false;
        this.setIsWorkingState(false);
      }, (err: HttpErrorResponse) => {
        this.bitBookContent = [];
        this.isLoading = false;
        this.setIsWorkingState(false);
        this.fail.emit(err);
      });
  }

  protected renderPageNoListeners(fragment?: string, dontResetLastPosition?: boolean, additionalOptions?: {
    readerMode?: ReaderModes,
    ignoreStartBit?: boolean
  }) {
    this.isLoading = true;
    this.setIsWorkingState(true);
    this.bitBook.isNewRelease = this.isNewRelease;
    this.readerContentService.loadContent(this.bitBook, this.pagination, fragment, true, this.readerContentQueryType, this.globalNotebookApiQueryParams, additionalOptions)
      .pipe(concatMap((res: {
        bitBookContent: Array<BitApiWrapper>,
        lastPos: BookPosition,
        isAtTop: boolean,
        isAtBottom: boolean
      }) => {
        let scrollObs;
        if (!dontResetLastPosition) {
          scrollObs = this.readerContentService.scrollToLastPosition(res.bitBookContent, res.lastPos);
        } else {
          scrollObs = of(null);
        }

        this.bitBookContent = res.bitBookContent;
        this.isAtTheTop = res.isAtTop;
        this.isAtTheBottom = res.isAtBottom;
        this.bitbookContentVersion = new Date().getTime();

        if (this.bitBookContent?.length > 2) {
          this.brandingThemeClass = this.bitBook?.theme;
        }

        return scrollObs;
      }))
      .subscribe(() => {

        this.scrollUnlisten = this.readerContentService.listenToScroll(this.bitBook,
          () => {
          },
          () => this.bitBookContent);
        this.scrollEndUnlisten = this.readerContentService.listenToScrollEnd(this.bitBook,
          () => {
          },
          () => this.bitBookContent);


        this.isLoading = false;
        this.setIsWorkingState(false);
      }, (err: HttpErrorResponse) => {
        this.bitBookContent = [];
        this.isLoading = false;
        this.setIsWorkingState(false);
        this.fail.emit(err);
      });
  }

  protected loadBit(startingBit: string, distance?: number) {
    return new Observable(x => {
      this.setIsWorkingState(true);

      const bitIndex = this.bitBookContent.findIndex(b => b.id == startingBit);
      if (!startingBit || (startingBit === 'start' && bitIndex === -1)) {
        const scrollContainer = document.querySelector('.infinite-scroll-container');
        scrollContainer.scrollTop = 0;
        this.isAtTheTop = true;
        this.isAtTheBottom = false;
        this.bitBookApiService.getBookContentPage(this.bitBook.externalId, this.pagination)
          .subscribe((content: Array<BitApiWrapper>) => {
            if (startingBit === 'start' || this.pagination.pageNumber === 1) {
              this.bitBookContent = [this.readerContentService.getBitBookSummaryBit(this.bitBook), ...content];
            } else {
              this.bitBookContent = content;
            }
            this.setIsWorkingState(false);
            x.next();
            x.complete();
          }, (err: HttpErrorResponse) => {
            x.error(err);
            x.complete();
            console.error(err);
            this.setIsWorkingState(false);
          });
        return;
      }

      if (startingBit === 'start') {
        this.setIsWorkingState(true);
        this.isAtTheBottom = false;
        this.isAtTheTop = true;
        startingBit = this.bitBook.toc.filter(ti => !ti.isTrashed).at(0)?.ref;

        this.readerContentService.loadBit(startingBit, this.bitBook, this.isNewRelease ? {useVersioning: true} : null)
          .subscribe((res: { bitBookContent: Array<BitApiWrapper>, bit: BitApiWrapper }) => {
            const startBit = this.readerContentService.getBitBookSummaryBit(this.bitBook);

            const scrollToBotttomObs = this.readerContentService.observeAndScrollToBookPos({
              bitId: startBit.id,
              index: startBit.index,
              distance: distance || -1,
            }).subscribe(() => {
              scrollToBotttomObs.unsubscribe();
              this.setIsWorkingState(false);
              x.next();
              x.complete();
            });

            if (this.bitBookContent.find(b => b.id === 'end')) {
              const endBit = this.readerContentService.getBitBookEndingBit(this.bitBook, res.bitBookContent.at(-1));
              this.bitBookContent = [startBit, ...res.bitBookContent, endBit];
            } else {
              this.bitBookContent = [startBit, ...res.bitBookContent];
            }
          });
        return;
      }

      if (startingBit === 'end') {
        this.setIsWorkingState(true);
        this.isAtTheBottom = true;
        this.isAtTheTop = false;
        startingBit = this.bitBook.toc.filter(ti => !ti.isTrashed).at(-1)?.ref;
        this.readerContentService.loadBit(startingBit, this.bitBook, this.isNewRelease ? {useVersioning: true} : null)
          .subscribe((res: { bitBookContent: Array<BitApiWrapper>, bit: BitApiWrapper }) => {
            const endBit = this.readerContentService.getBitBookEndingBit(this.bitBook, res.bitBookContent.at(-1));

            const scrollToBotttomObs = this.readerContentService.observeAndScrollToBookPos({
              bitId: endBit.id,
              index: endBit.index,
              distance: distance || -1,
            }).subscribe(() => {
              scrollToBotttomObs.unsubscribe();
              this.setIsWorkingState(false);
              x.next();
              x.complete();
            });

            if (this.bitBookContent.find(b => b.id === 'start')) {
              const startBit = this.readerContentService.getBitBookSummaryBit(this.bitBook);
              this.bitBookContent = [startBit, ...res.bitBookContent, endBit];
            } else {
              this.bitBookContent = [...res.bitBookContent, endBit];
            }
          });
        return;
      }

      if (bitIndex !== -1) {
        this.readerContentService.scrollToBookPos({
          bitId: startingBit,
          index: this.bitBookContent[bitIndex].index,
          distance: distance || -.3,
        })
          .subscribe(() => {
            this.setIsWorkingState(false);
            x.next();
            x.complete();
          });
        return;
      }

      this.isAtTheTop = false;
      this.isAtTheBottom = false;
      this.setIsWorkingState(true);
      this.readerContentService.loadBit(startingBit, this.bitBook, this.isNewRelease ? {useVersioning: true} : null)
        .subscribe((res: { bitBookContent: Array<BitApiWrapper>, bit: BitApiWrapper }) => {
          this.isLoading = true;
          if (this.isScrolledToTop) {
            this.bitBookContent = [this.readerContentService.getBitBookSummaryBit(this.bitBook), ...res.bitBookContent];
          } else if (this.isScrolledToBottom) {
            this.bitBookContent = [...res.bitBookContent, this.readerContentService.getBitBookEndingBit(this.bitBook, res.bitBookContent.at(-1))];
          } else {
            this.bitBookContent = res.bitBookContent;
          }
          this.readerContentService.scrollToBookPos({
            bitId: res.bit.id,
            index: res.bit.index,
            distance: distance || -1,
          }).subscribe(() => {
            this.isLoading = false;
            this.setIsWorkingState(false);
            x.next();
            x.complete();
          });
        });
    });
  }

  protected addBitsToBookContent(bits: Array<BitApiWrapper>, afterBitId: string, toc?: Array<TocItem>) {
    const idx = afterBitId ?
      this.bitBookContent.findIndex((b: BitApiWrapper) => b.id === afterBitId)
      : this.context === 'shop' || this.context === 'shop-section' || this.context === 'self-learning' ? -1 : 0;
    this.bitBookContent = this.bitBookContent.slice(0, idx + 1)
      .concat(bits)
      .concat(this.bitBookContent.slice(idx + 1));
    if (idx > -1) {
      this.updateNextBitsAfterInject(idx, bits).subscribe();
    }
    if (!toc?.length) {
      this.refreshToc();
    } else {
      this.bitbookMqService.notifySetTocAndReload(toc);
    }
    this.readerContentService.computeAndNotifyBitsVisibility(() => this.bitBookContent);
  }

  protected setIsWorkingState(value: boolean) {
    this.isLoadingAdditionalContent = value;
    this.isWorking.emit(value);
  }

  onOpenResource(bitResource: BitResource) {
    this.readerContentService.handleOpenResource(bitResource);
  }

  onIsBitBeingEdited(bit: any) {
    this.bitEditorStatus = BitEditorStatus.Updating;
    // if (bit.bitWrapper && bit.bitWrapper?.bit?.format === BitmarkFormat.Prosemirror) {
    //   return this.updateBitFromTextEditor(bit.bitWrapper, bit.content);
    // }
    let payload: any = {};
    if (bit.bitWrapper?.bit?.type === BitType.Chapter) {
      payload = {id: (bit.bit as any)?.id, title: bit.bit?.title, level: bit.bit?.level, format: bit.bit?.format};
    } else if (bit.updates?.type === BitType.Quote) {
      payload = {
        id: (bit.bitWrapper as any).id,
        body: bit.updates.body,
        quotedPerson: bit.updates.quotedPerson,
      };
      bit = bit.bitWrapper;
    } else {
      payload = bit.bit || bit.bitWrapper?.bit;
    }
    if (bit?.bit?.type === BitType.Module) {
      payload = {
        icon: bit?.bit?.icon,
        resource: bit?.bit?.resource,
        item: bit?.bit?.item,
        lead: bit?.bit?.lead,
        instruction: bit?.bit?.instruction,
        body: bit?.bit?.body,
        id: bit?.bitWrapper?.id,
        duration: bit?.bit?.duration
      };
    }
    this.bitBookApiService.updateBit(payload, this.globalNotebookApiQueryParams)
      .subscribe((res: { tocEntry: TocItem, bit: BitApiWrapper }) => {
        if (!res) {
          return;
        }

        if (res.bit) {
          if (bit.bitWrapper) {
            bit.bitWrapper.bitmark = res.bit.bitmark || '';
          } else {
            bit.bitmark = res.bit.bitmark || '';
          }
        }

        const idx = this.bitBookContent.findIndex((x: BitApiWrapper) => x.id === bit.id);
        this.updateNextBitsAfterInject(idx, [res.bit]).subscribe();
        this.bitbookMqService.notifyRefreshBit({id: this.bitBook.id, tocEntry: res?.tocEntry});
        this.readerTocService.setIsTocReloadNeeded(this.bitBook.externalId, true).subscribe();

        this.setReaderAsReady();
      }, (err) => {
        console.error(err);
        this.bitEditorStatus = BitEditorStatus.Failed;
      });
  }

  closeBitIsEditedByUser(bitWrapper: BitApiWrapper) {
    this.bitEditorStatus = BitEditorStatus.Updating;
    bitWrapper.isBeingEditedByUser = false;
    bitWrapper.saveUserEdit = false;
    this.setReaderAsReady();
  }

  saveBitFromEditor(editedBitBitmark: any, courseId?: string) {
    if (!this.bitEditorCurrentEditedBit) {
      this.bitBookApiService.addBitmarkBitAfterBit(this.bitBook.externalId, editedBitBitmark, this.bitEditorPreviousBit?.id, courseId, this.globalNotebookApiQueryParams)
        .subscribe((data: { toc?: Array<TocItem> }) => {
          this.bitEditorPreviousBit = null;
          this.bitEditorCurrentEditedBit = null;
          this.renderPage();
          this.readerContentService.computeAndNotifyBitsVisibility(() => this.bitBookContent);
          this.bitbookMqService.notifySetTocAndReload(data?.toc);
          this.showBitEditor = false;
        }, (err) => {
          console.error(err);
          alert(err?.error?.message || err?.message);
        });
    } else {
      this.bitBookApiService.editBitFromBitmark(editedBitBitmark, this.bitEditorCurrentEditedBit.id, courseId, this.globalNotebookApiQueryParams)
        .subscribe((savedBit: BitApiWrapper) => {
          this.bitBookContent = this.bitBookContent.map(x => {
            if (x.id === this.bitEditorCurrentEditedBit.id) {
              savedBit.updatedTimestamp = new Date().toISOString();
              return savedBit;
            }

            x.updatedTimestamp = null;
            return x;
          });
          this.showBitEditor = false;
          this.bitEditorPreviousBit = null;
          this.bitEditorCurrentEditedBit = null;
          this.readerContentService.computeAndNotifyBitsVisibility(() => this.bitBookContent);
          this.bitbookMqService.notifyRefreshBit({id: this.bitBook.id, tocEntry: savedBit.tocEntry});
        }, (err) => {
          console.error(err);
          alert(err?.error?.message || err?.message);
        });
    }
  }

  saveBitFromTextEditor(bitWrapper: BitApiWrapper, bitJson: any) {
    this.bitEditorStatus = BitEditorStatus.Updating;
    this.bitBookApiService.editBitFromProsemirror(bitJson, bitWrapper.id, null, this.globalNotebookApiQueryParams)
      .subscribe(() => {
        this.renderPage();
        this.setReaderAsReady();
      }, (err) => console.error(err));
  }

  updateBitFromTextEditor(bitWrapper: BitApiWrapper, bitJson: any) {
    this.bitEditorStatus = BitEditorStatus.Updating;
    this.bitBookApiService.editBitFromProsemirror(bitJson, bitWrapper.id, bitWrapper.bit.type, this.globalNotebookApiQueryParams)
      .subscribe((res) => {
        (bitWrapper.bit as any).bit = bitJson;
        this.bitbookMqService.notifyRefreshBit({id: this.bitBook.id, tocEntry: res.tocEntry});
        this.setReaderAsReady();
      }, (err) => console.error(err));
  }

  setReaderAsReady() {
    this.bitEditorStatus = BitEditorStatus.Ready;
    this.lastEditTimestamp = new Date().getTime();
    setTimeout(() => {
      if (new Date().getTime() - this.lastEditTimestamp > 1000) {
        this.bitEditorStatus = null;
      }
    }, 1100);
  }

  onBitEditorClose() {
    this.showBitEditor = false;
  }

  protected updateNextBitsAfterInject(injectedAtIndex: number, arrayInjected: Array<BitApiWrapper>) {
    return new Observable((x: any) => {
      const lastChapterIdx = arrayInjected.findIndex((i: BitApiWrapper) => i.bit?.type === BitType.Chapter);
      if (lastChapterIdx !== -1) {
        const req = injectedAtIndex !== -1 && this.bitBookContent[injectedAtIndex].id !== 'start'
          ? this.bitBookApiService.getBookContentWithBitInMiddle(this.bitBook.externalId, this.bitBookContent[injectedAtIndex].id, injectedAtIndex, true, this.globalNotebookApiQueryParams)
          : this.bitBookApiService.getBookContentPage(this.bitBook.externalId, {
            pageSize: 20,
            pageNumber: 1,
            startBitId: null,
          }, this.globalNotebookApiQueryParams);
        req.subscribe((bits: Array<BitApiWrapper>) => {
          this.bitBookContent?.forEach((bc: BitApiWrapper) => {
            const updatedBit = bits.find((i: BitApiWrapper) => i.id === bc.id);
            if (updatedBit) {
              bc.meta.chapterPath = updatedBit.meta?.chapterPath;
            }
          });
          x.next();
          x.complete();
        }, (err) => console.error(err));
      }
      for (let i = injectedAtIndex + 1; i < this.bitBookContent.length; i++) {
        if (this.bitBookContent[i].index >= 0) {
          this.bitBookContent[i].index += 1;
        } else {
          if (this.bitBookContent[i - 1]?.index >= 0) {
            this.bitBookContent[i].index = this.bitBookContent[i - 1].index + 1;
          } else {
            this.bitBookContent[i].index = 0;
          }
        }
      }
      console.log(this.bitBookContent.map((i) => i.index));
      x.next();
      x.complete();
    });
  }

  protected copyAllBitsToBasket() {
    this.bitEditorStatus = BitEditorStatus.Updating;
    this.readerBasketService.addBookBitsToBasket(this.bitBook.id).subscribe(() => {
      this.setReaderAsReady();
    });
  }

  protected deleteBit(dropdownItemModel: DropdownItemModel, courseId?: string) {
    if (!dropdownItemModel?.data?.id) {
      console.error('ERROR DELETING BIT...', dropdownItemModel);
      return;
    }

    const isChapterBit = dropdownItemModel.data.bit.type === BitType.Chapter;
    this.bitBookApiService.removeBitFromNotebook(dropdownItemModel.data.id, courseId, this.globalNotebookApiQueryParams)
      .subscribe(() => {
        this.deleteBitLocally(dropdownItemModel, isChapterBit);
        this.readerContentService.computeAndNotifyBitsVisibility(() => this.bitBookContent);
      }, err => console.error(err));
  }

  protected deleteBitLocally(dropdownItemModel: DropdownItemModel, isChapterBit: boolean) {
    this.bitBookContent = this.bitBookContent.filter((b: BitApiWrapper) => b.id !== dropdownItemModel.data.id);
    for (let i = 0; i < this.bitBookContent.length; i++) {
      this.bitBookContent[i].index = i;
    }
    this.refreshToc();
    this.bitbookMqService.notifyReaderChapterDeleted(this.bitBook.externalId, dropdownItemModel.data.id);

    if (isChapterBit) {
      const idx = this.bitBookContent.findIndex((b: BitApiWrapper) => b.id === dropdownItemModel.data.id);
      for (let i = Math.max(0, idx); i < this.bitBookContent.length; i++) {
        this.bitBookContent[i].meta.chapterPath = this.bitBookContent[i].meta.chapterPath?.filter((c) => {
          return c.ref !== dropdownItemModel.data.id;
        });
      }
    }
  }

  protected refreshNotebookIfNeeded(notebookId: string) {
    if (this.bitBook.id === notebookId) {
      this.renderPage();
    }
  }

  refreshToc() {
    this.bitbookMqService.notifyRefreshToc({id: this.bitBook.id});
    this.readerTocService.setIsTocReloadNeeded(this.bitBook.externalId, true).subscribe();
  }

  refreshTocBit(tocEntry: TocItem) {
    this.bitbookMqService.notifyRefreshBit({id: this.bitBook.id, tocEntry});
    this.readerTocService.setIsTocReloadNeeded(this.bitBook.externalId, true).subscribe();
  }

  protected handleBitsVisibilityChanged(bitsData: BitsViewPortVisibility) {
    const lastBitId = this.bitBook?.toc?.at(-1)?.ref;
    if (!lastBitId) {
      return;
    }

    const visibleBitIds = bitsData.bitsWithContentInViewPort.map(x => x.id);
    this.isScrolledToBottom = visibleBitIds.some(x => [lastBitId, 'end'].includes(x));
  }

  protected showHiddenFields(editingBit: DropdownItemModel) {
    editingBit.data.isBeingEditedByUser = true;
  }

  protected hideHiddenFields(editingBit: DropdownItemModel) {
    editingBit.data.saveUserEdit = true;
  }

  protected shuffle(dropdownItemModel: DropdownItemModel) {
    const bitId = (dropdownItemModel.data as BitApiWrapper).id;
    this.bitbookMqService.notifyBitShuffle(bitId);
  }

  protected resetAnswer(dropdownItemModel: DropdownItemModel) {
    const bitId = (dropdownItemModel.data as BitApiWrapper).id;
    this.readerContentService.resetAnswer(bitId, this.bitBookContent)
      .subscribe();
  }

  protected addToBasket(dropdownItemModel: DropdownItemModel) {
    const bitId = (dropdownItemModel.data as BitApiWrapper).id;
    this.readerBasketService.addToBasket(bitId).subscribe();
  }

  protected openBitEditor(prevBit: DropdownItemModel) {
    this.bitEditorPreviousBit = prevBit.data;
    this.bitEditorCurrentEditedBit = null;
    this.isEditingWholeBook = false;
    this.showBitEditor = true;
  }

  protected openEditBitEditor(editingBit: DropdownItemModel) {
    this.bitEditorPreviousBit = null;
    this.bitEditorCurrentEditedBit = editingBit.data;
    this.isEditingWholeBook = false;
    this.showBitEditor = true;
  }

  protected copyBitmarkJsonToClipboard(dropdownItemModel: DropdownItemModel) {
    const bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    return this.clipboardService.copyFromContent(JSON.stringify(bitWrapper.bit || ''));
  }

  protected copyBitToClipboard(dropdownItemModel: DropdownItemModel) {
    return this.readerClipboard.copyToClipboard([{id: dropdownItemModel.data.id}], this.globalNotebookApiQueryParams).subscribe();
  }

  protected pasteFromClipboard(dropdownItemModel?: DropdownItemModel) {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }
    this.readerClipboard.pasteToBookFromClipboard(this.bitBook.id, bitWrapper?.id, this.globalNotebookApiQueryParams)
      .subscribe((res: { count: number, results: Array<BitApiWrapper>, toc: Array<TocItem> }) => {
        if (res?.results?.length) {
          res.results?.forEach((r: BitApiWrapper) => r.bit.id = r.id);
          this.addBitsToBookContent(res.results, bitWrapper?.id, res?.toc);
          // TODO: update chapter path of bits after if there is a chapter in the pasted bits
          // TODO: update indexes for all the bits in and after the response
        }
      }, err => console.error(err));
  }

  protected onInsertBasket(dropdownItemModel: DropdownItemModel, courseId?: string) {
    this.bitEditorStatus = BitEditorStatus.Updating;
    const bitApiWrapper = dropdownItemModel.data as BitApiWrapper;
    this.basketSvc.getBasketItems()
      .subscribe((basketItems: Array<BitApiWrapper>) => {
        const bits = basketItems.map((b: BitApiWrapper) => {
          return b.bit?.id ? {id: b.bit.id} : b.bit;
        });
        this.bitBookApiService.insertBitAfterBit(this.bitBook.id, bits, bitApiWrapper?.id, courseId, this.globalNotebookApiQueryParams)
          .subscribe(() => {
            this.setReaderAsReady();
            this.context !== 'shop-section' && this.context !== 'shop-section-readonly' ?
              this.renderPage(null, null, {ignoreStartBit: true})
              : this.renderPageNoListeners(null, true, {ignoreStartBit: true});
          }, (err) => console.error(err));
      }, () => {
        this.bitEditorStatus = BitEditorStatus.Ready;
      });
  }

  protected sendBit(dropdownItemModel: DropdownItemModel) {
    this.sendBits.emit([dropdownItemModel.data as BitApiWrapper]);
  }

  protected sendBitToClass(dropdownItemModel: DropdownItemModel) {
    this.sendBitsToClass.emit([dropdownItemModel.data as BitApiWrapper]);
  }

  protected saveBit(dropdownItemModel: DropdownItemModel) {
    this.saveBits.emit([dropdownItemModel.data as BitApiWrapper]);
  }

  protected findBitInBook(dropdownItemModel: DropdownItemModel) {
    this.findInBook.emit(dropdownItemModel.data as BitApiWrapper);
  }

  navigateToTopBottom() {
    if (!this.bitBookContent?.filter(x => !['start', 'end'].includes(x.id))?.length) {
      return;
    }
    let bitIdToScroll = '';
    if (this.isScrolledToBottom) {
      bitIdToScroll = this.getFarthestBitIdFromTopBottom(15, false) || 'start';
      this.isScrolledToBottom = false;
      this.isScrolledToTop = true;
    } else {
      bitIdToScroll = this.getFarthestBitIdFromTopBottom(15, true) || 'end';
      this.isScrolledToBottom = true;
      this.isScrolledToTop = false;
    }

    if (!bitIdToScroll) {
      return;
    }

    let scrollBitId = 'start';
    if (this.isScrolledToBottom) {
      scrollBitId = 'end';
    }

    if (this.bitBookContent.find(x => x.id === scrollBitId)) {
      this.domUtilsService.scrollElementIntoView(`#bit-${scrollBitId}`, null, {behavior: 'smooth'}).subscribe();
      return;
    }

    const s = new Subject<void>();
    s.subscribe(() => {
      this.domUtilsService.scrollElementIntoView(`#bit-${scrollBitId}`, null, {behavior: 'smooth'}).subscribe();
    });
    this.handleBitSelected(bitIdToScroll, true, s);
  }

  private getFarthestBitIdFromTopBottom(distance: number, fromBottom: boolean) {
    const filteredBookToc = this.bitBook.toc.filter(ti => !ti.isTrashed);
    const loadedBitIds = this.bitBookContent.map(x => x.id);
    while (distance > 0) {
      if (fromBottom) {
        const index = filteredBookToc.length - distance;
        if (index > 0 && !loadedBitIds.includes(filteredBookToc.at(index).ref)) {
          return this.bitBook.toc.at(index).ref;
        }
      } else {
        if (filteredBookToc.length > distance && !loadedBitIds.includes(filteredBookToc.at(distance).ref)) {
          return this.bitBook.toc.at(distance).ref;
        }
      }

      distance -= 5;
    }

    return null;
  }

  protected cutBitToClipboard(dropdownItemModel: DropdownItemModel) {
    const bitWrapper = (dropdownItemModel.data.bit as BaseBit);
    this.readerClipboard.cutToClipboard([bitWrapper], this.globalNotebookApiQueryParams).subscribe();
    const isChapterBit = dropdownItemModel.data.bit.type === BitType.Chapter;
    this.deleteBitLocally(dropdownItemModel, isChapterBit);
    this.readerContentService.computeAndNotifyBitsVisibility(() => this.bitBookContent);
  }

  protected copyBitLink(dropdownItemModel: DropdownItemModel) {
    this.copyLinkToBit.emit(dropdownItemModel.data as BitApiWrapper);
  }

  protected createChapterAfterBit(dropdownItemModel: DropdownItemModel, courseId?: string) {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }
    const newBit: ChapterBit = {
      title: this.translate.instant('Reader.Editor.NewChapter'),
      format: BitmarkFormat.MM,
      type: BitType.Chapter,
      level: 2,
    };
    this.bitBookApiService.insertBitAfterBit(this.bitBook.id, newBit, bitWrapper?.id, courseId, this.globalNotebookApiQueryParams)
      .subscribe((res: { bits: Array<BitApiWrapper>, toc: any }) => {
        if (res.bits?.length) {
          res.bits[0].isBeingEdited = true;
          const editorCreatedSub = this.bitbookMqService.onEditorCreated()
            .subscribe((editor: ReaderTextEditorComponent | ReaderSimpleTextEditorComponent) => {
              this.domUtilsService.waitForDifferentStyleValue(`#bit-${res.bits[0].id}`, 'visibility', 'hidden')
                .subscribe(() => {
                  editor.focus();
                  editor.selectAll();
                });
              editorCreatedSub.unsubscribe();
            });
          this.addBitsToBookContent(res.bits, bitWrapper?.id);
        }
      }, err => console.error(err));
  }

  private selectContentEditableElement(el, selectAllText?: boolean) {
    el.focus();
    if (typeof window.getSelection !== 'undefined'
      && typeof document.createRange !== 'undefined') {
      const range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof (document.body as any).createTextRange !== 'undefined') {
      const textRange = (document.body as any).createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
    if (selectAllText) {
      document.execCommand('selectAll', false, null);
    }
  }

  protected createLtiAfterBit(dropdownItemModel: DropdownItemModel) {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }

    const newBit: LearningPathLtiBit = {
      instruction: '',
      action: '',
      body: '',
      duration: '3600s',
      format: BitmarkFormat.Text,
      type: BitType.LearningPathLti,
    };

    this.bitEditorStatus = BitEditorStatus.Updating;

    this.bitBookApiService.insertBitAfterBit(this.bitBook.id, newBit, bitWrapper?.id)
      .subscribe((res: { bits: Array<BitApiWrapper>, toc: any }) => {
        res.bits?.forEach(b => b.isBeingEditedByUser = true);
        this.addBitsToBookContent(res.bits, bitWrapper?.id);
        this.setReaderAsReady();
      }, err => {
        console.error(err);
        this.bitEditorStatus = BitEditorStatus.Failed;
      });
  }

  protected createNoteAfterBit(dropdownItemModel: DropdownItemModel, courseId?: string) {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }
    const newBit: ArticleBit = {
      body: '',
      format: BitmarkFormat.PP,
      type: BitType.Note,
    };
    this.bitBookApiService.insertBitAfterBit(this.bitBook.id, newBit, bitWrapper?.id, courseId, this.globalNotebookApiQueryParams)
      .subscribe((res: { bits: Array<BitApiWrapper>, toc: any }) => {
        res.bits[0].isBeingEdited = true;
        const editorCreatedSub = this.bitbookMqService.onEditorCreated()
          .subscribe((editor: ReaderTextEditorComponent | ReaderSimpleTextEditorComponent) => {
            this.domUtilsService.waitForDifferentStyleValue(`#bit-${res.bits[0].id}`, 'visibility', 'hidden')
              .subscribe(() => {
                editor.focus();
              });
            editorCreatedSub.unsubscribe();
          });
        this.addBitsToBookContent(res.bits, bitWrapper?.id);
      }, err => console.error(err));
  }

  protected createArticleAfterBit(dropdownItemModel: DropdownItemModel, json?: any) {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }
    const newBit: ArticleBit = {
      body: json || '',
      format: BitmarkFormat.PP,
      type: BitType.Article,
    };
    this.bitBookApiService.insertBitAfterBit(this.bitBook.id, newBit, bitWrapper?.id, null, this.globalNotebookApiQueryParams)
      .subscribe((res: { bits: Array<BitApiWrapper>, toc: any }) => {
        res.bits[0].isBeingEdited = true;
        const editorCreatedSub = this.bitbookMqService.onEditorCreated()
          .subscribe((editor: ReaderTextEditorComponent | ReaderSimpleTextEditorComponent) => {
            this.domUtilsService.waitForDifferentStyleValue(`#bit-${res.bits[0].id}`, 'visibility', 'hidden')
              .subscribe(() => {
                editor.focus();
              });
            editorCreatedSub.unsubscribe();
          });
        this.addBitsToBookContent(res.bits, bitWrapper?.id);
      }, err => console.error(err));
  }

  protected createQuoteAfterBit(dropdownItemModel: DropdownItemModel) {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }
    const newBit: QuoteBit = {
      body: 'There is no shame in not knowing something. The shame is in not being willing to learn.',
      quotedPerson: 'Alison Croggon',
      format: BitmarkFormat.Text,
      type: BitType.Quote,
    };
    this.bitBookApiService.insertBitAfterBit(this.bitBook.id, newBit, bitWrapper?.id, null, this.globalNotebookApiQueryParams)
      .subscribe((res: { bits: Array<BitApiWrapper>, toc: any }) => {
        this.addBitsToBookContent(res.bits, bitWrapper?.id);
      }, err => console.error(err));
  }

  protected createExampleNotes(dropdownItemModel: DropdownItemModel) {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }
    const payload = {
      format: BitmarkFormat.PP,
      type: BitType.Article,
    };

    const res = from(defaultContent.reverse()).pipe(
      concatMap((noteContent: any) =>
        this.bitBookApiService.insertBitAfterBit(this.bitBook.externalId, noteContent?.type ?
          Object.assign({}, payload, noteContent, {}) :
          Object.assign({}, payload, {body: noteContent}), bitWrapper?.id, this.globalNotebookApiQueryParams),
      ));
    const newNotes = [];
    res.subscribe((newNote) => {
      newNotes.push(newNote);
      if (newNotes.length === defaultContent.length) {
        this.renderPage();
      }
    }, (err) => console.error(err));
  }

  //region OpenAI

  private setGeneratingBitLoading(bitWrapper: BitApiWrapper, isLoading: boolean) {
    if (bitWrapper) {
      bitWrapper.editorLoadingPost = isLoading;
    } else {
      if (this.bitBookContent?.length <= 2) {
        return;
      }
      if (isLoading) {
        this.bitBookContent[1].editorLoadingPre = true;
      } else {
        this.bitBookContent[1].editorLoadingPre = false;
        this.bitBookContent[2].editorLoadingPre = false;
      }
    }
  }

  protected generateQuizzesForBitChapter(dropdownItemModel: DropdownItemModel) {
    const bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    this.setGeneratingBitLoading(bitWrapper, true);
    this.stopUserCopyProtection();
    const req = bitWrapper?.id
      ? this.bitBookApiService.getBitChapterContent(bitWrapper.id)
      : this.bitBookApiService.getBookContentPage(this.bitBook.externalId, {
        pageSize: 1000,
        pageNumber: 1,
        startBitId: null,
      });
    req.subscribe(async (chapterContent: any) => {
      const content = chapterContent?.content || chapterContent;
      const bitmark = content.map((c) => {
        const body = (c?.bit as any).body || c?.bitmark;
        return typeof body === 'string' ? body : this.getTextFromProseMirrorJson({content: body});
      }).join('\n\n');
      if (!bitmark?.replace((/ {2}|\r\n|\n|\r/gm), '').trim().length) {
        alert('There is not enough content to generate the summary');
        this.setGeneratingBitLoading(bitWrapper, false);
        this.resumeUserCopyProtection();
        return;
      }

      const modalRef = this.ngbModal.open(BitTranslateLanguagePickerComponent, {
        windowClass: 'transparent-modal md',
        animation: false,
      });

      const sub = (modalRef.componentInstance as BitTranslateLanguagePickerComponent).languageChosen.subscribe(async (language: string) => {
        sub.unsubscribe();
        if (!language) {
          this.setGeneratingBitLoading(bitWrapper, false);
          this.resumeUserCopyProtection();
          return;
        }
        const response = await this.bitBookApiService.generateAIQuizzesFromBitmarkStream(bitmark, language);
        const reader = response.body.getReader();
        if (response.status >= 400) {
          alert('ChatGPT is not available right now. Please try again later!');
          this.setGeneratingBitLoading(bitWrapper, false);
          this.resumeUserCopyProtection();
          return;
        }
        if (bitWrapper && (!bitWrapper?.editorLoadingPre && !bitWrapper?.editorLoadingPost)) {
          this.resumeUserCopyProtection();
          return;
        }
        this.setGeneratingBitLoading(bitWrapper, false);
        const bitToLoadIdx = bitWrapper?.id ? this.bitBookContent.findIndex((b) => b.id === bitWrapper.id) : 0;
        const bitNextId = this.bitBookContent[bitToLoadIdx + 1]?.id;
        let buffer = '', displayBuffer = '';
        while (true) {
          const {done, value} = await reader.read();
          if (done) {
            break;
          }
          const stringValue = new TextDecoder().decode(value);
          if (stringValue?.length > 100 && (stringValue[0] === 'Error' || stringValue[0] === '{')) {
            break;
          }
          buffer += stringValue;
          if (stringValue?.indexOf('\n') !== -1) {
            displayBuffer = buffer.slice(0, buffer.lastIndexOf('\n'));
            this.setGeneratingBitLoading(bitWrapper, false);

            this.parserApiService.parseBitmark(displayBuffer).subscribe((parsedBits: Array<BitApiWrapper>) => {
              parsedBits = parsedBits?.filter((b: any) => b?.bit?.type !== '_error') || [];
              if (parsedBits?.length) {
                const brandingBitMeta = Object.assign({}, bitWrapper?.meta || this.bitBookContent.find((b) => b.meta)?.meta);
                if (brandingBitMeta) {
                  brandingBitMeta.themeId = this.bitBook.theme;
                  brandingBitMeta.thisBook = {theme: this.bitBook.theme};
                  brandingBitMeta.originBook = null;
                  parsedBits?.forEach(x => {
                    x.meta = Object.assign({}, x.meta, {thisBook: {theme: this.brandingRenderService.getBookThemeBranding(brandingBitMeta)}});
                  });
                }
                const nextBitIndex = bitNextId ? this.bitBookContent.findIndex((b) => b.id === bitNextId) : -1;
                this.bitBookContent = this.bitBookContent.slice(0, bitToLoadIdx + 1).concat(parsedBits)
                  .concat(this.bitBookContent.slice(nextBitIndex));
              }
            }, (err) => {
              console.error(err);
              throw err;
            });
          }
        }

        console.log('bitmark whole: ', buffer);
        this.resumeUserCopyProtection();
        //sometimes chatgpt hallucinates quotes at the end
        buffer = buffer.replace(/]"/g, ']');
        this.bitBookApiService.addBitmarkBitAfterBit(this.bitBook.externalId, buffer, bitWrapper?.id, null, this.globalNotebookApiQueryParams)
          .subscribe((args: { bits: Array<BitApiWrapper>, toc: any }) => {
            this.bitEditorPreviousBit = null;
            this.bitEditorCurrentEditedBit = null;
            // this.renderPage();
            this.showBitEditor = false;
            const nextBitIndex = bitNextId ? this.bitBookContent.findIndex((b) => b.id === bitNextId) : -1;
            this.bitBookContent = this.bitBookContent.slice(0, bitToLoadIdx + 1).concat(this.bitBookContent.slice(nextBitIndex));
            this.addBitsToBookContent(args?.bits, bitWrapper?.id);
          }, (err) => {
            alert('There was an issue saving your quizzes. Please try again later!');
            console.error(err);
          });
      });
    }, (err) => {
      alert('There was an issue processing your quizzes request. Please try again later!');
      this.resumeUserCopyProtection();
      console.error(err);
    });
  }

  protected generateQuizzesForBook(dropdownItemModel: DropdownItemModel) {
    const bitWrapper = dropdownItemModel.data as BitApiWrapper;
    this.setGeneratingBitLoading(bitWrapper, true);
    this.stopUserCopyProtection();

    const req = this.bitBookApiService.getBookContentPage(this.bitBook.externalId, {
      pageSize: 1000,
      pageNumber: 1,
      startBitId: null,
    });
    req.subscribe(async (chapterContent: any) => {
      const content = chapterContent?.content || chapterContent;
      const bitmark = content.map((c) => {
        const body = (c?.bit as any).body || c?.bitmark;
        return typeof body === 'string' ? body : this.getTextFromProseMirrorJson({content: body});
      }).join('\n\n');
      if (!bitmark?.replace((/ {2}|\r\n|\n|\r/gm), '').trim().length) {
        alert('There is not enough content to generate the summary');
        this.setGeneratingBitLoading(bitWrapper, false);
        this.resumeUserCopyProtection();
        return;
      }
      const modalRef = this.ngbModal.open(BitTranslateLanguagePickerComponent, {
        windowClass: 'transparent-modal md',
        animation: false,
      });

      const sub = (modalRef.componentInstance as BitTranslateLanguagePickerComponent).languageChosen.subscribe(async (language: string) => {
        sub.unsubscribe();
        if (!language) {
          this.setGeneratingBitLoading(bitWrapper, false);
          this.resumeUserCopyProtection();
          return;
        }
        const response = await this.bitBookApiService.generateAIQuizzesFromBitmarkStream(bitmark, language);
        const reader = response.body.getReader();
        if (response.status >= 400) {
          alert('ChatGPT is not available right now. Please try again later!');
          this.setGeneratingBitLoading(bitWrapper, false);
          this.resumeUserCopyProtection();
          return;
        }
        if (bitWrapper && (!bitWrapper?.editorLoadingPre && !bitWrapper?.editorLoadingPost)) {
          this.resumeUserCopyProtection();
          return;
        }
        this.setGeneratingBitLoading(bitWrapper, false);
        const bitToLoadIdx = bitWrapper?.id ? this.bitBookContent.findIndex((b) => b.id === bitWrapper.id) : 0;
        const bitNextId = this.bitBookContent[bitToLoadIdx + 1]?.id;
        let buffer = '', displayBuffer = '';
        while (true) {
          const {done, value} = await reader.read();
          if (done) {
            break;
          }
          const stringValue = new TextDecoder().decode(value);
          if (stringValue?.length > 100 && (stringValue[0] === 'Error' || stringValue[0] === '{')) {
            break;
          }
          buffer += stringValue;
          if (stringValue?.indexOf('\n') !== -1) {
            displayBuffer = buffer.slice(0, buffer.lastIndexOf('\n'));
            this.setGeneratingBitLoading(bitWrapper, false);
            this.parserApiService.parseBitmark(displayBuffer).subscribe((parsedBits: Array<BitApiWrapper>) => {
              parsedBits = parsedBits?.filter((b: any) => b?.bit?.type !== '_error') || [];
              if (parsedBits?.length) {
                const brandingBitMeta = Object.assign({}, bitWrapper?.meta || this.bitBookContent.find((b) => b.meta)?.meta);
                if (brandingBitMeta) {
                  brandingBitMeta.thisBook = {theme: this.bitBook.theme};
                  brandingBitMeta.themeId = this.bitBook.theme;
                  brandingBitMeta.originBook = null;
                  parsedBits?.forEach(x => {
                    x.meta = Object.assign({}, x.meta, {thisBook: {theme: this.brandingRenderService.getBookThemeBranding(brandingBitMeta)}});
                  });
                }
                const nextBitIndex = bitNextId ? this.bitBookContent.findIndex((b) => b.id === bitNextId) : -1;
                this.bitBookContent = this.bitBookContent.slice(0, bitToLoadIdx + 1).concat(parsedBits)
                  .concat(this.bitBookContent.slice(nextBitIndex));
              }
            }, (err) => {
              this.resumeUserCopyProtection();
              console.error(err);
              throw err;
            });
          }
        }

        console.log('bitmark whole: ', buffer);
        this.resumeUserCopyProtection();
        //sometimes chatgpt hallucinates quotes at the end
        buffer = buffer.replace(/]"/g, ']');
        this.bitBookApiService.addBitmarkBitAfterBit(this.bitBook.externalId, buffer, bitWrapper?.id, null, this.globalNotebookApiQueryParams)
          .subscribe((createdBitsResponse: { bits: Array<BitApiWrapper>, toc: any }) => {
            this.bitEditorPreviousBit = null;
            this.bitEditorCurrentEditedBit = null;
            // this.renderPage();
            this.showBitEditor = false;
            const nextBitIndex = bitNextId ? this.bitBookContent.findIndex((b) => b.id === bitNextId) : -1;
            this.bitBookContent = this.bitBookContent.slice(0, bitToLoadIdx + 1).concat(this.bitBookContent.slice(nextBitIndex));
            this.addBitsToBookContent(createdBitsResponse?.bits, bitWrapper?.id);
          }, (err) => {
            alert('There was an issue saving your quizzes. Please try again later!');
            console.error(err);
          });
      });
    }, (err) => {
      this.resumeUserCopyProtection();
      alert('There was an issue processing your quizzes request. Please try again later!');
      console.error(err);
    });
  }

  protected generateSummaryForBookStream(dropdownItemModel: DropdownItemModel) {
    const bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    this.setGeneratingBitLoading(bitWrapper, true);
    this.bitBookApiService.getBookContentPage(this.bitBook.externalId, {
      pageNumber: 1,
      pageSize: 50,
      startBitId: null,
    }).subscribe(async (content) => {
      const bitToLoadIndex = bitWrapper?.id ? this.bitBookContent.findIndex((b) => b.id === bitWrapper.id) : 0;
      const bitmark = content.map((c) => {
        const body = (c?.bit as any).body || c?.bitmark;
        return typeof body === 'string' ? body : this.getTextFromProseMirrorJson({content: body});
      }).join('\n\n');
      if (!bitmark?.replace((/ {2}|\r\n|\n|\r/gm), '').trim().length) {
        alert('There is not enough content to generate the summary');
        this.setGeneratingBitLoading(bitWrapper, false);
        return;
      }

      const modalRef = this.ngbModal.open(BitTranslateLanguagePickerComponent, {
        windowClass: 'transparent-modal md',
        animation: false,
      });

      const sub = (modalRef.componentInstance as BitTranslateLanguagePickerComponent).languageChosen.subscribe((language: string) => {
        sub.unsubscribe();
        if (!language) {
          this.setGeneratingBitLoading(bitWrapper, false);
          return;
        }
        this.bitBookApiService.addBitmarkBitAfterBit(this.bitBook.externalId, '[.article]\n\n', bitWrapper?.id, this.globalNotebookApiQueryParams)
          .subscribe(async (createdBitsResponse: { bits: Array<BitApiWrapper> }) => {
            const brandingBitMeta = Object.assign({}, bitWrapper?.meta || this.bitBookContent.find((b) => b.meta)?.meta);
            if (brandingBitMeta) {
              brandingBitMeta.thisBook = {theme: this.bitBook.theme};
              brandingBitMeta.themeId = this.bitBook.theme;
              brandingBitMeta.originBook = null;
              createdBitsResponse?.bits?.forEach(x => {
                x.meta = Object.assign({}, x.meta, {thisBook: {theme: this.brandingRenderService.getBookThemeBranding(brandingBitMeta)}});
                (x.bit as ArticleBit).isBeingGenerated = true;
                x.bit.instruction = 'Summary';
                x.isEditNotAllowed = true;
              });
            }
            if (bitWrapper && (!bitWrapper?.editorLoadingPre && !bitWrapper?.editorLoadingPost)) {
              return;
            }
            this.setGeneratingBitLoading(bitWrapper, false);
            this.addBitsToBookContent(createdBitsResponse?.bits, bitWrapper?.id);

            const response = await this.bitBookApiService.generateBookSummaryFromBitmarkStream(bitmark, language);
            const newBit = this.bitBookContent[bitToLoadIndex + 1] as BitApiWrapper;
            const reader = response.body.getReader();

            let buffer = '';
            while (true) {
              const {done, value} = await reader.read();
              if (done) {
                newBit.isEditNotAllowed = false;
                (newBit.bit as ArticleBit).isBeingGenerated = false;
                this.bitbookMqService.notifyBitGenerated(newBit.id);
                this.bitEditorStatus = BitEditorStatus.Updating;
                this.bitBookApiService.editBitJson(newBit?.bit, newBit?.id)
                  .subscribe((data: { tocEntry: TocItem, bit: BitApiWrapper }) => {
                    this.setReaderAsReady();
                  }, (err) => {
                    this.bitEditorStatus = BitEditorStatus.Failed;
                  });
                break;
              }
              const stringValue = new TextDecoder().decode(value);
              console.log(stringValue);
              if (stringValue?.length > 100 && (stringValue[0] === 'Error' || stringValue[0] === '{')) {
                break;
              }
              if (stringValue.indexOf('---summary---') !== -1) {
                newBit.bit.instruction = stringValue.replace('---summary---', '');
              } else {
                buffer += stringValue;
                if (newBit) {
                  newBit.bit = Object.assign({}, newBit.bit, {body: buffer});
                }
              }
            }
            if (response.status >= 400) {
              alert('ChatGPT is not available right now. Please try again later!');
              this.setGeneratingBitLoading(bitWrapper, false);
              return;
            }
          }, (err) => {
            alert('There was an issue saving your summary. Please try again later!');
            console.error(err);
          });
      });
    }, (err) => {
      alert('There was an issue processing your summary request. Please try again later!');
      console.error(err);
    });
  }

  protected generateSummaryForChapterStream(dropdownItemModel: DropdownItemModel) {
    const bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    this.setGeneratingBitLoading(bitWrapper, true);
    const bitId = bitWrapper?.id;
    const req = bitId
      ? this.bitBookApiService.getBitChapterContent(bitId)
      : this.bitBookApiService.getBookContentPage(this.bitBook.externalId, {
        pageSize: 1000,
        pageNumber: 1,
        startBitId: null,
      });
    req.subscribe((content: any) => {
      const bitToLoadIndex = bitWrapper?.id ? this.bitBookContent.findIndex((b) => b.id === bitWrapper.id) : 0;

      const bitmark = content?.content?.map((c) => {
        const body = (c?.bit as any).body || c?.bitmark;
        return typeof body === 'string' ? body : this.getTextFromProseMirrorJson({content: body});
      }).join('\n\n');
      if (!bitmark?.replace((/ {2}|\r\n|\n|\r/gm), '').trim().length) {
        alert('There is not enough content to generate the summary');
        this.setGeneratingBitLoading(bitWrapper, false);
        return;
      }
      const modalRef = this.ngbModal.open(BitTranslateLanguagePickerComponent, {
        windowClass: 'transparent-modal md',
        animation: false,
      });

      const sub = (modalRef.componentInstance as BitTranslateLanguagePickerComponent).languageChosen.subscribe((language: string) => {
        sub.unsubscribe();
        if (!language) {
          this.setGeneratingBitLoading(bitWrapper, false);
          return;
        }
        this.bitBookApiService.addBitmarkBitAfterBit(this.bitBook.externalId, '[.article]\n\n', bitWrapper?.id, this.globalNotebookApiQueryParams)
          .subscribe(async (createdBitsResponse: { bits: Array<BitApiWrapper> }) => {
            const brandingBit = Object.assign({}, bitWrapper || this.bitBookContent.find((b) => b.meta));
            if (brandingBit?.bit) {
              brandingBit.meta.themeId = this.bitBook.theme;
              brandingBit.meta.thisBook = {theme: this.bitBook.theme};
              brandingBit.meta.originBook = null;
              createdBitsResponse?.bits?.forEach(x => {
                x.meta = Object.assign({}, x.meta, {thisBook: {theme: this.brandingRenderService.getBookThemeBranding(brandingBit.meta)}});
                (x.bit as ArticleBit).isBeingGenerated = true;
                x.bit.instruction = 'Summary';
                x.isEditNotAllowed = true;
              });
            }
            if (bitWrapper && (!bitWrapper?.editorLoadingPre && !bitWrapper?.editorLoadingPost)) {
              return;
            }
            this.setGeneratingBitLoading(bitWrapper, false);
            this.addBitsToBookContent(createdBitsResponse?.bits, bitWrapper?.id);

            const response = await this.bitBookApiService.generateBookSummaryFromBitmarkStream(bitmark, language);
            const newBit = this.bitBookContent[bitToLoadIndex + 1] as any;
            const reader = response.body.getReader();

            let buffer = '';
            while (true) {
              const {done, value} = await reader.read();
              if (done) {
                newBit.isEditNotAllowed = false;
                this.bitbookMqService.notifyBitGenerated(newBit.id);
                this.bitEditorStatus = BitEditorStatus.Updating;
                this.bitBookApiService.editBitJson(newBit?.bit, newBit?.id)
                  .subscribe((data: { tocEntry: TocItem, bit: BitApiWrapper }) => {
                    this.setReaderAsReady();
                  }, (err) => {
                    this.bitEditorStatus = BitEditorStatus.Failed;
                  });
                break;
              }
              const stringValue = new TextDecoder().decode(value);
              if (stringValue?.length > 100 && (stringValue[0] === 'Error' || stringValue[0] === '{')) {
                break;
              }
              if (stringValue.indexOf('---summary---') !== -1) {
                newBit.bit.instruction = stringValue.replace('---summary---', '');
              } else {
                buffer += stringValue;
                if (newBit) {
                  newBit.bit = Object.assign({}, newBit.bit, {body: buffer});
                }
              }
            }
            if (response.status >= 400) {
              alert('ChatGPT is not available right now. Please try again later!');
              this.setGeneratingBitLoading(bitWrapper, false);
              return;
            }
          }, (err) => {
            alert('There was an issue saving your summary. Please try again later!');
            console.error(err);
          });
      });
    }, (err) => {
      alert('There was an issue processing your summary request. Please try again later!');
      console.error(err);
    });
  }

  private getTextFromProseMirrorJson(json) {
    let text = '';

    function extractText(node) {
      if (node.type === 'text') {
        text += node.text;
      }
      if (node.content) {
        node.content?.length ? node.content.forEach(extractText) : '';
      }
    }

    extractText(json);

    return text;
  }


  protected stopBitGeneration(dropdownItemModel: DropdownItemModel) {
    const bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    bitWrapper.editorLoadingPre = false;
    bitWrapper.editorLoadingPost = false;
    this.bitBookContent[0].editorLoadingPre = false;
    this.bitBookContent[0].editorLoadingPost = false;
  }

  protected transcribeMediaBit(dropdownItemModel: DropdownItemModel) {
    const bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    if (bitWrapper) {
      bitWrapper.editorLoadingPost = true;
    }
    const bitResourceSrc = bitWrapper?.bit?.resource?.video?.src
      || bitWrapper?.bit?.resource?.audio?.src
      || bitWrapper?.bit?.answer?.audio?.src
      || bitWrapper?.bit?.answer?.video?.src;

    if (!bitResourceSrc) {
      alert('There is no resource associated with this bit');
      if (bitWrapper) {
        bitWrapper.editorLoadingPost = false;
      }
      return;
    }
    this.bitBookApiService.transcribeMediaBitStream(bitResourceSrc).subscribe((res) => {
      if (bitWrapper) {
        bitWrapper.editorLoadingPost = false;
      }
      this.createAnnotationNoteOnBit({data: bitWrapper}, 'Speech to Text', res.text);
    }, (err) => {
      alert('ChatGPT is not available right now. Please try again later!');
      console.error(err);
    });
  }

  protected translateBit(dropdownItemModel: DropdownItemModel) {
    const bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    if (!bitWrapper) {
      return;
    }

    const modalRef = this.ngbModal.open(BitTranslateLanguagePickerComponent, {
      windowClass: 'transparent-modal md',
      animation: false,
    });

    const sub = (modalRef.componentInstance as BitTranslateLanguagePickerComponent).languageChosen.subscribe((language: string) => {
      if (!language) {
        return;
      }
      sub.unsubscribe();
      bitWrapper.editorLoadingPost = true;
      this.bitEditorStatus = BitEditorStatus.Updating;
      const sourceLanguages = Array.from(new Set(['detect', bitWrapper?.meta?.language, navigator.language?.split('-')[0], this.translate.currentLang])).filter((l) => l && l !== language);
      this.bitBookApiService.translateContent(bitWrapper, language, sourceLanguages)
        .subscribe((translatedBitmark) => {
          bitWrapper.editorLoadingPost = false;
          this.setReaderAsReady();
          const theme = (bitWrapper as any)?.theme || bitWrapper?.meta?.originBook?.theme || bitWrapper?.meta?.thisBook?.theme;
          const publisher = (bitWrapper as any)?.publisher || bitWrapper?.meta?.publisher?.code || bitWrapper?.meta?.originBook?.publisher?.code || bitWrapper?.meta?.thisBook?.publisher?.code;
          const resource = bitWrapper.bit?.resource;
          // (translatedBit as any).bit.theme = theme;
          // (translatedBit as any).bit.publisher = publisher;
          // this.cloneBitWithTranslation(translatedBit, bitWrapper.id);
          const content = translatedBitmark.bitmark;
          const lines = content.split('\n');
          if (content.indexOf('[@publisher:') === -1) {
            lines.splice(1, 0, `[@publisher:${publisher || 'system'}]`);
          }
          if (content.indexOf('[@theme:') === -1 && theme?.length) {
            lines.splice(1, 0, `[@theme:${theme}]`);
          }
          const finalBitmark = lines.join('\n');

          this.bitBookApiService.addBitmarkBitAfterBit(this.bitBook.externalId, finalBitmark, bitWrapper.id).subscribe((res) => {
            if (resource && res.bits.length && res.bits[0]?.bit?.resource) {
              this.applyBitImageSize(resource, res.bits[0]);
            }

            this.addBitsToBookContent(res.bits, bitWrapper.id);
            this.setReaderAsReady();
          }, (err) => {
            console.error(err);
            alert('There was an error saving your translated bit.\n Please try again later');
          });
          this.analyticsService.record('bit-translate', {
            bitId: bitWrapper.id,
            bitType: bitWrapper.bit?.type,
            bitInstanceId: bitWrapper?.bitInstanceId,
            bookId: bitWrapper?.meta?.thisBook?.id || bitWrapper?.meta?.originBook?.id,
            bookExternalId: bitWrapper?.meta?.thisBook?.externalId || bitWrapper?.meta?.originBook?.externalId,
            language: bitWrapper.bit?.lang || bitWrapper.meta?.language,
            learningLanguage: bitWrapper.meta?.learningLanguage,
            chapterPath: bitWrapper.chapterPath,
            tag: bitWrapper.tags,
            analyticsTag: bitWrapper.analyticsTag,
            reductionTag: bitWrapper.reductionTag
          });
        }, (err) => {
          console.error(err);
          alert('An error has occurred while translating the bit');

          bitWrapper.editorLoadingPost = false;
          this.bitEditorStatus = BitEditorStatus.Failed;
        });
    });
  }

  private applyBitImageSize(initialResource: BitResource, destBit: BitApiWrapper) {
    if (!initialResource || !destBit.bit?.resource) {
      return;
    }

    if (destBit.bit.resource.image && initialResource.image) {
      destBit.bit.resource.image.width = initialResource.image.width;
      destBit.bit.resource.image.height = initialResource.image.height;
    }
    if (destBit.bit.resource.imagePortrait && initialResource.imagePortrait) {
      destBit.bit.resource.imagePortrait.width = initialResource.imagePortrait.width;
      destBit.bit.resource.imagePortrait.height = initialResource.imagePortrait.height;
    }
    if (destBit.bit.resource.imageLandscape && initialResource.imageLandscape) {
      destBit.bit.resource.imageLandscape.width = initialResource.imageLandscape.width;
      destBit.bit.resource.imageLandscape.height = initialResource.imageLandscape.height;
    }
  }

  private getBitPropertyValue(value: any, editorType: TipTapEditorType): { value: string, isHtml: boolean } {
    if (!value) {
      return {value: '', isHtml: false};
    }

    return typeof value === 'string'
      ? {value, isHtml: false}
      : {
        value: generateHTML(new BitmarkPipe(this.readerTipTapService, new UrlRewriteService()).handleJsonContentValue(value), this.readerTipTapService.getExtensions(editorType)),
        isHtml: true,
      };
  }

  getMiscellaneousBitPropertyValues(bitWrapper: BitApiWrapper) {
    const bit = (bitWrapper?.bit as any);
    if (bit?.quizzes?.length) {
      return [JSON.stringify(bit?.quizzes)];
    }
    if (bit?.responses?.length) {
      return [JSON.stringify(bit?.responses)];
    }
    if (bit?.pairs?.length) {
      return [JSON.stringify(bit?.pairs)];
    }
    return null;
  }

  private cloneBitWithTranslation(translatedBit: BitApiWrapper, afterBitId: string) {
    const clonedBit: any = JSON.parse(JSON.stringify({
      ...translatedBit?.bit,
      id: undefined
    }));

    this.bitBookApiService.insertBitAfterBit(this.bitBook.id, clonedBit, afterBitId, null, this.globalNotebookApiQueryParams)
      .subscribe((res: { bits: Array<BitApiWrapper>, toc: any }) => {
        res.bits[0].isBeingEdited = true;
        this.addBitsToBookContent(res.bits, afterBitId);
      }, err => console.error(err));
  }

  //endregion

  //region Annotations

  createAnnotationNoteFromId() {
    this.annotationMenuInstance.style.display = 'none';
    if (window.getSelection().toString().length) {
      const bitElement = this.getParentBitElement(window.getSelection().anchorNode);
      const bitId = bitElement.id.split('-')[1];
      const bitWrapper = this.bitBookContent.find((b) => (b.id as any) === +bitId);
      this.createAnnotationNoteOnBit({data: bitWrapper}, window.getSelection().toString()?.trim());
    }
  }

  createAnnotationHandwrittenFromId() {
    this.annotationMenuInstance.style.display = 'none';
    if (window.getSelection().toString().length) {
      const bitElement = this.getParentBitElement(window.getSelection().anchorNode);
      const bitId = bitElement.id.split('-')[1];
      const bitWrapper = this.bitBookContent.find((b) => (b.id as any) === +bitId);
      this.createAnnotationHandwrittenOnBit({data: bitWrapper}, window.getSelection().toString()?.trim());
    }
  }

  createAnnotationBookmarkFromId() {
    this.annotationMenuInstance.style.display = 'none';
    if (window.getSelection().toString().length) {
      const bitElement = this.getParentBitElement(window.getSelection().anchorNode);
      const bitId = bitElement.id.split('-')[1];
      const bitWrapper = this.bitBookContent.find((b) => (b.id as any) === +bitId);
      this.createAnnotationBookmarkOnBit({data: bitWrapper}, window.getSelection().toString()?.trim());
    }
  }

  createAnnotationFavoriteFromId() {
    this.annotationMenuInstance.style.display = 'none';
    if (window.getSelection().toString().length) {
      const bitElement = this.getParentBitElement(window.getSelection().anchorNode);
      const bitId = bitElement.id.split('-')[1];
      const bitWrapper = this.bitBookContent.find((b) => (b.id as any) === +bitId);
      this.createAnnotationFavoriteOnBit({data: bitWrapper}, window.getSelection().toString()?.trim());
    }
  }

  protected createAnnotationNoteOnBit(dropdownItemModel: DropdownItemModel, title = '', content?: string) {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }
    if (!bitWrapper?.id) {
      return;
    }

    const titleContent = title
      ? [{
        attrs: {
          class: 'normal',
          paragraphType: 'normal',
        },
        content: [
          {
            text: title,
            type: 'text',
          },
        ],
        type: 'paragraph',
      }]
      : null;

    const bodyContent = content
      ? [{
        attrs: {
          class: 'normal',
          paragraphType: 'normal',
        },
        content: [
          {
            text: content || '',
            type: 'text',
          },
        ],
        type: 'paragraph',
      }]
      :
      null;

    const newBit: AnnotationNoteBit = {
      title: titleContent,
      content: bodyContent,
      color: DefaultBitAnnotationColors[0],
      format: BitmarkFormat.PP,
      type: BitType.AnnotationNote,
    };
    this.bitBookApiService.addBitAnnotation(bitWrapper?.id, newBit, BitType.AnnotationNote)
      .subscribe((result: { createdAnnotations: Array<BitApiAnnotation> }) => {
        if (!bitWrapper.annotations?.length) {
          bitWrapper.annotations = [];
        }
        if (result?.createdAnnotations?.length && result?.createdAnnotations[0]?.id) {
          this.onAnnotationCreated(result?.createdAnnotations[0]?.id, result?.createdAnnotations[0].tocEntry);
        }

        bitWrapper.annotations = [...bitWrapper.annotations, ...result.createdAnnotations];
      }, (err) => console.error(err));
  }

  protected createAnnotationHandwrittenOnBit(dropdownItemModel: DropdownItemModel, title = '') {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }
    if (!bitWrapper?.id) {
      return;
    }

    const newBit: AnnotationNoteBit = {
      title: title
        ? [{
          attrs: {
            class: 'normal',
            paragraphType: 'normal',
          },
          content: [
            {
              text: title,
              type: 'text',
            },
          ],
          type: 'paragraph',
        }]
        : null,
      content: [],
      color: DefaultBitAnnotationColors[0],
      format: BitmarkFormat.Text,
      type: BitType.AnnotationHandwritten,
    };
    this.bitBookApiService.addBitAnnotation(bitWrapper?.id, newBit, BitType.AnnotationHandwritten)
      .subscribe((result: { createdAnnotations: Array<BitApiAnnotation> }) => {
        if (!bitWrapper.annotations?.length) {
          bitWrapper.annotations = [];
        }
        if (result?.createdAnnotations?.length && result?.createdAnnotations[0]?.id) {
          // this.selectNoteAfterCreation(result?.createdAnnotations[0]?.id.toString());
          this.onAnnotationCreated(result?.createdAnnotations[0]?.id, result?.createdAnnotations[0].tocEntry, false);
        }

        bitWrapper.annotations = [...bitWrapper.annotations, ...result.createdAnnotations];
      }, (err) => console.error(err));
  }

  protected createAnnotationBookmarkOnBit(dropdownItemModel: DropdownItemModel, title = '') {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }
    if (!bitWrapper?.id) {
      return;
    }

    const newBit: AnnotationBookmarkBit = {
      content: title
        ? [{
          attrs: {
            class: 'normal',
            paragraphType: 'normal',
          },
          content: [
            {
              text: title,
              type: 'text',
            },
          ],
          type: 'paragraph',
        }]
        : null,
      color: DefaultBitAnnotationColors[2],
      format: BitmarkFormat.PP,
      type: BitType.AnnotationBookmark,
    };

    this.bitBookApiService.addBitAnnotation(bitWrapper?.id, newBit, BitType.AnnotationBookmark)
      .subscribe((result: { createdAnnotations: Array<BitApiAnnotation> }) => {
        if (!bitWrapper.annotations?.length) {
          bitWrapper.annotations = [];
        }
        if (result?.createdAnnotations?.length && result?.createdAnnotations[0]?.id) {
          this.onAnnotationCreated(result?.createdAnnotations[0]?.id, result?.createdAnnotations[0].tocEntry);
        }
        bitWrapper.annotations = [...bitWrapper.annotations, ...result.createdAnnotations];
      }, (err) => console.error(err));
  }

  protected createAnnotationFavoriteOnBit(dropdownItemModel: DropdownItemModel, title = '') {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }
    if (!bitWrapper?.id) {
      return;
    }

    const newBit: AnnotationBookmarkBit = {
      content: title
        ? [{
          attrs: {
            class: 'normal',
            paragraphType: 'normal',
          },
          content: [
            {
              text: title,
              type: 'text',
            },
          ],
          type: 'paragraph',
        }]
        : null,
      color: DefaultBitAnnotationColors[3],
      format: BitmarkFormat.PP,
      type: BitType.AnnotationFavorite,
    };
    this.bitBookApiService.addBitAnnotation(bitWrapper?.id, newBit, BitType.AnnotationFavorite)
      .subscribe((result: { createdAnnotations: Array<BitApiAnnotation> }) => {
        if (!bitWrapper.annotations?.length) {
          bitWrapper.annotations = [];
        }
        if (result?.createdAnnotations?.length && result?.createdAnnotations[0]?.id) {
          this.onAnnotationCreated(result?.createdAnnotations[0]?.id, result?.createdAnnotations[0].tocEntry);
        }

        bitWrapper.annotations = [...bitWrapper.annotations, ...result.createdAnnotations];
      }, (err) => console.error(err));
  }

  private onAnnotationCreated(createdAnnotationId: number, tocEntry: TocItem, shouldFocus = true) {
    if (shouldFocus) {
      const editorCreatedSub = this.bitbookMqService.onEditorCreated()
        .subscribe((editor: ReaderTextEditorComponent | ReaderSimpleTextEditorComponent) => {
          this.domUtilsService.waitForDifferentStyleValue(`#bit-${createdAnnotationId.toString()}`, 'visibility', 'hidden')
            .subscribe(() => {
              editor.focus();
            });
          editorCreatedSub.unsubscribe();
        });
    }

    this.refreshTocBit(tocEntry);
    if (this.context === 'new-release') {
      return;
    }
    this.readerContentService.computeAndNotifyBitsVisibility(() => this.bitBookContent);
  }

  toggleAnnotationHighlight() {
    this.annotationMenuInstance.style.display = 'none';
    if (!window.getSelection().toString().length) {
      return;
    }

    const isOverlapping = (this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'user-highlight')
        && this.elementOrAncestorHasClass(window.getSelection().focusNode, 'user-highlight'))
      || (this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'bitmark-text-user-highlight')
        && this.elementOrAncestorHasClass(window.getSelection().focusNode, 'bitmark-text-user-highlight'));

    if (isOverlapping) {
      this.removeAnnotationHighlight();
    } else {
      this.createAnnotationHighlight();
    }
  }

  createAnnotationHighlight() {
    this.annotationMenuInstance.style.display = 'none';
    if (window.getSelection().toString().length) {
      const bitElement = this.getParentBitElement(window.getSelection().anchorNode);

      const markElem = document.createElement('mark');
      markElem.className = 'bitmark-text-user-highlight';

      const elems = highlightRange(bitElement, window.getSelection().getRangeAt(0), markElem);
      normalizeHighlights(elems);

      window.getSelection().removeAllRanges();

      const bitId = bitElement.id.replace('bit-', '');

      const bodyElem = bitElement.querySelector('.bit-body-allow-highlight .bitmark-text');
      this.saveBitBitmark(bitId, {body: bodyElem.innerHTML});
    }
  }

  removeAnnotationHighlight() {
    this.annotationMenuInstance.style.display = 'none';
    if (window.getSelection().toString().length) {
      const bitElement = this.getParentBitElement(window.getSelection().anchorNode);
      const range = window.getSelection().getRangeAt(0);

      const iterator = document.createNodeIterator(
        range.commonAncestorContainer,
        NodeFilter.SHOW_ALL, // pre-filter
        {
          // custom filter
          acceptNode: function () {
            return NodeFilter.FILTER_ACCEPT;
          },
        });

      const nodes = [];
      while (iterator.nextNode()) {
        if (nodes.length === 0 && iterator.referenceNode !== range.startContainer) {
          continue;
        }

        if (iterator.referenceNode.parentElement.classList.contains('user-highlight')
          || iterator.referenceNode.parentElement.classList.contains('bitmark-text-user-highlight')) {
          nodes.push(iterator.referenceNode);
        }

        if (iterator.referenceNode === range.endContainer) {
          break;
        }
      }

      nodes?.forEach(node => {
        removeHighlights(node.parentElement);
      });

      window.getSelection().removeAllRanges();

      const bitId = bitElement.id.replace('bit-', '');

      const bodyElem = bitElement.querySelector('.bit-body-allow-highlight .bitmark-text');
      this.saveBitBitmark(bitId, {body: bodyElem.innerHTML});
    }
  }

  @memoize()
  getApiWrapperFromAnnotation(bitApiWrapper: BitApiWrapper, annotation: BitApiAnnotation): BitApiWrapper {
    return {
      id: annotation.id.toString(),
      bit: {id: annotation.id.toString(), ...annotation.data},
      meta: bitApiWrapper.meta,
      showBitActions: bitApiWrapper?.showBitActions
    };
  }

  @memoize()
  getBitAnnotationActions(annotation: BitApiAnnotation): Array<DropdownItemModel> {
    let annotationColor = (annotation.data as AnnotationBaseBit)?.color?.primary;

    if (!annotationColor) {
      annotationColor = BitAnnotationDefaultColor[annotation.type]?.primary;
    }

    return this.bitAnnotationActions.map(action => {
      if (action.id === 'color') {
        action.colors = action.colors.map(color => {
          color.isSelected = color.primary === annotationColor;

          return color;
        });
      }

      return action;
    });
  }

  @memoize()
  getTopAnnotations(annotations: Array<BitApiAnnotation>): Array<BitApiAnnotation> {
    return annotations.filter(x => x.type === BitType.AnnotationFavorite || x.type === BitType.AnnotationBookmark);
  }

  @memoize()
  getBottomAnnotations(annotations: Array<BitApiAnnotation>): Array<BitApiAnnotation> {
    return annotations.filter(x => x.type === BitType.AnnotationNote || x.type === BitType.AnnotationHandwritten);
  }

  saveEditedAnnotation(bit: any) {
    this.bitEditorStatus = BitEditorStatus.Updating;

    const payload = {...bit.bitWrapper.bit, ...bit.content};

    this.bitBookApiService.updateBitAnnotation(bit.bitWrapper.id, payload)
      .subscribe((result: BitApiAnnotation) => {
        this.refreshTocBit(result?.tocEntry);
        const associatedBit = this.bitBookContent.find((b) => +b.id === result.bitId);
        const existingAnnotationIdx = associatedBit?.annotations.findIndex((a) => a.id === result.id);
        if (existingAnnotationIdx > -1) {
          associatedBit.annotations[existingAnnotationIdx].data = result.data;
        }
        this.setReaderAsReady();
      }, (err) => {
        console.error(err);
        this.bitEditorStatus = BitEditorStatus.Failed;
      });
  }

  selectAnnotationColor(dropdownItemModel: DropdownItemModel) {
    const bit = this.bitBookContent.find(bookBit => {
      return bookBit.annotations?.find(x => x.id == dropdownItemModel.data.id);
    });

    if (bit && bit.annotations) {
      bit.annotations = bit.annotations.map(x => {
        if (x.id == dropdownItemModel.data.id) {
          const bitAnnotationData = x.data as AnnotationBaseBit;
          if (bitAnnotationData) {
            bitAnnotationData.color = dropdownItemModel.selectedColor;
            x = {
              ...x,
              data: {...bitAnnotationData},
            };

            this.bitEditorStatus = BitEditorStatus.Updating;
            this.bitBookApiService.updateBitAnnotation(x.id, bitAnnotationData)
              .subscribe((result) => {
                this.refreshTocBit(result?.tocEntry);
                this.setReaderAsReady();
              }, (err) => {
                console.error(err);
                this.bitEditorStatus = BitEditorStatus.Failed;
              });
          }
        }

        return x;
      });
    }
  }

  deleteAnnotation(dropdownItemModel: DropdownItemModel) {
    this.bitBookApiService.deleteBitAnnotation(dropdownItemModel.data.id)
      .subscribe((result) => {
        const bit = this.bitBookContent.find(bookBit => {
          return bookBit.annotations?.find(x => x.id == dropdownItemModel.data.id);
        });

        if (bit && bit.annotations) {
          bit.annotations = bit.annotations.filter(x => x.id != dropdownItemModel.data.id);
        }

        this.refreshTocBit(result?.tocEntry);
        this.readerContentService.computeAndNotifyBitsVisibility(() => this.bitBookContent);
      }, (err) => {
        console.error(err);
      });
  }

  //endregion

  //region Notebook notes

  selectNoteAfterCreation(bitId: string) {
    const el = document.querySelector(`#bit-${bitId} .ProseMirror[contenteditable="true"]`);
    if (el) {
      this.selectContentEditableElement(el);
    } else {
      setTimeout(() => {
        this.selectNoteAfterCreation(bitId);
      }, 100);
    }
  }

  //endregion

  //region Paste special

  async pasteFlashcardsFromQuizlet(dropdownItemModel: DropdownItemModel) {
    const text = await navigator.clipboard.readText();
    this.uploadCSVData(dropdownItemModel.data, text, '\t');
  }

  async pasteStepFromScribePro(dropdownItemModel: DropdownItemModel) {
    let text = await navigator.clipboard.readText();
    text = this.applyScribeProMultipleRegexReplacements(text);
    if (!text) {
      return;
    }

    const previousBitWrapper = (dropdownItemModel.data as BitApiWrapper);
    this.bitBookApiService.addBitmarkBitAfterBit(this.bitBook.id, text, previousBitWrapper?.id, null, this.globalNotebookApiQueryParams)
      .subscribe((data: { bits: Array<BitApiWrapper>, toc?: Array<TocItem> }) => {
        this.addBitsToBookContent(data.bits, previousBitWrapper?.id);
      }, err => console.error(err));
  }

  protected uploadCSVData(previousBit: BitApiWrapper, content: string, termSeparator: string, rowSeparator = '\n') {
    const bits: Array<Flashcard1Bit> = [];

    if (termSeparator === '\t') {
      const regex = /(?:^"((?:.|\n)*?)"|(.*?))\t(?:"((?:.|\n)*?)"|(.*?))(?:\n|$)/gm;
      const contentLines: Array<Array<string>> = this.getTSVMatchGroups(content, regex);
      contentLines.forEach(line => {
        const [question, answer] = line;
        if (question?.trim() || answer?.trim()) {
          bits.push({
            format: BitmarkFormat.MM,
            type: BitType.Flashcard1,
            cards: [{
              question: question?.trim() || '',
              answer: answer?.trim() || '',
            }],
          });
        }
      });
    } else {
      const contentLines = content.split(rowSeparator);
      contentLines.forEach(line => {
        const [question, answer] = line.split(termSeparator);
        if (question?.trim() || answer?.trim()) {
          bits.push({
            format: BitmarkFormat.MM,
            type: BitType.Flashcard1,
            cards: [{
              question: question?.trim() || '',
              answer: answer?.trim() || '',
            }],
          });
        }
      });
    }


    if (bits?.length) {
      this.bitBookApiService.insertBitsAfterBit(this.bitBook.id, bits, previousBit?.id, null, this.globalNotebookApiQueryParams)
        .subscribe((res: { bits: Array<BitApiWrapper>, toc?: Array<TocItem> }) => {
          this.addBitsToBookContent(res.bits, previousBit?.id);
        }, err => console.error(err));
    }
  }

  private getTSVMatchGroups(str: string, regex: RegExp) {
    let match: RegExpMatchArray;
    const result = [];

    while ((match = regex.exec(str)) !== null) {
      const groups = match.slice(1); // Get all capturing groups
      const nonEmptyGroups = groups.filter(item => item !== undefined && item !== '');
      result.push(nonEmptyGroups);
    }

    return result;
  }

  private applyScribeProMultipleRegexReplacements(text: string) {
    const replacements = [
      {
        pattern: /^#### (.*)/gm,
        replacement: '[.article]\n$1',
      },
      {
        pattern: /^#(.*)/gm,
        replacement: '[.chapter]\n[$&]\n',
      },
      {
        pattern: /!(\[((?:\[[^\]]*\]|[^\[\]])*)\]\([ \t]*()<?((?:\([^)]*\)|[^()\s])*?)>?[ \t]*((['"])(.*?)\6[ \t]*)?\))/gm,
        replacement: '[&image:$4]',
      },
      {
        pattern: /^([0-9]+)\\?\.(.+)/gm,
        replacement: '[.step-image-screenshot]\n[%$1]\n[!$2]',
      },
      {
        pattern: /(\[((?:\[[^\]]*\]|[^\[\]])*)\]\([ \t]*()<?((?:\([^)]*\)|[^()\s])*?)>?[ \t]*((['"])(.*?)\6[ \t]*)?\))/gm,
        replacement: '==$2==|link:$4|',
      },
      {
        pattern: /\\$/gm,
        replacement: '',
      },
    ];

    replacements.forEach(replacement => {
      text = text.replace(replacement.pattern, replacement.replacement);
    });

    return text;
  }

  //endregion

  //region PDF

  onJobFinished(jobId: any, bitWrapper: BitApiWrapper) {
    bitWrapper.editorOperation.data.runningJobs = bitWrapper.editorOperation.data.runningJobs
      .filter(j => jobId !== j.name);
    if (bitWrapper.editorOperation.data.runningJobs.length === 0) {
      bitWrapper.editorOperation = null;
      this.renderPage();
    }
  }

  insertPdf(dropdownItemModel?: DropdownItemModel) {
    if (!dropdownItemModel?.data) {
      return this.currentAfterBit = {data: this.bitBookContent[0]};
    }
    this.currentAfterBit = dropdownItemModel;
    this.fileUtilsService.pickFromDesktopFileSystem('application/pdf')
      .subscribe((files: File[]) => {
        this.bitEditorStatus = BitEditorStatus.Updating;
        this.createJob(files, this.currentAfterBit?.data);
      });
  }

  createJob(files: Array<File>, bitWrapper: BitApiWrapper) {
    const bit = this.bitBookContent.find((b) => b.id === bitWrapper?.id);
    if (bit) {
      bit.editorOperation = {
        operationType: ReaderEditorOperationType.PdfUpload,
        data: {
          runningJobs: [],
        },
      };
      for (let i = 0; i < files.length; i++) {
        bit.editorOperation.data.runningJobs.push(files[i]);
      }
    }
  }

  //endregion

  //region Code

  protected changeCodeLanguage(dropdownItemModel: DropdownItemModel) {
    const bitId = (dropdownItemModel.data as BitApiWrapper).id;
    this.bitbookMqService.notifyBitCodeLanguageChanged(bitId, dropdownItemModel.id);
  }

  //endregion

  protected saveBitBitmark(id: string, data: any) {
    const bookBit = this.bitBookContent.find(x => x.id == id);

    const changedData = {};
    for (const dataProp of Object.keys(data)) {
      changedData[dataProp] = new BitmarkReversePipe(this.readerTipTapService).transform(data[dataProp]);
    }

    this.bitEditorStatus = BitEditorStatus.Updating;

    const changedBit = {...bookBit.bit, ...changedData};
    this.bitBookApiService.saveAnswer(bookBit.id, changedBit, bookBit.bitInstanceId)
      .subscribe(() => this.setReaderAsReady(),
        (err: HttpErrorResponse) => {
          console.error(err);
          this.bitEditorStatus = BitEditorStatus.Failed;
        });
  }

  protected getParentBitElement(element) {
    if (!element || element.length === 0) {
      return null;
    }
    let parent = element;
    do {
      if (parent === document) {
        break;
      }
      if (parent.className?.indexOf('bit-wrapper1') >= 0) {
        return parent;
      }
    } while (parent = parent.parentNode);
    return false;
  }

  protected getPreviousBitChapter(bitWrapper) {
    const bitIndex = bitWrapper.index;
    let previousChapter;
    if (bitWrapper.bit.type === 'chapter') {
      previousChapter = bitWrapper;
    } else {
      for (let i = bitIndex; i >= 0; i--) {
        if (this.bitBookContent[i].bit?.type === BitType.Chapter) {
          previousChapter = this.bitBookContent[i];
          break;
        }
      }
    }
    return previousChapter;
  }

  protected elementOrAncestorHasClass(element, className) {
    if (!element || element.length === 0) {
      return false;
    }
    let parent = element;
    do {
      if (parent === document) {
        break;
      }
      if (parent.className?.indexOf(className) >= 0) {
        return true;
      }
    } while (parent = parent.parentNode);
    return false;
  }

  getBrandingThemeClass(bitWrapper: BitApiWrapper) {
    const publisherId = +bitWrapper.meta?.publisherId || +bitWrapper.meta?.thisBook?.publisherId || +bitWrapper.meta?.originBook?.publisherId || 0;
    const themeId = this.brandingRenderService.getBookThemeBranding(bitWrapper.meta);
    return `bitmark-publisher-${publisherId}-theme-${themeId}`;
  }

  private getSurroundingChaptersInToc(bitId: string, toc: Array<TocItem>) {
    if (bitId) {
      const currentBitInToc = toc.find((b) => b.ref === bitId);
      const currentBitIndexInToc = toc.findIndex((b) => b.ref === bitId);
      let previousChapterToc;
      if (currentBitInToc.type === BitType.Chapter) {
        previousChapterToc = currentBitInToc;
      } else {
        for (let i = currentBitIndexInToc; i >= 0; i--) {
          if (toc[i].type === BitType.Chapter) {
            previousChapterToc = toc[i];
            break;
          }
        }
      }
      let nextChapterToc;
      for (let i = currentBitIndexInToc + 1; i < toc?.length; i++) {
        if (toc[i].type === BitType.Chapter && (toc[i].level <= previousChapterToc?.level || !previousChapterToc)) {
          nextChapterToc = toc[i];
          break;
        }
      }
      return {
        previous: previousChapterToc,
        next: nextChapterToc,
      };
    }
  }

  protected handleInternalLink(internalLinkBit: InternalLinkBit) {
    this.navigateToBitReference(internalLinkBit.reference);
  }

  protected navigateToBitReference(ref: string, bitId?: string) {
    this.readerTocService.getBitByReferenceAnchor(this.bitBook.externalId, ref)
      .subscribe((targetedBit) => {
        if (targetedBit) {
          // this.location.replaceState(
          //   this.router.createUrlTree(
          //     [],
          //     {
          //       fragment: `${targetedBit.ref}`,
          //       queryParamsHandling: 'merge'
          //     })
          //     .toString(),
          // );
          this.location.go(
            this.router.createUrlTree(
              [],
              {
                fragment: `${targetedBit.ref}`,
                queryParamsHandling: 'merge'
              })
              .toString()
          );
          this.loadBit(targetedBit.ref).subscribe();
        } else {
          const refBit = this.bitBookContent.find(x => x.id == bitId);
          if (refBit?.meta?.originBook?.externalId) {
            this.readerContentService.getBookBit(refBit.meta.originBook.externalId, ref).subscribe(tocBit => {
              this.navigateToBook.emit({bookId: refBit.meta.originBook.externalId, fragment: tocBit?.ref});
            });
          }
        }
      });
  }

  protected navigateToBookBitReference(bookId: string, ref: string) {
    if (!bookId) {
      return;
    }
    if (!ref) {
      this.navigateToBook.emit({bookId, fragment: null});
      return;
    }
    this.readerTocService.getBitByReferenceAnchor(bookId, ref)
      .subscribe((targetedBit) => {
        if (targetedBit) {
          this.navigateToBook.emit({bookId, fragment: targetedBit.ref});
        }
      });
  }
}
