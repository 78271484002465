// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-buy-button-promotion-container {
  align-items: center;
}
.page-buy-button-promotion-container > div {
  display: flex;
  align-items: center;
  flex-direction: row;
}

@media (max-width: 1024px) {
  .page-buy-button-promotion-container {
    flex-direction: column;
  }
}
.buy-button-container {
  display: flex;
  justify-content: var(--bitmark-page-buy-button-promotion-flex-align, flex-start);
}

::ng-deep .page-buy-button-promotion-container .bitmark-instruction b, ::ng-deep .page-buy-button-promotion-container .bitmark-instruction strong {
  font-family: var(--bitmark-page-buy-button-promotion-bold-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-page-buy-button-promotion-bold-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-page-buy-button-promotion-bold-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-page-buy-button-promotion-bold-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-page-buy-button-promotion-bold-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-page-buy-button-promotion-bold-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-page-buy-button-promotion-bold-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-page-buy-button-promotion-bold-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-page-buy-button-promotion-bold-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-page-buy-button-promotion-bold-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
}

.buy-button-body {
  margin: var(--bitmark-page-buy-button-promotion-body-size-margin);
  padding: var(--bitmark-page-buy-button-promotion-body-size-padding);
  height: var(--bitmark-page-buy-button-promotion-body-size-height);
  width: var(--bitmark-page-buy-button-promotion-body-size-width);
}

.btn-background-calm.buy-button {
  background: var(--bitmark-page-buy-button-promotion-button-background-shorthand, var(--bitmark-page-buy-button-promotion-button-background, #543199));
  border-top: var(--bitmark-page-buy-button-promotion-button-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-page-buy-button-promotion-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-page-buy-button-promotion-button-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-page-buy-button-promotion-button-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-page-buy-button-promotion-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-page-buy-button-promotion-button-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-page-buy-button-promotion-button-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-page-buy-button-promotion-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-page-buy-button-promotion-button-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-page-buy-button-promotion-button-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-page-buy-button-promotion-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-page-buy-button-promotion-button-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-page-buy-button-promotion-button-border-radius, var(--bitmark-x-border-radius, 30px));
  width: var(--bitmark-page-buy-button-promotion-button-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-page-buy-button-promotion-button-size-height, var(--bitmark-x-size-height, inherit));
  margin: var(--bitmark-page-buy-button-promotion-button-size-margin, var(--bitmark-x-size-margin, 0));
  padding: var(--bitmark-page-buy-button-promotion-button-size-padding, var(--bitmark-x-size-padding, 0));
  font-family: var(--bitmark-page-buy-button-promotion-button-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-page-buy-button-promotion-button-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-page-buy-button-promotion-button-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-page-buy-button-promotion-button-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-page-buy-button-promotion-button-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-page-buy-button-promotion-button-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-page-buy-button-promotion-button-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-page-buy-button-promotion-button-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-page-buy-button-promotion-button-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-page-buy-button-promotion-button-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
}
.btn-background-calm.buy-button.buy-button-promotion {
  top: -4px;
  position: relative;
  margin-left: 5px;
}

.add-to-library {
  padding: 5px 0 0 0;
  border-radius: 100%;
  min-width: inherit;
  width: 40px;
  height: 40px;
  border: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
