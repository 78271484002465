import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '@faubulous/ng2-ui-auth';
import {CookieService} from "ngx-cookie-service";
import {concatMap, forkJoin, Observable, of} from 'rxjs';
import {catchError, map, tap, timeout} from 'rxjs/operators';
import {BrowserStorageService} from '../browser-storage/browser-storage.service';
import {ApiService} from '../api/api.service';
import {IdentityService} from './identity.service';
import {UserPresenceService} from './user-presence.service';
import {IdentityUser, WindowService} from "core";
import {LoginResponse} from 'auth/auth.models';

@Injectable({providedIn: 'root'})
export class AuthApiService {
  constructor(private auth: AuthService,
              private apiService: ApiService,
              private translate: TranslateService,
              private identityService: IdentityService,
              private userPresenceService: UserPresenceService,
              private windowService: WindowService,
              private cookieService: CookieService,
              private browserStorageService: BrowserStorageService) {
  }

  window: any = window;
  nativeBridge = this.window.GetMoreBrain;

  private clearWebPushNotifications(): Observable<any> {
    const swPushEndpoint = this.browserStorageService.volatile.retrieve('sw-push-endpoint');
    return (swPushEndpoint)
      ? this.unsubscribeToWebPushNotifications(swPushEndpoint)
      : of({});
  }

  private clearIdentity(): string {
    this.cookieService.delete('redirectUrl');

    const userSpace = this.identityService.getSpace();
    const isForcedSpace = this.identityService.isForcedSpace();
    const isSsoLogin = this.identityService.isSsoLogin();
    const loginProvider = this.identityService.loginProvider() || 'personal';

    this.auth.removeToken();
    this.identityService.user = null;
    this.browserStorageService.volatile.clear();
    this.browserStorageService.idb.clear().subscribe();
    this.userPresenceService.notifyUnavailable();

    if (this.nativeBridge?.dispatchLoggedOutEvent) {
      this.nativeBridge.dispatchLoggedOutEvent();
    }
    this.translate.use('en');

    const currentBaseUrl = this.windowService.getBaseUrl();
    const loginUrl = isForcedSpace ?
      `${currentBaseUrl}/space/${loginProvider}/login?spc=${userSpace}` :
      `${currentBaseUrl}/space/${loginProvider}/login`;
    const logoutSsoUrl = `${currentBaseUrl}/logout-sso`;
    console.log('logout sso url: ', logoutSsoUrl);
    console.log(`${logoutSsoUrl}?loginUrl=${loginUrl}`);
    return (!isForcedSpace && !isSsoLogin)
      ? loginUrl
      : `${logoutSsoUrl}?loginUrl=${loginUrl}`;
  }

  tokenRefresh(): Observable<LoginResponse> {
    const user = this.identityService.user;
    const refreshToken = user?.refreshToken;
    if (!refreshToken) {
      return of(null);
    }
    const lastTokenRefreshTimeStamp = user?.tokenTimeStamp || 0;
    const diffSeconds = (Date.now() - +lastTokenRefreshTimeStamp) / 1000;
    const defResponse: LoginResponse = {
      token: this.auth.getToken(),
      fixedToken: user.fixedToken,
      refreshToken: user.refreshToken,
      user: user,
      tokenTimeStamp: user.tokenTimeStamp
    };

    const x$ = diffSeconds > 60 * 3
      ? this.apiService.post('auth/refresh-token', null, {refreshToken, claims: user.claims})
        .pipe(timeout(1000))
        .pipe(catchError(() => of(defResponse)))
        .pipe(map((response: LoginResponse) => Object.assign(response, {tokenTimeStamp: Date.now()})))
      : of(defResponse);

    return x$.pipe(tap((response: LoginResponse | null) => {
      if (!response) {
        return;
      }
      this.identityService.user = Object.assign({}, response.user, {
        fixedToken: response.fixedToken,
        refreshToken: response.refreshToken,
        tokenTimeStamp: response.tokenTimeStamp
      });
      this.auth.setToken(response.token);
    }));
  }

  logout(): Observable<string> {
    const logout$ = !this.auth.isAuthenticated() || !this.identityService.user
      ? of({})
      : forkJoin([
        this.apiService.post('auth/logout', null, {refreshToken: this.identityService.user?.refreshToken}),
        this.auth.logout(),
        this.clearWebPushNotifications()
      ]);
    return logout$
      .pipe(concatMap(() => of(this.clearIdentity())))
      .pipe(catchError(() => of(this.clearIdentity())));
  }

  unsubscribeToWebPushNotifications(endpoint: string): Observable<any> {
    return this.apiService.post('auth/unsubscribe-web-push', null, {endpoint});
  }

  requestLostPasswordLink(email: string) {
    return this.apiService.post('auth/lostPassword', null, {email});
  }

  resetPassword(passwordToken: {
    passwordNew: string,
    resetPasswordToken: string
  }) {
    return this.apiService.post('auth/resetPassword', null, passwordToken);
  }

  verifyEmail(emailVerificationToken: string): Observable<IdentityUser> {
    return this.apiService.post('auth/verify-email', null, {emailVerificationToken});
  }

  verifyEmailAuthenticated(emailVerificationToken: string): Observable<IdentityUser> {
    return this.apiService.post('auth/verify-email-authenticated', null, {emailVerificationToken});
  }
}
