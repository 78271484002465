import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {animate, style, transition, trigger} from '@angular/animations';
import {HttpErrorResponse} from '@angular/common/http';
import {concatMap, forkJoin, of, tap} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DeviceDetectorService} from 'ngx-device-detector';
import {BitbookMqService} from '../bitbook-mq.service';
import {BookConfiguration, BookEntity, ReaderModes} from '../reader.models';
import {BitbookApiService} from '../bitbook-api.service';
import {Location} from '@angular/common';
import {ReaderLocalContentService} from '../reader-local-content.service';
import {ReaderTocService} from '../reader-toc.service';
import {BitApiWrapper} from '../../bits/bits.models';
import {BookPosition, ReaderTrackingService} from '../reader-tracking.service';
import {KeypressUtilsService, ModalTouchSwipeService, SubSink} from '../../shared';
import {BrandingRenderService} from '../branding-render.service';
import {ReaderBasketComponent} from '../reader-basket/reader-basket.component';
import {BookType, NotebookTypes, ProductFamily, ReaderHiddenElements} from '../../shared/models/bitmark.models';
import {BitmarkConfig} from '../../bitmark.module';
import {Title} from '@angular/platform-browser';
import {AnalyticsService} from "../../shared/analytics/analytics.service";
import {catchError} from "rxjs/operators";

@Component({
  selector: 'bitmark-reader-book',
  templateUrl: './reader-book.component.html',
  styleUrls: ['./reader-book.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({transform: 'translateX(100%)'}),
            animate('200ms', style({transform: 'translateX(0%)'})),
          ],
        ),
        transition(
          ':leave',
          [
            style({transform: 'translateX(0%)'}),
            animate('0ms', style({transform: 'translateX(100%)'})),
          ],
        ),
      ],
    ),
  ],
})
export class ReaderBookComponent implements OnInit, OnDestroy, OnChanges {
  @Input() bitBookId: string;
  @Input() isExternalSearch: boolean;
  @Input() externalLocation: string;
  @Input() courseId?: string;
  @Input() themeId?: string;
  @Input() hiddenElements?: Array<ReaderHiddenElements> = [];
  @Input() configuration?: BookConfiguration;
  @Input() readerMode: ReaderModes;
  @Output() onClose = new EventEmitter<any>();
  @Output() metaRead = new EventEmitter<{ bookType: BookType, notebookSubtype?: NotebookTypes }>();
  @Output() sendBits = new EventEmitter<Array<BitApiWrapper>>();
  @Output() sendBitsToClass = new EventEmitter<Array<BitApiWrapper>>();
  @Output() saveBits = new EventEmitter<Array<BitApiWrapper>>();
  @Output() copyLinkToBit = new EventEmitter<BitApiWrapper>();
  @Output() assignHandIn = new EventEmitter<{ handInId: number, isSelfAssign?: boolean }>();
  @Output() navigateToBook = new EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily, queryParams?: any }>();
  @Output() navigateToProduct = new EventEmitter<{ productId: string, family?: ProductFamily }>();

  ReaderModes = ReaderModes;

  bitBook: BookEntity;
  isTableOfContentsVisible = false;
  isSearchSidebarVisible = false;
  isNotebookQuickNoteOpen = false;
  isShopReader = false;
  isSelfLearningReader = false;
  brandingClass = '';
  error: HttpErrorResponse | any;
  private sub = new SubSink();

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent($event: KeyboardEvent) {
    if (this.keypressUtilsService.isEscKey($event)) {
      if (this.isTableOfContentsVisible) {
        this.toggleToc();
      } else if (this.isSearchSidebarVisible) {
        this.toggleSearchSidebar();
      } else if (this.isExternalSearch) {
        this.closeBook();
      } else if (this.readerMode === ReaderModes.Search) {
        this.toggleSearch();
      } else if (this.isNotebookQuickNoteOpen) {
        this.isNotebookQuickNoteOpen = false;
      } else {
        this.closeBook();
      }
    }
  }

  constructor(@Inject('BitmarkConfig') private bitmarkConfig: BitmarkConfig,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private ngbModal: NgbModal,
              private title: Title,
              private location: Location,
              private deviceDetectorService: DeviceDetectorService,
              private bitbookMqService: BitbookMqService,
              private bitBookApiService: BitbookApiService,
              private readerLocalContentService: ReaderLocalContentService,
              private readerTrackingService: ReaderTrackingService,
              private readerTocService: ReaderTocService,
              private modalTouchSwipeService: ModalTouchSwipeService,
              private keypressUtilsService: KeypressUtilsService,
              private brandingRenderService: BrandingRenderService,
              private analyticsService: AnalyticsService) {
  }

  ngOnInit() {
    this.sub.sink = this.bitbookMqService.onQuickNoteShow()
      .subscribe(this.notebookQuickNoteOpen.bind(this));
    this.sub.sink = this.bitbookMqService.onReaderInvalidateBookCache()
      .subscribe(this.invalidateBookCache.bind(this));
    this.sub.sink = this.bitbookMqService.onReaderBitSelected()
      .subscribe(() => {
        this.sidebarMobileClose();
        this.notebookQuickNoteClose();
      });
    this.sub.sink = this.bitbookMqService.onSearchSideBarVisibleSet()
      .subscribe(() => this.isSearchSidebarVisible = false);
    // this.loadBook();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngOnChanges(bitBookIdChanges: any) {
    if (bitBookIdChanges?.bitBookId?.previousValue !== bitBookIdChanges?.bitBookId?.currentValue) {
      this.loadBook();
    }
  }

  private loadBook() {
    const queryParams: any = this.readerMode === ReaderModes.NewRelease ? [{useVersioning: true}] : [];
    const isSearch = this.activatedRoute.snapshot?.queryParams?.p === 'search';
    queryParams.push({ignoreFilters: !isSearch})
    // queryParams.ignoreFilters = false;
    const productId = this.bitBookId || this.activatedRoute.snapshot.url[this.activatedRoute.snapshot.url.length - 1].path as any;
    const isNotebook = isFinite(productId as any);
    this.isShopReader = [
      ReaderModes.Shop,
      ReaderModes.ShopReadonly,
      ReaderModes.ShopSection,
      ReaderModes.ShopSectionReadonly,
      ReaderModes.ShopPublic
    ].includes(this.readerMode);
    this.isSelfLearningReader = [
      ReaderModes.SelfLearningReadonly,
    ].includes(this.readerMode);
    if (!this.bitBookId) {
      // console.error('Missing bitBookId for request on page ', window.location.href);
      return;
    }
    const handInId = this.activatedRoute.snapshot.queryParams.handInId;
    if(handInId){
      queryParams.push({handInId});
    }
    const getBook$ = this.isExternalSearch
      ? of({title: ''} as BookEntity)
      : this.readerMode == ReaderModes.Book || this.isSelfLearningReader
        ? this.bitBookApiService.getBookById(this.bitBookId, queryParams)
        : this.readerMode == ReaderModes.LearningPaths
          ? this.bitBookApiService.getBookByIdAndCourseId(this.bitBookId, this.courseId, queryParams)
          : isNotebook
            ? this.bitBookApiService.getNotebookById(this.bitBookId, queryParams)
            : this.isShopReader && this.readerMode === ReaderModes.ShopPublic
              ? this.bitBookApiService.getPublicBookById(this.bitBookId, queryParams)
              : this.isShopReader
                ? this.bitBookApiService.getOpenBookById(this.bitBookId, queryParams)
                : this.bitBookApiService.getBookById(this.bitBookId, queryParams);

    const q = getBook$
      .pipe(catchError(error => {
          // Handle the error here
          console.error('LOADING ERROR: An error occurred while fetching the book:', error);
          alert('An error occurred while fetching the book');
          throw error;
        }),
        tap((bk: BookEntity) => {
          // if (bk?.course?.type === 'module') {
          //   this.isSelfLearningReader = true;
          // }
          if (bk?.course?.externalId && !this.themeId) {
            this.courseId = bk?.course?.externalId || this.courseId;
            this.themeId = bk?.theme === 'module-lp-test' || bk?.theme == 'product_content' ? bk?.theme : 'learning-path';
            this.location.replaceState(
              this.router.createUrlTree(
                [],
                {
                  queryParams: {course: null},
                  queryParamsHandling: 'merge',
                })
                .toString(),
            );
          }
          this.bitBook = bk;
          if (this.isExternalSearch) {
            return;
          }
          this.metaRead.emit({bookType: this.bitBook.type, notebookSubtype: this.bitBook.subtype});

          // todo: remove when all notebooks have externalId
          this.bitBook.externalId = this.bitBook.externalId || this.bitBookId;
          this.bitBook.theme = this.brandingRenderService.getBookThemeBranding({
            thisBook: Object.assign({}, this.bitBook, {theme: this.themeId || this.bitBook?.theme}),
          });
          this.bitBook.publisherId = this.themeId
            ? 0
            : (this.bitBook.meta?.publisherId || this.bitBook.publisher?.id || this.bitBook.publisherId);

          if (this.bitBook && !this.isExternalSearch && !this.isShopReader && !this.isSelfLearningReader) {
            forkJoin([
              this.readerLocalContentService.storeBookFilters(this.bitBook),
              this.readerTocService.storeTocForBook(this.bitBook.externalId, this.bitBook.toc),
              this.bitBookApiService.markBookAsVisited(this.bitBookId)
            ]).subscribe(() => {
            }, (err) => console.error(err));
          }
          setTimeout(() => {
            if (!this.isShopReader) {
              this.title.setTitle('Get More Brain - ' + this.bitBook?.title);
            }
          }, 500);
        }))
      .pipe(concatMap(() => this.brandingRenderService.applyBookBranding(this.bitBook)));

    q.subscribe((res: { themeClass: string }) => {
      this.brandingClass = res?.themeClass;
      this.bitbookMqService.notifyReaderBrandingSet(null);
      this.analyticsService.record('book-open', {
        bookId: this.bitBook.id,
        bookExternalId: this.bitBook.externalId,
        courseId: this.courseId,
        bookType: this.bitBook.type,
        lang: this.bitBook.lang,
        language: this.bitBook.mainLanguage,
        learningLanguage: this.bitBook.mainLearningLanguage
      });
    }, (err: HttpErrorResponse | any) => {
      if (this.readerMode === ReaderModes.Shop || this.readerMode === ReaderModes.ShopReadonly) {
        (this.brandingClass as any) = {};
      }
      console.error(err);
      this.bitbookMqService.notifyReaderBrandingSet(null);
      this.error = err;
      if (this.isShopReader) {
        return;
      }
      this.router.navigate(['space', this.bitmarkConfig.space, 'academy', 'book', this.bitBookId], {
        replaceUrl: true
      });
      if (err.status === 403) {
        alert('You do not have access to this book.');
      } else if (err?.status === 404) {
        alert('The book could not be found');
      } else {
        alert('An error occurred while loading the book.');
      }
    });
  }

  closeBook() {
    this.analyticsService.record('book-close', {
      bookId: this.bitBook.id,
      bookExternalId: this.bitBook.externalId,
      courseId: this.courseId,
      bookType: this.bitBook.type,
      lang: this.bitBook.lang,
      language: this.bitBook.mainLanguage,
      learningLanguage: this.bitBook.mainLearningLanguage
    });
    this.onClose.emit();
  }

  findBitInBook(bit: BitApiWrapper) {
    const originBitId = bit?.meta?.rootBitId || bit?.meta?.originBitId;
    if (bit?.meta?.originBook?.externalId && bit?.meta?.originBitId) {
      const url = `/space/${this.bitmarkConfig.space}/reader/${bit.meta.originBook.externalId}`;
      this.router.navigate([url], {
        fragment: `${originBitId}`,
        queryParams: {p: null},
        replaceUrl: false,
      });
    }
  }

  isContentAvailable(): boolean {
    return !!this.bitBook || this.isExternalSearch;
  }

  toggleSearch() {
    if (!this.isContentAvailable()) {
      return;
    }
    if (this.isExternalSearch) {
      return this.closeBook();
    }
    this.isTableOfContentsVisible =
      this.isSearchSidebarVisible = false;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.readerMode !== ReaderModes.Search
        ? {p: 'search', handInId: this.activatedRoute.snapshot.queryParams.handInId || null}
        : { handInId: this.activatedRoute.snapshot.queryParams.handInId || null },
      replaceUrl: true,
    });
  }

  toggleToc() {
    if (!this.isContentAvailable()) {
      return;
    }
    this.isTableOfContentsVisible = !this.isTableOfContentsVisible;
  }

  toggleSearchSidebar() {
    if (!this.isContentAvailable()) {
      return;
    }
    this.isSearchSidebarVisible = !this.isSearchSidebarVisible;
  }

  notebookQuickNoteOpen() {
    this.isNotebookQuickNoteOpen = true;
  }

  notebookQuickNoteClose() {
    this.isNotebookQuickNoteOpen = false;
  }

  sidebarMobileClose() {
    const isDesktop = this.deviceDetectorService.isDesktop();
    if (!isDesktop) {
      this.isTableOfContentsVisible =
        this.isSearchSidebarVisible = false;
    }
  }

  // todo: too much logic here: move it to a service and remove mq onReaderInvalidateBookCache <-> notifyReaderInvalidateBookCache and use (evt-binding)
  async invalidateBookCache(bookExternalId: string) {
    this.bitBook = await this.bitBookApiService.getBookById(this.bitBookId).toPromise();
    await this.readerLocalContentService.storeBookFilters(this.bitBook).toPromise();
    await this.readerTocService.storeTocForBook(this.bitBook.externalId, this.bitBook.toc).toPromise();
    this.readerTrackingService.getLastPositionForBook(bookExternalId)
      .subscribe((lastPos: BookPosition) => {
        if (lastPos && lastPos?.index > -1) {
          if (this.bitBook?.toc?.length > lastPos.index - 1) {
            this.readerTrackingService.storeLastPositionForBook(bookExternalId, {
              bitId: this.bitBook.toc[lastPos.index].ref,
              index: lastPos.index,
              distance: 0,
            }).subscribe();
            this.router.navigate([], {
              relativeTo: this.activatedRoute,
              fragment: `${this.bitBook.toc[lastPos.index].ref}|${lastPos.index}`,
              replaceUrl: true,
            });
          } else {
            this.readerTrackingService.clearLastPositionForBook(bookExternalId).subscribe();
          }
        } else {
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            fragment: null,
            replaceUrl: true,
          });
        }
        console.error('READER BOOK invalidateBookCache - Reloading page');
        setTimeout(() => window.location.reload());
      });
  }

  viewBasket() {
    this.isTableOfContentsVisible =
      this.isSearchSidebarVisible = false;
    const basketModalRef = this.ngbModal.open(ReaderBasketComponent, {
      windowClass: this.isTableOfContentsVisible || this.isSearchSidebarVisible ? 'reader-modal col-md-9' : 'reader-modal',
      backdrop: 'static',
      backdropClass: 'd-none',
      keyboard: true,
      animation: false,
    });
    this.modalTouchSwipeService.applyTouchSwipe(basketModalRef);
    const readerBasketComponentInstance = basketModalRef.componentInstance as ReaderBasketComponent;
    readerBasketComponentInstance.sendBits
      .subscribe((items: Array<BitApiWrapper>) => this.sendBits.emit(items));
    readerBasketComponentInstance.saveBits
      .subscribe((items: Array<BitApiWrapper>) => this.saveBits.emit(items));
  }

  protected readonly ReaderHiddenElements = ReaderHiddenElements;
}
