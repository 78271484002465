// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-section-folder-body {
  font-family: var(--bitmark-page-section-folder-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-page-section-folder-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-page-section-folder-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-page-section-folder-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-page-section-folder-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-page-section-folder-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-page-section-folder-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-page-section-folder-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-page-section-folder-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-page-section-folder-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
  width: var(--bitmark-page-section-folder-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-page-section-folder-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-page-section-folder-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-page-section-folder-size-margin, var(--bitmark-x-size-margin, 0));
}

.page-product-container {
  justify-content: var(--bitmark-page-section-folder-flex-align, flex-start) !important;
  align-items: flex-start !important;
}

.book-cover {
  height: 200px;
  width: 170px;
  width: var(--bitmark-page-section-folder-image-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-page-section-folder-image-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-page-section-folder-image-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-page-section-folder-image-size-margin, var(--bitmark-x-size-margin, 0));
}

.book-publisher {
  color: #656769;
  font-family: var(--bitmark-page-section-folder-publisher-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-page-section-folder-publisher-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-page-section-folder-publisher-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-page-section-folder-publisher-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-page-section-folder-publisher-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-page-section-folder-publisher-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-page-section-folder-publisher-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-page-section-folder-publisher-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-page-section-folder-publisher-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-page-section-folder-publisher-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
}

.book-title {
  word-wrap: break-word;
  color: black;
  font-family: var(--bitmark-page-section-folder-title-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-page-section-folder-title-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-page-section-folder-title-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-page-section-folder-title-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-page-section-folder-title-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-page-section-folder-title-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-page-section-folder-title-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-page-section-folder-title-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-page-section-folder-title-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-page-section-folder-title-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
}

.book-texts {
  padding-left: 2px;
  padding-right: 2px;
  width: var(--bitmark-page-section-folder-body-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-page-section-folder-body-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-page-section-folder-body-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-page-section-folder-body-size-margin, var(--bitmark-x-size-margin, 0));
  width: var(--bitmark-page-section-folder-body-size-width, var(--bitmark-x-size-width, 170px));
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.book-shadow {
  filter: drop-shadow(5px 0 0 white) drop-shadow(0 5px 0 white) drop-shadow(-5px 0 0 white) drop-shadow(0 -5px 0 white);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
