// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview-error {
  display: none;
}

.resource-website-wrapper {
  width: var(--bitmark-resource-website-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-resource-website-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-resource-website-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-resource-website-size-margin, var(--bitmark-x-size-margin, 0));
}
.resource-website-wrapper .resource-website-image {
  width: var(--bitmark-resource-website-image-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-resource-website-image-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-resource-website-image-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-resource-website-image-size-margin, var(--bitmark-x-size-margin, 0));
}
.resource-website-wrapper .resource-website-title {
  padding: var(--bitmark-resource-website-title-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-resource-website-title-size-margin, var(--bitmark-x-size-margin, 0));
  font-family: var(--bitmark-resource-website-title-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-resource-website-title-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-resource-website-title-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-resource-website-title-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-resource-website-title-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-resource-website-title-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-resource-website-title-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-resource-website-title-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-resource-website-title-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-resource-website-title-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
}
.resource-website-wrapper .resource-website-description {
  padding: var(--bitmark-resource-website-description-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-resource-website-description-size-margin, var(--bitmark-x-size-margin, 0));
  font-family: var(--bitmark-resource-website-description-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-resource-website-description-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-resource-website-description-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-resource-website-description-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-resource-website-description-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-resource-website-description-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-resource-website-description-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-resource-website-description-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-resource-website-description-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-resource-website-description-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
}
.resource-website-wrapper .resource-website-link {
  padding: var(--bitmark-resource-website-link-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-resource-website-link-size-margin, var(--bitmark-x-size-margin, 0));
  font-family: var(--bitmark-resource-website-link-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-resource-website-link-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-resource-website-link-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-resource-website-link-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-resource-website-link-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-resource-website-link-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-resource-website-link-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-resource-website-link-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-resource-website-link-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-resource-website-link-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
}
.resource-website-wrapper .resource-website-link:hover, .resource-website-wrapper .resource-website-link:active, .resource-website-wrapper .resource-website-link:visited {
  font-family: var(--bitmark-resource-website-link-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-resource-website-link-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-resource-website-link-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-resource-website-link-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-resource-website-link-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-resource-website-link-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-resource-website-link-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-resource-website-link-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-resource-website-link-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-resource-website-link-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
