import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {VirtualMarkBookAsReadBit} from "./virtual-mark-book-as-read.models";
import {BitbookApiService} from "../../reader/bitbook-api.service";
import {BitmarkConfig} from "../../bitmark.module";
import {ProductFamily} from "../../shared/models/bitmark.models";

@Component({
  selector: 'bitmark-virtual-mark-book-as-read',
  templateUrl: './virtual-mark-book-as-read.component.html',
  styleUrls: ['./virtual-mark-book-as-read.component.scss', '../bits.scss']
})
export class VirtualMarkBookAsReadComponent implements OnInit {
  @Input() markBookAsReadBit?: VirtualMarkBookAsReadBit;
  @Output() navigateToBook: EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily, queryParams?: any }> = new EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily, queryParams?: any }>();

  wiggleClass: string;
  rating: number;

  constructor(@Inject('BitmarkConfig') private bitmarkConfig: BitmarkConfig,
              private bitbookApiService: BitbookApiService,) {}

  ngOnInit() {
    console.log();
    this.rating = this.markBookAsReadBit?.rating || 0;
    this.bitbookApiService.getBookProgress(this.bitmarkConfig.space, this.markBookAsReadBit?.bookId).subscribe((progress) => {
      console.log('PROGRESS: ', progress);
      this.rating = progress?.rating / 10;
      this.markBookAsReadBit.rating = progress?.rating / 10;
      this.markBookAsReadBit.isBookRead = progress?.progress === 100 && progress?.rating > 0;
    }, (err) => {
      this.rating = 0;
      this.markBookAsReadBit.rating = 0;
      this.markBookAsReadBit.isBookRead = false;
    })
  }

  markBookAsRead() {
    if(!this.rating){
      this.toggleWiggle();
      return;
    }
    this.bitbookApiService.markBookAsRead('personal', this.markBookAsReadBit?.bookId, this.rating * 10).subscribe((res) => {
      console.log(res);
      this.markBookAsReadBit.isBookRead = true;
    }, (err) => {
      console.error(err);
    })
  }

  updateRating($event: any){
    if(isNaN($event) || this.markBookAsReadBit?.isBookRead){
      return;
    }
    console.log('Rating event: ', $event);
    this.rating = $event;
  }

  toggleWiggle() {
    this.wiggleClass = this.wiggleClass ? '' : 'wiggle-animation';
    setTimeout(() => this.wiggleClass = '', 600);
  }
}
