// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .bitmark-table-no-wrap {
  white-space: nowrap;
}
::ng-deep .bitmark-table-container {
  --bitmark-table-table-default-border-top-width: 1px;
  --bitmark-table-table-default-border-right-width: 1px;
  --bitmark-table-table-default-border-bottom-width: 1px;
  --bitmark-table-table-default-border-left-width: 1px;
  --bitmark-table-table-default-border-style: solid;
  --bitmark-table-table-default-border-color: #e5e7eb;
  --bitmark-table-table-default-border-radius: 8px;
}
::ng-deep .bitmark-table-container .gridjs-wrapper {
  border-top: var(--bitmark-table-table-border-top-width, var(--bitmark-table-table-default-border-top-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-right: var(--bitmark-table-table-border-right-width, var(--bitmark-table-table-default-border-right-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-bottom: var(--bitmark-table-table-border-bottom-width, var(--bitmark-table-table-default-border-bottom-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-left: var(--bitmark-table-table-border-left-width, var(--bitmark-table-table-default-border-left-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-radius: var(--bitmark-table-table-border-radius, var(--bitmark-table-table-default-border-radius, y));
}
::ng-deep .bitmark-table-container.bitmark-table-container-paginated .gridjs-wrapper {
  border-radius: var(--bitmark-table-table-border-radius, var(--bitmark-table-table-default-border-radius)) var(--bitmark-table-table-border-radius, var(--bitmark-table-table-default-border-radius)) 0 0;
}
::ng-deep .bitmark-table-container .bitmark-table .bitmark-thead {
  background-color: var(--bitmark-table-table-header-background-color, #f9fafb);
}
::ng-deep .bitmark-table-container .bitmark-table .bitmark-thead th {
  background-color: var(--bitmark-table-table-header-background-color, #f9fafb);
  font-family: var(--bitmark-table-table-header-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-table-table-header-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-table-table-header-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-table-table-header-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-table-table-header-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-table-table-header-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-table-table-header-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-table-table-header-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-table-table-header-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-table-table-header-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
  border-top: var(--bitmark-table-table-border-top-width, var(--bitmark-table-table-default-border-top-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-right: var(--bitmark-table-table-border-right-width, var(--bitmark-table-table-default-border-right-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-bottom: var(--bitmark-table-table-border-bottom-width, var(--bitmark-table-table-default-border-bottom-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-left: var(--bitmark-table-table-border-left-width, var(--bitmark-table-table-default-border-left-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-radius: var(--bitmark-table-table-border-radius, var(--bitmark-table-table-default-border-radius, y));
  border-radius: 0;
  border-top: none;
}
::ng-deep .bitmark-table-container .bitmark-table .bitmark-thead th:first-child {
  border-left: none;
  border-top-left-radius: var(--bitmark-table-table-border-radius, var(--bitmark-table-table-default-border-radius));
}
::ng-deep .bitmark-table-container .bitmark-table .bitmark-thead th:last-child {
  border-right: none;
  border-top-right-radius: var(--bitmark-table-table-border-radius, var(--bitmark-table-table-default-border-radius));
}
::ng-deep .bitmark-table-container .bitmark-table .bitmark-thead-hidden {
  display: none;
}
::ng-deep .bitmark-table-container .bitmark-table .bitmark-tbody tr td {
  font-family: var(--bitmark-table-table-row-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-table-table-row-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-table-table-row-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-table-table-row-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-table-table-row-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-table-table-row-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-table-table-row-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-table-table-row-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-table-table-row-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-table-table-row-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
  border-top: var(--bitmark-table-table-border-top-width, var(--bitmark-table-table-default-border-top-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-right: var(--bitmark-table-table-border-right-width, var(--bitmark-table-table-default-border-right-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-bottom: var(--bitmark-table-table-border-bottom-width, var(--bitmark-table-table-default-border-bottom-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-left: var(--bitmark-table-table-border-left-width, var(--bitmark-table-table-default-border-left-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-radius: var(--bitmark-table-table-border-radius, var(--bitmark-table-table-default-border-radius, y));
  border-radius: 0;
}
::ng-deep .bitmark-table-container .bitmark-table .bitmark-tbody td:first-child {
  border-left: none;
}
::ng-deep .bitmark-table-container .bitmark-table .bitmark-tbody td:last-child {
  border-right: none;
}
::ng-deep .bitmark-table-container .bitmark-table .bitmark-tbody tr:last-child td {
  border-bottom: 0;
}
::ng-deep .bitmark-table-container .bitmark-table .bitmark-tbody tr:nth-child(odd) td {
  background-color: var(--bitmark-table-table-row-odd-background-color);
}
::ng-deep .bitmark-table-container .bitmark-table .bitmark-tbody tr:nth-child(even) td {
  background-color: var(--bitmark-table-table-row-even-background-color);
}
::ng-deep .bitmark-table-container .bitmark-tfooter {
  background-color: var(--bitmark-table-table-footer-background-color);
  border-top: var(--bitmark-table-table-border-top-width, var(--bitmark-table-table-default-border-top-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-right: var(--bitmark-table-table-border-right-width, var(--bitmark-table-table-default-border-right-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-bottom: var(--bitmark-table-table-border-bottom-width, var(--bitmark-table-table-default-border-bottom-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-left: var(--bitmark-table-table-border-left-width, var(--bitmark-table-table-default-border-left-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-radius: var(--bitmark-table-table-border-radius, var(--bitmark-table-table-default-border-radius, y));
  border-radius: 0 0 var(--bitmark-table-table-border-radius, var(--bitmark-table-table-default-border-radius)) var(--bitmark-table-table-border-radius, var(--bitmark-table-table-default-border-radius));
}
::ng-deep .bitmark-table-container .bitmark-tfooter .bitmark-tfooter-pagination {
  font-family: var(--bitmark-table-table-footer-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-table-table-footer-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-table-table-footer-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-table-table-footer-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-table-table-footer-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-table-table-footer-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-table-table-footer-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-table-table-footer-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-table-table-footer-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-table-table-footer-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
}
::ng-deep .bitmark-table-container .bitmark-tfooter .bitmark-tfooter-pagination-button {
  background-color: var(--bitmark-table-table-footer-pagination-background-color) !important;
  font-family: var(--bitmark-table-table-footer-pagination-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family))) !important;
  font-size: var(--bitmark-table-table-footer-pagination-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size))) !important;
  font-weight: var(--bitmark-table-table-footer-pagination-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight))) !important;
  font-style: var(--bitmark-table-table-footer-pagination-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style))) !important;
  -webkit-text-decoration: var(--bitmark-table-table-footer-pagination-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration))) !important;
          text-decoration: var(--bitmark-table-table-footer-pagination-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration))) !important;
  line-height: var(--bitmark-table-table-footer-pagination-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height))) !important;
  color: var(--bitmark-table-table-footer-pagination-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color))) !important;
  text-align: var(--bitmark-table-table-footer-pagination-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))) !important;
  justify-content: var(--bitmark-table-table-footer-pagination-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))) !important;
  border-top: var(--bitmark-table-table-border-top-width, var(--bitmark-table-table-default-border-top-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-right: var(--bitmark-table-table-border-right-width, var(--bitmark-table-table-default-border-right-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-bottom: var(--bitmark-table-table-border-bottom-width, var(--bitmark-table-table-default-border-bottom-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-left: var(--bitmark-table-table-border-left-width, var(--bitmark-table-table-default-border-left-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-radius: var(--bitmark-table-table-border-radius, var(--bitmark-table-table-default-border-radius, y));
}
::ng-deep .bitmark-table-container .bitmark-tfooter .bitmark-tfooter-pagination-button:not(:last-child):not(:first-child) {
  border-radius: 0;
}
::ng-deep .bitmark-table-container .bitmark-tfooter .bitmark-tfooter-pagination-button:first-child {
  border-top: var(--bitmark-table-table-border-top-width, var(--bitmark-table-table-default-border-top-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-right: var(--bitmark-table-table-border-right-width, var(--bitmark-table-table-default-border-right-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-bottom: var(--bitmark-table-table-border-bottom-width, var(--bitmark-table-table-default-border-bottom-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-left: var(--bitmark-table-table-border-left-width, var(--bitmark-table-table-default-border-left-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-radius: var(--bitmark-table-table-border-radius, var(--bitmark-table-table-default-border-radius, y));
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
::ng-deep .bitmark-table-container .bitmark-tfooter .bitmark-tfooter-pagination-button:last-child {
  border-top: var(--bitmark-table-table-border-top-width, var(--bitmark-table-table-default-border-top-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-right: var(--bitmark-table-table-border-right-width, var(--bitmark-table-table-default-border-right-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-bottom: var(--bitmark-table-table-border-bottom-width, var(--bitmark-table-table-default-border-bottom-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-left: var(--bitmark-table-table-border-left-width, var(--bitmark-table-table-default-border-left-width)) var(--bitmark-table-table-border-style, var(--bitmark-table-table-default-border-style)) var(--bitmark-table-table-border-color, var(--bitmark-table-table-default-border-color));
  border-radius: var(--bitmark-table-table-border-radius, var(--bitmark-table-table-default-border-radius, y));
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
::ng-deep .bitmark-table-container .bitmark-tfooter .bitmark-tfooter-pagination-button:not(:last-child) {
  border-right: none;
}
::ng-deep .bitmark-table-container .bitmark-tfooter .gridjs-pagination .gridjs-pages button.gridjs-currentPage {
  font-weight: 700 !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
