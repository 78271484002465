import {BitType} from '../../bits/bits.models';
import {DefaultBitBranding as defDefaultBitBranding} from './branding-defaults';

export enum ProductFamily {
  Paperbook = 'PAPERBOOK',
  Notebook = 'NOTEBOOK',
  PDFBook = 'PDF',
  Collection = 'COLLECTION',
  Book = 'BOOK',
  Folder = 'folder',
  Training = 'training',
  XCourse = 'xcourse',
  XModule = 'xmodule',
  TrainingSession = 'training_session',
  Template = 'template',
  Course = 'course',
  CourseSession = 'course_session',
  Bot = 'BOT',
  AcademySection = 'academy_section',
  AcademyProductPage = 'academy_product_page'
}

export enum BookType {
  Paperbook = 'paperbook',
  Notebook = 'notebook',
  PDFBook = 'pdf',
  Collection = 'collection',
  Book = 'book',
  LearningPath = 'learning-path'
}

export enum NotebookTypes { // todo: rename to BookSubtype
  Worksheet = 'worksheet',
  Homework = 'homework',
  Flashcard = 'flashcard',
  AI = 'ai',
  LearningPath = 'learning-path',
  DraftBook = 'draft-book',
  PDF = 'pdf',
  Bot = 'bot',
  BotNoChat = 'bot-no-chat',
  OpenContent = 'open-content'
}

export enum NotebookCoverType {
  Standard = 'standard',
  None = 'none',
  FullCover = 'full-cover',
  CustomCover = 'custom-cover'
}

export enum BitmarkBookTypes {
  Book = 'BOOK',
  Pdf = 'PDF',
  Flashcards = 'FLASHCARDS',
  Worksheet = 'WORKSHEET',
  Quiz = 'QUIZZES'
}

export enum BitmarkFormat {
  Text = 'text',
  MM = 'bitmark--',
  PP = 'bitmark++',
  Prosemirror = 'prosemirror',
  JSON = 'json'
}

export enum BitmarkBitFeedback {
  Standard = 'standard',
  TwoStep = '2-step',
  TwoStepAi = '2-step-ai'
}

export enum BitmarkBitFeedbackStepsTwoSteps {
  Redo = 0,
  InterimFeedback = 1,
  FinalFeedback = 2
}

export enum BrandingDefaultThemes {
  SystemDefault = 'system-default',
  LearningPath = 'learning-path',
  NotebookLearningPath = 'notebook-learning-path',
  DraftBook = 'draft-book'
}

export interface BitBrandingFont {
  family?: string;
  size?: string;
  weight?: string;
  style?: string;
  align?: string;
  lineHeight?: string;
  color?: string;
  textDecoration?: string;
}

export interface BitBrandingBorder {
  topWidth?: string;
  rightWidth?: string;
  bottomWidth?: string;
  leftWidth?: string;
  color?: string;
  style?: string;
  radius?: string;
}

export interface BitBrandingSize {
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
}

export interface BitBrandingSizeBit {
  width?: string;
  height?: string;
  aspectRatio?: string;
  padding?: string;
  margin?: string;
}

export interface BitBrandingBackground {
  color?: string;
  image?: string;
  size?: string;
  position?: string;
  repeat?: string;
  transform?: string;
  animationName?: string;
  animationTime?: string;
  backdropFilter?: string;
}

export interface BitBrandingWallpaperBackground {
  color?: string;
  image?: string;
  width?: string;
  height?: string;
  split?: string;
  position?: string;
  repeat?: string;
  backdropFilter?: string;
  attachedTo?: string;
}

export interface BitBrandingContent {
  beforeString?: string;
  beforeFont?: BitBrandingFont;
  beforeMargin?: string;
  afterString?: string;
  afterFont?: BitBrandingFont;
  afterMargin?: string;
}

export interface BitBrandingSingleContent {
  string?: string;
  font?: BitBrandingFont;
  margin?: string;
}

export interface BitBrandingIcon {
  image?: string;
  width?: string;
  height?: string;
  margin?: string;
}

export interface BitBrandingDuration {
  font?: BitBrandingFont;
  backgroundColor?: string;
}

export interface BitBrandingIconAbsolute extends BitBrandingIcon {
  location?: string;
}

export interface BitBrandingItem {
  font?: BitBrandingFont;
  border?: BitBrandingBorder;
  size?: BitBrandingSize;
  background?: BitBrandingBackground;
  icon?: BitBrandingIcon;
  position?: string;
}

export interface BitBrandingLead {
  font?: BitBrandingFont;
  border?: BitBrandingBorder;
  size?: BitBrandingSize;
  content?: BitBrandingContent;
  permContent?: BitBrandingContent;
}

export interface BitBrandingInstruction {
  font?: BitBrandingFont;
  border?: BitBrandingBorder;
  size?: BitBrandingSize;
  position?: string;
  content?: BitBrandingContent;
}

export interface BitBrandingTechnicalTerm {
  font?: BitBrandingFont;
  border?: BitBrandingBorder;
  size?: BitBrandingSize;
  content?: BitBrandingContent;
  position?: string;
}

export interface BitBrandingFooter {
  size?: BitBrandingSize;
  font?: BitBrandingFont;
  border?: BitBrandingBorder;
}

export interface BitBrandingChapter {
  font?: BitBrandingFont;
  size?: BitBrandingSize;
  border?: BitBrandingBorder;
  background?: BitBrandingBackground;
  wallpaperBackground?: BitBrandingWallpaperBackground;
  flexDirection?: string;
  flexAlign?: string;
  flexJustify?: string;
  item?: BitBrandingItem;
  itemContent?: BitBrandingContent;
  instruction?: BitBrandingInstruction;
  body?: BitBrandingInstruction;
  icon?: BitBrandingIconAbsolute;
}

export interface BitBrandingButton {
  size: BitBrandingSize;
  border: BitBrandingBorder;
  background: string;
  backgroundShorthand: string;
  font: BitBrandingFont;
}

export interface BitBrandingIconButton extends BitBrandingButton {
  color: string;
  iconSize: BitBrandingSize;
}

export interface BitBrandingScrollbar {
  thick: string;
  border: BitBrandingBorder;
  thumbBackgroundColor: string;
  trackBackgroundColor: string;
}

export interface BitBrandingStrike {
  color: string;
  thickness: string;
}

export interface BitBrandingHighlight {
  color: string;
  backgroundColor: string;
}

export interface BitBrandingCircle {
  border: BitBrandingBorder;
  size: BitBrandingSize;
}

export interface BitBrandingInsDel {
  font: BitBrandingFont;
  strike: BitBrandingStrike;
  backgroundColor: string;
  size: BitBrandingSize;
  content: BitBrandingContent;
}

export interface BitBrandingLanguageEm {
  font: BitBrandingFont;
  strike: BitBrandingStrike;
}

export interface BitBrandingLearningPath {
  showInEditor?: boolean;
  background?: BitBrandingBackground;
  headerBackground?: BitBrandingBackground;
  size?: BitBrandingSize;
  border?: BitBrandingBorder;
  headerFont?: BitBrandingFont;
  calendarBackground?: BitBrandingBackground;
  calendarFont?: BitBrandingFont;
  buttonBackgroundColor?: string;
}

export interface BitBrandingShowInEditor {
  showInEditor?: boolean;
}

export interface BitBrandingReleaseNote extends BitBrandingBit {
  dateFont: BitBrandingFont;
  separatorBackground: BitBrandingBackground;
  kindFont: BitBrandingFont;
  kindBackground: BitBrandingBackground;
  kindBorder: BitBrandingBorder;
  kindSize: BitBrandingSize;
  versionFont: BitBrandingFont;
  versionSize: BitBrandingSize;
}

export interface BitBrandingList {
  size: BitBrandingSize;
  itemPosition: string;
  itemType: string;
  itemContent: BitBrandingContent;
  itemSize: BitBrandingSize;
  itemDisplay: string;
}

export interface BitBrandingTitle {
  font: BitBrandingFont;
  size: BitBrandingSize;
}

export interface BitBrandingSubItem {
  font: BitBrandingFont;
  size: BitBrandingSize;
}

export interface BitBrandingBit {
  background?: BitBrandingBackground;
  externalBackground?: BitBrandingBackground;
  wallpaperBackground?: BitBrandingWallpaperBackground;
  size?: BitBrandingSize;
  font?: BitBrandingFont;
  border?: BitBrandingBorder;
  item?: BitBrandingItem;
  lead?: BitBrandingLead;
  instruction?: BitBrandingInstruction;
  technicalTerm?: BitBrandingTechnicalTerm;
  bodySize?: BitBrandingSize;
  icon?: BitBrandingIconAbsolute;
  img?: BitBrandingImage;
  bitmarkTitle?: BitBrandingTitle;
  bitmarkSubtitle?: BitBrandingTitle;
  subItemFont?: BitBrandingFont;
  subItemSize?: BitBrandingSize;
}

export interface BitGeneralBrandingBit extends BitBrandingBit {
  footer?: BitBrandingFooter;
}

export interface BitBrandingFootnotes {
  background?: BitBrandingBackground;
  size?: BitBrandingSize;
  font?: BitBrandingFont;
  border?: BitBrandingBorder;
}

export interface BitBrandingQuizBit extends BitGeneralBrandingBit {
  quizItemContent?: BitBrandingContent;
}

export interface BitQuizBitFeedbackBrandingBit {
  avatarBackground?: BitBrandingBackground;
  avatarSize?: BitBrandingSize;
  avatarString?: string;
  avatarFont?: BitBrandingFont;
  messageBubbleBackground?: BitBrandingBackground;
  messageBubbleSize?: BitBrandingSize;
  messageBubbleFont?: BitBrandingFont;
}

export interface BitBrandingTakePictureBit extends BitBrandingBitWordBank {
  buttonBackgroundColor: string;
  buttonBackgroundShorthand: string;
  buttonBorder: BitBrandingBorder;
}

export interface BitBrandingRecordAudioBit extends BitBrandingBitWordBank {
  audioRecorder?: BitBrandingAudio;
}

export interface BitBrandingRecordVideoBit extends BitBrandingBitWordBank {
  videoRecorder?: BitBrandingVideo;
}

export interface BitBrandingQuoteBit extends BitBrandingBit {
  signColor: string;
}

export interface BitBrandingSurveyRatingBit extends BitBrandingBit {
  ratingAlign?: string;
  ratingDirection?: string;
  ratingLabelDirection?: string;
  ratingItemsGap?: string;
  ratingItemBackground?: BitBrandingBackground;
  ratingItemSize?: BitBrandingSize;
  ratingItemBorder?: BitBrandingBorder;
  ratingItemFont?: BitBrandingFont;
  ratingItemSelectedBackground?: BitBrandingBackground;
  ratingItemSelectedBorder?: BitBrandingBorder;
  ratingItemSelectedFont?: BitBrandingFont;
  ratingLabelSize?: BitBrandingSize;
  ratingLabelFont?: BitBrandingFont;
}

export interface BitBrandingSurveyRatingOnceBit extends BitBrandingSurveyRatingBit {
  ratingTimestampFont?: BitBrandingFont;
}

export interface BitBrandingSurveyMatrixBit extends BitBrandingStepImagePointerBit {
  pointerSetDateFont?: BitBrandingFont;
}

export interface BitBrandingDetailsBit extends BitBrandingBit {
  itemLeadContent?: BitBrandingContent;
  rightArrow?: BitBrandingSingleContent;
}

export interface BitBrandingDetailsImageBit extends BitBrandingBit {
  imgContainerSize?: BitBrandingSize;
  summarySize?: BitBrandingSize;
  summaryBackground?: BitBrandingBackground;
  contentSize?: BitBrandingSize;
  contentBackground?: BitBrandingBackground;
  toggleBtnBackground?: BitBrandingBackground;
  toggleBtnIconColor?: string;
}

export interface BitBrandingImagesLogoGraveBit extends BitBrandingBit {
  logoGraveImageSize?: BitBrandingSize;
}

export interface BitBrandingVideoBit extends BitBrandingBit {
  videoBorder?: BitBrandingBorder;
  coverTransform?: string;
}

export interface BitBrandingBookSourcesBit extends BitBrandingBit {
  sourceBorder?: BitBrandingBorder;
  sourceTitleFont?: BitBrandingFont;
  sourceContentFont?: BitBrandingFont;
  lineClampContent?: string;
  button?: BitBrandingButton;
}

export interface BitBrandingClozeGroupedBit extends BitBrandingBit {
  countContent?: BitBrandingContent;
  strike?: BitBrandingStrike;
}

export interface BitBrandingGeneralBitWordBank extends BitGeneralBrandingBit {
  strike?: BitBrandingStrike;
}

export interface BitBrandingBitWordBank extends BitGeneralBrandingBit {
  strike?: BitBrandingStrike;
}

export interface BitBrandingImage {
  background?: BitBrandingBackground;
  size?: BitBrandingSize;
  border?: BitBrandingBorder;
  flexAlign?: string;
  captionFont?: BitBrandingFont;
  captionSize?: BitBrandingSize;
}

export interface BitBrandingAudio {
  size?: BitBrandingSize;
  captionFont?: BitBrandingFont;
  captionSize?: BitBrandingSize;
  recordingSize?: BitBrandingSize;
  recordingBorder?: BitBrandingBorder;
  recordingBackground?: BitBrandingBackground;
  recordingIconButton?: BitBrandingIconButton;
  recordingAudioWaveColor?: string;
  recordingTimeFont?: BitBrandingFont;
  playerBackground?: BitBrandingBackground;
}

export interface BitBrandingVideo {
  size?: BitBrandingSize;
  captionFont?: BitBrandingFont;
  captionSize?: BitBrandingSize;
  playButtonColor?: string;
  playButtonIconColor?: string;
  playButtonBorder?: BitBrandingBorder;
  recordingSize?: BitBrandingSize;
  recordingBorder?: BitBrandingBorder;
  recordingBackground?: BitBrandingBackground;
  recordingIconButton?: BitBrandingIconButton;
  recordingAudioWaveColor?: string;
  recordingTimeFont?: BitBrandingFont;
}

export interface BitBrandingCheckBoxContent {
  string: string;
  font?: BitBrandingFont;
}

export interface BitBrandingDocument {
  size?: BitBrandingSize;
  iconSize?: BitBrandingSize;
  nameFont?: BitBrandingFont;
  nameSize?: BitBrandingSize;
}

export interface BitBrandingWebsite {
  size?: BitBrandingSize;
  imageSize?: BitBrandingSize;
  titleFont?: BitBrandingFont;
  titleSize?: BitBrandingSize;
  descriptionFont?: BitBrandingFont;
  descriptionSize?: BitBrandingSize;
  linkFont?: BitBrandingFont;
  linkSize?: BitBrandingSize;
}

export interface BitBrandingImageSuperWide extends BitBrandingBit {
  scrollbar?: BitBrandingScrollbar;
}

export interface BitBrandingStepImagePointerBit extends BitBrandingBit {
  pointerDiameter?: string;
  pointerBackground?: BitBrandingBackground;
  pointerBorder?: BitBrandingBorder;
}

export interface BitBrandingConversation {
  background?: BitBrandingBackground;
  externalBackground?: BitBrandingBackground;
  size?: BitBrandingSize;
  font?: BitBrandingFont;
  border?: BitBrandingBorder;
  bodySize?: BitBrandingSize;
  item?: BitBrandingItem;
  lead?: BitBrandingLead;
  partner?: BitBrandingInstruction;
  bubbleBorder?: BitBrandingBorder;
  bubbleContentSize?: BitBrandingSize;
  bubbleBackground?: BitBrandingBackground;
}

export interface BitBrandingAppFlashcardsBit extends BitBrandingBit {
  cardFrontBackground: BitBrandingBackground;
  cardFrontFont: BitBrandingFont;
  cardFrontEmojiDisplay: string;
  cardBackBackground: BitBrandingBackground;
  cardBackFont: BitBrandingFont;
  cardIcon: BitBrandingIconAbsolute;
  navigationButtonsBackground: BitBrandingBackground;
  navigationButtonsFontColor: string;
  labelColor: string;
  gotItRightEmojiString: string;
  gotItWrongEmojiString: string;
  cardSize: BitBrandingSize;
  cardBackSize: BitBrandingSize;
  podColor: string;
  podFont: BitBrandingFont;
  selectedPodColor: string;
  selectedPodFont: BitBrandingFont;
  toggleButtonColor: string;
  toggleButtonSwitchColor: string;
  keyboardButtonColor: string;
  keyboardActiveButtonColor: string;
}

export interface BitBrandingFlashcard1Bit extends BitBrandingBit {
  cardSize: BitBrandingSize;
  cardBackSize: BitBrandingSize;
  cardBorder: BitBrandingBorder;
  cardShadow: string;
  cardFrontBackground: BitBrandingBackground;
  cardFrontFont: BitBrandingFont;
  cardFrontIcon: BitBrandingIconAbsolute;
  cardBackBackground: BitBrandingBackground;
  cardBackFont: BitBrandingFont;
  cardBackIcon: BitBrandingIconAbsolute;
  footer: BitBrandingFooter;
}

export interface BitBrandingTableBit extends BitBrandingBit {
  tableBorder?: BitBrandingBorder;
  tableHeaderBackgroundColor?: string;
  tableHeaderFont?: BitBrandingFont;
  tableRowOddBackgroundColor?: string;
  tableRowEvenBackgroundColor?: string;
  tableRowFont?: BitBrandingFont;
  tableFooterFont?: BitBrandingFont;
  tableFooterBackgroundColor?: string;
  tableFooterPaginationFont?: BitBrandingFont;
  tableFooterPaginationBackgroundColor?: string;
}

export interface BitBrandingTableImageBit extends BitBrandingBit {
  captionFont?: BitBrandingFont;
  captionSize?: BitBrandingSize;
}

export interface BitBrandingPageBuyButtonBit extends BitGeneralBrandingBit {
  button?: BitBrandingButton;
  boldFont?: BitBrandingFont;
  flexAlign?: string;
}

export interface BitBrandingVirtualMarkBookAsReadBit extends BitBrandingBit {
  button?: BitBrandingButton;
  emptyStar?: BitBrandingButton;
  filledStar?: BitBrandingButton;
  flexAlign?: string;
}

export interface BitBrandingVirtualHandInBit extends BitBrandingBit {
  button?: BitBrandingButton;
  flexAlign?: string;
  personAvatar?: BitBrandingIcon;
  personName?: BitBrandingFont;
  personContainer?: BitBrandingButton;
}

export interface BitBrandingModuleBit extends BitBrandingBit {
  button?: BitBrandingButton;
  boldFont?: BitBrandingFont;
  flexAlign?: string;
  duration?: BitBrandingDuration;
  headerBackground?: BitBrandingBackground;
}

export interface BitBrandingPageFooterBit extends BitBrandingBit {
  sectionsFlexJustify?: string;
  sectionSize?: BitBrandingSize;
  sectionBorder?: BitBrandingBorder;
  sectionInstruction?: BitBrandingInstruction;
}

export interface BitBrandingPageProductBit extends BitBrandingBit {
  font?: BitBrandingFont;
  size?: BitBrandingSize;
  image?: BitBrandingImage;
  publisherFont?: BitBrandingFont;
  titleFont?: BitBrandingFont;
  button?: BitBrandingButton;
  flexAlign?: string;
}

export interface BitBrandingCookIngredientsBit extends BitBrandingBit {
  technicalTermFont?: BitBrandingFont;
  technicalTermContent?: BitBrandingContent;
  technicalTermSize?: BitBrandingSize;
  technicalTermPosition?: string;
  servingsInputSize?: BitBrandingSize;
  servingsInputBackground?: BitBrandingBackground;
  servingsInputBorder?: BitBrandingBorder;
  servingsInputFont?: BitBrandingFont;
  servingsFlexJustify?: string;
  servingsHintFont?: BitBrandingFont;
  servingsHintBorder?: BitBrandingBorder;
  servingsHintAlign?: string;
  servingsHintSize?: BitBrandingSize;
  ingredientsTableTemplateColumns?: string;
  ingredientsTableCheckboxOrder?: string;
  ingredientsTableCheckboxContainerSize?: BitBrandingSize;
  ingredientsTableCheckboxSize?: BitBrandingSize;
  ingredientsTableCheckboxBackground?: BitBrandingBackground;
  ingredientsTableCheckboxBorder?: BitBrandingBorder;
  ingredientsTableCheckboxCheckedBackground?: BitBrandingBackground;
  ingredientsTableCheckboxCheckedBorder?: BitBrandingBorder;
  ingredientsTableCheckboxCheckedContent?: BitBrandingCheckBoxContent;
  ingredientsTableUnitContainerSize?: BitBrandingSize;
  ingredientsTableUnitOrder?: string;
  ingredientsTableUnitFont?: BitBrandingFont;
  ingredientsTableQuantityContainerSize?: BitBrandingSize;
  ingredientsTableQuantityOrder?: string;
  ingredientsTableQuantityFont?: BitBrandingFont;
  ingredientsTableContentContainerSize?: BitBrandingSize;
  ingredientsTableContentOrder?: string;
  ingredientsTableContentFont?: BitBrandingFont;
  ingredientsTableContentTitle?: BitBrandingTitle;
  ingredientsTableRowOddBackgroundColor?: string;
  ingredientsTableRowEvenBackgroundColor?: string;
  footer?: BitBrandingFooter;
}

export interface BitBrandingArticleAltBit extends BitBrandingBit {
  headerSize?: BitBrandingSize;
  headerBackground?: BitBrandingBackground;
  bodyBackground?: BitBrandingBackground;
  footer?: BitBrandingFooter;
  footerBackground?: BitBrandingBackground;
}

export interface BitBranding {
  readerPrimaryColor: string;
  readerSecondaryColor: string;
  readerBackground: BitBrandingBackground;
  readerContentBackground: BitBrandingBackground;
  readerTitleBarShow: string;
  readerCoverBlockAlign: string;
  readerCoverBlockSize: BitBrandingSize;
  readerCoverShow: string;
  readerCoverAlign: string;
  readerTitleShow: string;
  readerContentBorder: BitBrandingBorder;
  readerContentWidth: string;
  readerGmbLogoImage: string;
  readerProgressBarDisplay: string;
  readerEndGlyphColor: string;
  readerEndGlyphDisplay: string;
  readerSearchResultsTitleFont: BitBrandingFont;

  titleFont: BitBrandingFont;
  titleSize?: BitBrandingSize;
  subtitleFont: BitBrandingFont;
  subtitleSize?: BitBrandingSize;
  mainFont: BitBrandingFont;
  bitmarkImage: BitBrandingImage;

  labelFont: BitBrandingFont;
  buttonFont: BitBrandingFont;
  buttonBackground: BitBrandingBackground;
  buttonBackgroundShorthand: string;
  buttonBorder: BitBrandingBorder;
  inputInlineSize: BitBrandingSize;
  inputShortSize: BitBrandingSize;
  inputLongSize: BitBrandingSize;
  inputBackground: BitBrandingBackground;
  inputFont: BitBrandingFont;
  inputBorder: BitBrandingBorder;
  inputCorrectBackground: BitBrandingBackground;
  inputCorrectFont: BitBrandingFont;
  inputCorrectBorder: BitBrandingBorder;
  inputCorrectContent: BitBrandingContent;
  inputIncorrectBackground: BitBrandingBackground;
  inputIncorrectFont: BitBrandingFont;
  inputIncorrectBorder: BitBrandingBorder;
  inputIncorrectContent: BitBrandingContent;
  inputAnsweredBackground: BitBrandingBackground;
  inputAnsweredFont: BitBrandingFont;
  inputAnsweredBorder: BitBrandingBorder;
  inputAnsweredContent: BitBrandingContent;
  radioSize: BitBrandingSize;
  radioBackground: BitBrandingBackground;
  radioBorder: BitBrandingBorder;
  radioCheckedBackground: BitBrandingBackground;
  radioCheckedBorder: BitBrandingBorder;
  radioCheckedContent: BitBrandingCheckBoxContent;
  checkboxSize: BitBrandingSize;
  checkboxBackground: BitBrandingBackground;
  checkboxBorder: BitBrandingBorder;
  checkboxCheckedBackground: BitBrandingBackground;
  checkboxCheckedBorder: BitBrandingBorder;
  checkboxCheckedContent: BitBrandingCheckBoxContent;
  dropdownBackground: BitBrandingBackground;
  dropdownBorder: BitBrandingBorder;
  dropdownColor: string;
  solutionBackground: BitBrandingBackground;
  solutionBorder: BitBrandingBorder;
  linkFont: BitBrandingFont;
  linkBackground: BitBrandingBackground;
  linkBorder: BitBrandingBorder;
  linkSize: BitBrandingSize;
  linkContent: BitBrandingContent;
  boldFont: BitBrandingFont;
  italicFont: BitBrandingFont;
  bulletList: BitBrandingList;
  orderedList: BitBrandingList;
  taskList: BitBrandingList;
  taskListCheckboxSize: BitBrandingSize;
  taskListCheckboxBackground: BitBrandingBackground;
  taskListCheckboxBorder: BitBrandingBorder;
  taskListCheckboxCheckedBackground: BitBrandingBackground;
  taskListCheckboxCheckedBorder: BitBrandingBorder;
  taskListCheckboxCheckedColor: string;
  taskListCheckboxCheckedContentString: string;
  textLightFont: BitBrandingFont;
  textHighlight: BitBrandingHighlight;
  textUserHighlight: BitBrandingHighlight;
  textStrike: BitBrandingStrike;
  textUserStrike: BitBrandingStrike;
  textUnderline: BitBrandingStrike;
  textUserUnderline: BitBrandingStrike;
  textDoubleUnderline: BitBrandingStrike;
  textUserDoubleUnderline: BitBrandingStrike;
  textSuperscriptFont: BitBrandingFont;
  textSubscriptFont: BitBrandingFont;
  textIns: BitBrandingInsDel;
  textDel: BitBrandingInsDel;
  textCircle: BitBrandingCircle;
  textUserCircle: BitBrandingCircle;
  textLanguageEm: BitBrandingLanguageEm;

  bit: BitGeneralBrandingBit;
  subItemPosition: string;
  footnotes: BitBrandingFootnotes;
  quizBitsFeedback: BitQuizBitFeedbackBrandingBit;

  chapterL1: BitBrandingChapter;
  chapterL2: BitBrandingChapter;
  chapterL3: BitBrandingChapter;
  chapterL4: BitBrandingChapter;
  chapterL5: BitBrandingChapter;
  chapterL6: BitBrandingChapter;
  chapterL7: BitBrandingChapter;

  learningPath: BitBrandingLearningPath;
  learningPathBook: BitBrandingShowInEditor;
  learningPathClassroomEvent: BitBrandingShowInEditor;
  learningPathClosing: BitBrandingShowInEditor;
  learningPathExternalLink: BitBrandingShowInEditor;
  learningPathLearningGoal: BitBrandingShowInEditor;
  learningPathLti: BitBrandingShowInEditor;
  learningPathStep: BitBrandingShowInEditor;
  learningPathVideoCall: BitBrandingShowInEditor;
  releaseNote: BitBrandingReleaseNote;
  botActionResponse: BitBrandingBit;
  details1: BitBrandingDetailsBit;
  sampleSolution: BitBrandingDetailsBit;
  detailsImage: BitBrandingDetailsImageBit;
  prototypeImages: BitBrandingBit;
  quote: BitBrandingQuoteBit;
  bookEpigraph: BitBrandingQuoteBit;
  statement: BitBrandingBit;
  internalLink: BitBrandingBit;
  assignment: BitBrandingBitWordBank;
  correction: BitBrandingBit;

  surveyRating: BitBrandingSurveyRatingBit;
  surveyRatingDisplay: BitBrandingSurveyRatingBit;
  surveyRatingOnce: BitBrandingSurveyRatingOnceBit;
  surveyMatrix: BitBrandingSurveyMatrixBit;
  surveyMatrixMe: BitBrandingSurveyMatrixBit;

  article: BitBrandingBit;
  note: BitBrandingBit;
  sideNote: BitBrandingBit;
  editorNote: BitBrandingBit;
  example: BitBrandingBit;
  info: BitBrandingBit;
  remark: BitBrandingBit;
  hint: BitBrandingBit;
  help: BitBrandingBit;
  warning: BitBrandingBit;
  danger: BitBrandingBit;
  bug: BitBrandingBit;
  stickyNote: BitBrandingBit;
  editorial: BitBrandingBit;
  bookFrontispiece: BitBrandingBit;
  bookTitle: BitBrandingBit;
  bookCopyright: BitBrandingBit;
  bookDedication: BitBrandingBit;
  bookForeword: BitBrandingBit;
  bookPreface: BitBrandingBit;
  bookPrologue: BitBrandingBit;
  bookEpilogue: BitBrandingBit;
  bookIntroduction: BitBrandingBit;
  bookIncitingIncident: BitBrandingBit;
  bookConclusion: BitBrandingBit;
  bookAfterword: BitBrandingBit;
  bookPostscript: BitBrandingBit;
  bookAppendix: BitBrandingBit;
  bookAddendum: BitBrandingBit;
  bookAcknowledgements: BitBrandingBit;
  bookListOfContributors: BitBrandingBit;
  bookBibliography: BitBrandingBit;
  bookReferenceList: BitBrandingBit;
  bookEndnotes: BitBrandingBit;
  bookNotes: BitBrandingBit;
  bookCopyrightPermissions: BitBrandingBit;
  bookTeaser: BitBrandingBit;
  bookAuthorBio: BitBrandingBit;
  bookRequestForABookReview: BitBrandingBit;
  bookComingSoon: BitBrandingBit;
  bookReadMore: BitBrandingBit;
  bookSummary: BitBrandingBit;
  bookImprint: BitBrandingBit;
  conclusion: BitBrandingBit;
  chapterSubjectMatter: BitBrandingBit;

  image: BitBrandingBit;
  imageLink: BitBrandingBit;
  imageZoom: BitBrandingBit;
  screenshot: BitBrandingBit;
  focusImage: BitBrandingBit;
  photo: BitBrandingBit;
  browserImage: BitBrandingBit;
  imageSuperWide: BitBrandingImageSuperWide;
  imagePrototype: BitBrandingBit;
  imagePortrait: BitBrandingBit;
  imageLandscape: BitBrandingBit;
  imageOnDevice: BitBrandingBit;
  pageBanner: BitBrandingBit;
  imageMood: BitBrandingBit;
  figure: BitBrandingBit;
  imageFigure: BitBrandingBit;
  imageBanner: BitBrandingBit;
  imageStyled: BitBrandingBit;
  imageScreenshot: BitBrandingBit;
  imageResponsive: BitBrandingBit;
  imageSeparator: BitBrandingBit;
  imagesLogoGrave: BitBrandingImagesLogoGraveBit;
  lifeSkillSticker: BitBrandingBit;
  appGetScreenshot: BitBrandingBit;
  appCreateBitsFromImage: BitBrandingBit;
  audio: BitBrandingBit;
  audioLink: BitBrandingBit;
  audioEmbed: BitBrandingBit;
  video: BitBrandingVideoBit;
  videoLink: BitBrandingVideoBit;
  videoLinkLandscape: BitBrandingVideoBit;
  videoLinkPortrait: BitBrandingVideoBit;
  videoEmbed: BitBrandingVideoBit;
  videoEmbedLandscape: BitBrandingVideoBit;
  videoEmbedPortrait: BitBrandingVideoBit;
  videoPortrait: BitBrandingVideoBit;
  videoLandscape: BitBrandingVideoBit;
  stillImageFilm: BitBrandingBit;
  stillImageFilmLink: BitBrandingBit;
  stillImageFilmEmbed: BitBrandingBit;
  websiteLink: BitBrandingBit;
  document: BitBrandingBit;
  documentLink: BitBrandingBit;
  documentEmbed: BitBrandingBit;
  documentDownload: BitBrandingBit;

  table: BitBrandingTableBit;
  tableImage: BitBrandingTableImageBit;

  appLink: BitBrandingBit;
  featured: BitBrandingBit;
  newspaperArticle: BitBrandingBit;
  blogArticle: BitBrandingBit;
  bookArticle: BitBrandingBit;
  notebookArticle: BitBrandingBit;
  workbookArticle: BitBrandingBit;
  code: BitBrandingBit;
  page: BitBrandingBit;
  pageArticle: BitBrandingBit;
  extractorPage: BitBrandingBit;
  extractorBlock: BitBrandingBit;
  extractorPageWithBlocks: BitBrandingBit;
  extractorRepeatedText: BitBrandingBit;
  extractorPageNumber: BitBrandingBit;
  extractorPageHeader: BitBrandingBit;
  extractorPageFooter: BitBrandingBit;

  cloze: BitBrandingBit;
  clozeSolutionGrouped: BitBrandingClozeGroupedBit;
  multipleChoiceText: BitBrandingBit;
  clozeAndMultipleChoiceText: BitBrandingBit;
  clozeInstructionGrouped: BitBrandingClozeGroupedBit;
  essay: BitBrandingBit;
  interview: BitGeneralBrandingBit;
  interviewInstructionGrouped: BitGeneralBrandingBit;
  mark: BitBrandingBit;
  match: BitGeneralBrandingBit;
  matchAll: BitGeneralBrandingBit;
  matchReverse: BitGeneralBrandingBit;
  matchAllReverse: BitGeneralBrandingBit;
  matchMatrix: BitGeneralBrandingBit;
  matchSolutionGrouped: BitGeneralBrandingBit;
  multipleChoice: BitBrandingQuizBit;
  multipleChoice1: BitBrandingBit;
  multipleResponse: BitBrandingQuizBit;
  multipleResponse1: BitBrandingBit;
  preparationNote: BitBrandingBit;
  selfAssessment: BitBrandingBit;
  survey: BitBrandingBit;
  trueFalse: BitGeneralBrandingBit;
  trueFalse1: BitBrandingBit;
  takePicture: BitBrandingTakePictureBit;
  recordAudio: BitBrandingRecordAudioBit;
  recordVideo: BitBrandingRecordVideoBit;
  summary: BitBrandingBit;
  card1: BitBrandingBit;
  question1: BitBrandingBit;
  message: BitBrandingBit;
  checklist: BitBrandingBit;
  separator: BitBrandingBit;
  videoTranscript: BitBrandingDetailsBit;
  audioTranscript: BitBrandingDetailsBit;

  step: BitBrandingBit;
  stepImageScreenshot: BitBrandingBit;
  stepImageScreenshotWithPointer: BitBrandingStepImagePointerBit;

  collapsible: BitBrandingDetailsBit;
  sideNoteCollapsible: BitBrandingDetailsBit;
  infoCollapsible: BitBrandingDetailsBit;
  remarkCollapsible: BitBrandingDetailsBit;
  warningCollapsible: BitBrandingDetailsBit;
  dangerCollapsible: BitBrandingDetailsBit;
  noteCollapsible: BitBrandingDetailsBit;
  exampleCollapsible: BitBrandingDetailsBit;
  hintCollapsible: BitBrandingDetailsBit;
  bugCollapsible: BitBrandingDetailsBit;

  cookPreparation: BitBrandingBit;
  cookStep: BitBrandingBit;
  cookRemark: BitBrandingBit;
  cookVariation: BitBrandingBit;
  cookInsert: BitBrandingBit;
  cookArrangement: BitBrandingBit;
  cookPracticeAdvise: BitBrandingBit;
  cookPlate: BitBrandingBit;
  cookRecommendation: BitBrandingBit;
  cookPersonalRecommendation: BitBrandingBit;
  cookSideDrink: BitBrandingBit;
  cookSideDish: BitBrandingBit;
  cookIngredients: BitBrandingCookIngredientsBit;
  recipe: BitBrandingCookIngredientsBit;

  langLearningOutcomes: BitBrandingBit;
  langEnablingLanguageSkills: BitBrandingBit;
  langLifeSkills: BitBrandingBit;
  langEnglishAroundWorld: BitBrandingBit;
  langGoodToKnow: BitBrandingBit;
  langLearningStrategy: BitBrandingBit;
  langLikeALocal: BitBrandingBit;
  langUsefulPhrases: BitBrandingBit;
  langLevelDown: BitBrandingBit;
  langLevelUp: BitBrandingBit;
  langExtraActivity: BitBrandingBit;
  langVideoScript: BitBrandingDetailsBit;
  langAudioScript: BitBrandingDetailsBit;
  langVocabulary: BitBrandingBit;
  langHomework: BitBrandingBit;
  langTeacherNote: BitBrandingBit;
  langTeacherPronunciation: BitBrandingBit;
  langMaterial: BitBrandingBit;
  langLearningGoal: BitBrandingBit;

  aiPrompt: BitBrandingBit;
  noteAi: BitBrandingBit;
  summaryAi: BitBrandingBit;
  articleAi: BitBrandingBit;
  bookSources: BitBrandingBookSourcesBit;
  bookLink: BitBrandingPageProductBit;
  bookClose: BitBrandingBit;
  module: BitBrandingModuleBit;
  pageBuyButton: BitBrandingPageBuyButtonBit;
  pageBuyButtonPromotion: BitBrandingPageBuyButtonBit;
  pageOpenBook: BitBrandingPageProductBit;
  pageProduct: BitBrandingPageProductBit;
  pageProductList: BitBrandingPageProductBit;
  pageProductVideo: BitBrandingPageProductBit;
  pageProductVideoList: BitBrandingPageProductBit;
  pageSectionFolder: BitBrandingPageProductBit;
  pageFooter: BitBrandingPageFooterBit;

  reviewNote: BitBrandingBit;
  reviewAuthorNote: BitBrandingBit;
  reviewReviewerNote: BitBrandingBit;
  reviewRequestForReviewNote: BitBrandingBit;
  reviewApprovedNote: BitBrandingBit;

  appAiPrompt: BitBrandingBit;
  appCodeEditor: BitBrandingBit;
  appFlashcards: BitBrandingAppFlashcardsBit;
  flashcard1: BitBrandingFlashcard1Bit;

  resourceImage: BitBrandingImage;
  resourceAudio: BitBrandingAudio;
  resourceVideo: BitBrandingVideo;
  resourceDocument: BitBrandingDocument;
  resourceWebsite: BitBrandingWebsite;

  conversationLeft1: BitBrandingConversation;
  conversationLeft1Scream: BitBrandingConversation;
  conversationLeft1Thought: BitBrandingConversation;
  conversationRight1: BitBrandingConversation;
  conversationRight1Scream: BitBrandingConversation;
  conversationRight1Thought: BitBrandingConversation;

  articleAlt: BitBrandingArticleAltBit;
  pageArticleAlt: BitBrandingArticleAltBit;
  noteAlt: BitBrandingBit;
  exampleAlt: BitBrandingBit;
  remarkAlt: BitBrandingBit;
  standardArticleNormative: BitBrandingBit;
  standardArticleNonNormative: BitBrandingBit;
  standardNoteNormative: BitBrandingBit;
  standardNoteNonNormative: BitBrandingBit;
  standardExampleNormative: BitBrandingBit;
  standardExampleNonNormative: BitBrandingBit;
  standardRemarkNormative: BitBrandingBit;
  standardRemarkNonNormative: BitBrandingBit;
  infoAlt: BitBrandingBit;
  hintAlt: BitBrandingBit;
  helpAlt: BitBrandingBit;
  warningAlt: BitBrandingBit;
  dangerAlt: BitBrandingBit;
  bugAlt: BitBrandingBit;
  conclusionAlt: BitBrandingBit;
  separatorAlt: BitBrandingBit;
  imageFigureAlt: BitBrandingBit;
  standardImageFigureNormative: BitBrandingBit;
  standardImageFigureNonNormative: BitBrandingBit;
  standardTableImageNormative: BitBrandingTableImageBit;
  standardTableImageNonNormative: BitBrandingTableImageBit;
  standardRemarkTableImageNormative: BitBrandingTableImageBit;
  standardRemarkTableImageNonNormative: BitBrandingTableImageBit;

  tableAlt: BitBrandingTableBit;
  tableImageAlt: BitBrandingTableImageBit;
  imageSeparatorAlt: BitBrandingTableBit;
  standardTableNormative: BitBrandingTableBit;
  standardTableNonNormative: BitBrandingTableBit;
  standardRemarkTableNormative: BitBrandingTableBit;
  standardRemarkTableNonNormative: BitBrandingTableBit;

  scorm: BitBrandingBit;
  vendorIframelyEmbed: BitBrandingBit;
  vendorHighchartsChart: BitBrandingBit;

  virtualMarkBookAsRead: BitBrandingVirtualMarkBookAsReadBit;
  virtualHandIn: BitBrandingVirtualHandInBit;
  virtualHandInReview: BitBrandingVirtualHandInBit;
  virtualHandInResult: BitBrandingVirtualHandInBit;
}

export interface BookEntityToc {
  ref?: string;
  toc?: boolean;
  item?: string;
  type?: BitType;
  level?: number;
  title?: string;
  isFree?: boolean;
  isPreview?: boolean;
  isProgress?: boolean;
  isGenericTitle?: boolean;
  isTrashed?: boolean;
  anchor?: string;
}

export interface BitAnnotationColor {
  primary: string;
  secondary: string;
  isSelected?: boolean;
}

export const BitAnnotationDefaultColor: { [key in BitType]?: BitAnnotationColor } = {
  [BitType.AnnotationFavorite]: {primary: '#d8f636', secondary: '#c7e32f'},
  [BitType.AnnotationBookmark]: {primary: '#ffb5ee', secondary: '#ff89e4'},
  [BitType.AnnotationNote]: {primary: '#fffb81', secondary: '#ffed82'},
};

export const DefaultBitAnnotationColors: Array<BitAnnotationColor> = [
  {primary: '#fffb81', secondary: '#ffed82'},
  {primary: '#8dceff', secondary: '#65bcfe'},
  {primary: '#ffb5ee', secondary: '#ff89e4'},
  {primary: '#d8f636', secondary: '#c7e32f'},
  {primary: '#ffb96d', secondary: '#ffa748'},
];

export enum ReaderHiddenElements {
  Toc = 'toc',
  Basket = 'basket',
  Bin = 'bin',
  Header = 'header',
  Search = 'search',
  HeaderMenu = 'header-menu',
  ChapterPath = 'chapterPath',
  Close = 'close',
  Progress = 'progress',
  BitActionsPadding = 'bit-actions-padding'
}

export const DefaultBitBranding: BitBranding = defDefaultBitBranding;

export const ReaderContentWidth = 800;

export interface SupportedTranslationLanguage {
  languageCode: string;
  displayName: string;
  displayNameEnglish?: string;
  supportSource: boolean;
  supportTarget: boolean;
}


export enum HandInStatus{
  NotHandedIn = 'NOTHANDEDIN',
  HandedIn = 'PENDING',
  Assigned = 'ASSIGNED',
  InReview = 'IN_REVIEW',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Archived = 'ARCHIVED'
}
