// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container .resource-image {
  display: block;
  width: var(--bitmark-details-image-img-container-size-width, inherit);
  height: var(--bitmark-details-image-img-container-size-height, 225px);
  padding: var(--bitmark-details-image-img-container-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-details-image-img-container-size-margin, var(--bitmark-x-size-margin, 0));
}

.triangle {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  height: 0;
  width: 0;
  border-left: 1.5em solid transparent;
  border-right: 1.5em solid transparent;
  border-top: 1.5em solid var(--bitmark-details-image-img-background-color);
  z-index: 20;
}

.toggle-btn {
  background-color: var(--bitmark-details-image-toggle-btn-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-details-image-toggle-btn-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-details-image-toggle-btn-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-details-image-toggle-btn-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-details-image-toggle-btn-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-details-image-toggle-btn-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-details-image-toggle-btn-background-animation-name) var(--bitmark-details-image-toggle-btn-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-details-image-toggle-btn-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-details-image-toggle-btn-background-backdrop-filter);
  color: var(--bitmark-details-image-toggle-btn-icon-color, #000);
  position: absolute;
  bottom: 0;
  left: 10px;
  transform: translate(0, 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.275);
  border-radius: 50%;
  transition: transform 500ms ease;
}
.toggle-btn.rotate {
  transform: translate(0, 50%) rotate(45deg);
}

.body-container {
  background-color: var(--bitmark-details-image-content-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-details-image-content-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-details-image-content-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-details-image-content-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-details-image-content-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-details-image-content-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-details-image-content-background-animation-name) var(--bitmark-details-image-content-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-details-image-content-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-details-image-content-background-backdrop-filter);
}
.body-container .body-size {
  padding: var(--bitmark-details-image-content-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-details-image-content-size-margin, var(--bitmark-x-size-margin, 0));
}

.instruction-container {
  background-color: var(--bitmark-details-image-summary-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-details-image-summary-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-details-image-summary-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-details-image-summary-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-details-image-summary-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-details-image-summary-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-details-image-summary-background-animation-name) var(--bitmark-details-image-summary-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-details-image-summary-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-details-image-summary-background-backdrop-filter);
  padding: var(--bitmark-details-image-summary-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-details-image-summary-size-margin, var(--bitmark-x-size-margin, 0));
}
.instruction-container .item {
  font-family: var(--bitmark-details-image-item-font-family, var(--bitmark-bit-item-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-details-image-item-font-size, var(--bitmark-bit-item-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-details-image-item-font-weight, var(--bitmark-bit-item-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-details-image-item-font-style, var(--bitmark-bit-item-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-details-image-item-font-text-decoration, var(--bitmark-bit-item-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-details-image-item-font-text-decoration, var(--bitmark-bit-item-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-details-image-item-font-line-height, var(--bitmark-bit-item-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-details-image-item-font-color, var(--bitmark-bit-item-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-details-image-item-font-align, var(--bitmark-bit-item-font-align, var(--bitmark-bit-item-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-details-image-item-font-align, var(--bitmark-bit-item-font-align, var(--bitmark-bit-item-font-align, var(--bitmark-main-font-alignt, left))));
  border-top: var(--bitmark-details-image-item-border-top-width, var(--bitmark-bit-item-border-top-width)) var(--bitmark-details-image-item-border-style, var(--bitmark-bit-item-border-style)) var(--bitmark-details-image-item-border-color, var(--bitmark-bit-item-border-color));
  border-right: var(--bitmark-details-image-item-border-right-width, var(--bitmark-bit-item-border-right-width)) var(--bitmark-details-image-item-border-style, var(--bitmark-bit-item-border-style)) var(--bitmark-details-image-item-border-color, var(--bitmark-bit-item-border-color));
  border-bottom: var(--bitmark-details-image-item-border-bottom-width, var(--bitmark-bit-item-border-bottom-width)) var(--bitmark-details-image-item-border-style, var(--bitmark-bit-item-border-style)) var(--bitmark-details-image-item-border-color, var(--bitmark-bit-item-border-color));
  border-left: var(--bitmark-details-image-item-border-left-width, var(--bitmark-bit-item-border-left-width)) var(--bitmark-details-image-item-border-style, var(--bitmark-bit-item-border-style)) var(--bitmark-details-image-item-border-color, var(--bitmark-bit-item-border-color));
  border-radius: var(--bitmark-details-image-item-border-radius, var(--bitmark-bit-item-border-radius, y));
  padding: var(--bitmark-item-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-item-size-margin, var(--bitmark-x-size-margin, 0));
  background-color: var(--bitmark-details-image-item-background-color, var(--bitmark-bit-item-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-details-image-item-background-image, var(--bitmark-bit-item-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-details-image-item-background-size, var(--bitmark-bit-item-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-details-image-item-background-position, var(--bitmark-bit-item-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-details-image-item-background-repeat, var(--bitmark-bit-item-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-details-image-item-background-transform, var(--bitmark-bit-item-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-details-image-item-background-animation-name) var(--bitmark-details-image-item-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-details-image-item-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-details-image-item-background-backdrop-filter);
}
.instruction-container .lead {
  display: inline-block;
  font-family: var(--bitmark-details-image-lead-font-family, var(--bitmark-bit-lead-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-details-image-lead-font-size, var(--bitmark-bit-lead-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-details-image-lead-font-weight, var(--bitmark-bit-lead-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-details-image-lead-font-style, var(--bitmark-bit-lead-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-details-image-lead-font-text-decoration, var(--bitmark-bit-lead-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-details-image-lead-font-text-decoration, var(--bitmark-bit-lead-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-details-image-lead-font-line-height, var(--bitmark-bit-lead-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-details-image-lead-font-color, var(--bitmark-bit-lead-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-details-image-lead-font-align, var(--bitmark-bit-lead-font-align, var(--bitmark-bit-lead-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-details-image-lead-font-align, var(--bitmark-bit-lead-font-align, var(--bitmark-bit-lead-font-align, var(--bitmark-main-font-alignt, left))));
  border-top: var(--bitmark-details-image-lead-border-top-width, var(--bitmark-bit-lead-border-top-width)) var(--bitmark-details-image-lead-border-style, var(--bitmark-bit-lead-border-style)) var(--bitmark-details-image-lead-border-color, var(--bitmark-bit-lead-border-color));
  border-right: var(--bitmark-details-image-lead-border-right-width, var(--bitmark-bit-lead-border-right-width)) var(--bitmark-details-image-lead-border-style, var(--bitmark-bit-lead-border-style)) var(--bitmark-details-image-lead-border-color, var(--bitmark-bit-lead-border-color));
  border-bottom: var(--bitmark-details-image-lead-border-bottom-width, var(--bitmark-bit-lead-border-bottom-width)) var(--bitmark-details-image-lead-border-style, var(--bitmark-bit-lead-border-style)) var(--bitmark-details-image-lead-border-color, var(--bitmark-bit-lead-border-color));
  border-left: var(--bitmark-details-image-lead-border-left-width, var(--bitmark-bit-lead-border-left-width)) var(--bitmark-details-image-lead-border-style, var(--bitmark-bit-lead-border-style)) var(--bitmark-details-image-lead-border-color, var(--bitmark-bit-lead-border-color));
  border-radius: var(--bitmark-details-image-lead-border-radius, var(--bitmark-bit-lead-border-radius, y));
  width: var(--bitmark-details-image-lead-size-width, var(--bitmark-bit-lead-size-width, inherit));
  height: var(--bitmark-details-image-lead-size-height, var(--bitmark-bit-lead-size-height, inherit));
  padding: var(--bitmark-details-image-lead-size-padding, var(--bitmark-bit-lead-size-padding, 0));
  margin: var(--bitmark-details-image-lead-size-margin, var(--bitmark-bit-lead-size-margin, 0));
}
.instruction-container .lead-dash {
  display: inline-block;
  margin: 0 5px;
}
.instruction-container .lead-dash:before {
  content: var(--bitmark-details-image-lead-content-before-string, var(--bitmark-bit-lead-content-before-string));
  margin: var(--bitmark-details-image-lead-content-before-margin, var(--bitmark-bit-lead-content-before-margin));
  font-family: var(--bitmark-details-image-lead-content-before-font-family, var(--bitmark-bit-lead-content-before-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-details-image-lead-content-before-font-size, var(--bitmark-bit-lead-content-before-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-details-image-lead-content-before-font-weight, var(--bitmark-bit-lead-content-before-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-details-image-lead-content-before-font-style, var(--bitmark-bit-lead-content-before-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-details-image-lead-content-before-font-text-decoration, var(--bitmark-bit-lead-content-before-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-details-image-lead-content-before-font-text-decoration, var(--bitmark-bit-lead-content-before-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-details-image-lead-content-before-font-line-height, var(--bitmark-bit-lead-content-before-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-details-image-lead-content-before-font-color, var(--bitmark-bit-lead-content-before-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-details-image-lead-content-before-font-align, var(--bitmark-bit-lead-content-before-font-align, var(--bitmark-bit-lead-content-before-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-details-image-lead-content-before-font-align, var(--bitmark-bit-lead-content-before-font-align, var(--bitmark-bit-lead-content-before-font-align, var(--bitmark-main-font-alignt, left))));
}
.instruction-container .lead-dash:after {
  content: var(--bitmark-details-image-lead-content-after-string, var(--bitmark-bit-lead-content-after-string));
  margin: var(--bitmark-details-image-lead-content-after-margin, var(--bitmark-bit-lead-content-after-margin));
  font-family: var(--bitmark-details-image-lead-content-after-font-family, var(--bitmark-bit-lead-content-after-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-details-image-lead-content-after-font-size, var(--bitmark-bit-lead-content-after-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-details-image-lead-content-after-font-weight, var(--bitmark-bit-lead-content-after-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-details-image-lead-content-after-font-style, var(--bitmark-bit-lead-content-after-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-details-image-lead-content-after-font-text-decoration, var(--bitmark-bit-lead-content-after-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-details-image-lead-content-after-font-text-decoration, var(--bitmark-bit-lead-content-after-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-details-image-lead-content-after-font-line-height, var(--bitmark-bit-lead-content-after-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-details-image-lead-content-after-font-color, var(--bitmark-bit-lead-content-after-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-details-image-lead-content-after-font-align, var(--bitmark-bit-lead-content-after-font-align, var(--bitmark-bit-lead-content-after-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-details-image-lead-content-after-font-align, var(--bitmark-bit-lead-content-after-font-align, var(--bitmark-bit-lead-content-after-font-align, var(--bitmark-main-font-alignt, left))));
}
.instruction-container .instruction {
  display: flex;
  font-family: var(--bitmark-details-image-instruction-font-family, var(--bitmark-bit-instruction-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-details-image-instruction-font-size, var(--bitmark-bit-instruction-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-details-image-instruction-font-weight, var(--bitmark-bit-instruction-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-details-image-instruction-font-style, var(--bitmark-bit-instruction-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-details-image-instruction-font-text-decoration, var(--bitmark-bit-instruction-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-details-image-instruction-font-text-decoration, var(--bitmark-bit-instruction-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-details-image-instruction-font-line-height, var(--bitmark-bit-instruction-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-details-image-instruction-font-color, var(--bitmark-bit-instruction-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-details-image-instruction-font-align, var(--bitmark-bit-instruction-font-align, var(--bitmark-bit-instruction-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-details-image-instruction-font-align, var(--bitmark-bit-instruction-font-align, var(--bitmark-bit-instruction-font-align, var(--bitmark-main-font-alignt, left))));
  border-top: var(--bitmark-details-image-instruction-border-top-width, var(--bitmark-bit-item-border-top-width)) var(--bitmark-details-image-instruction-border-style, var(--bitmark-bit-item-border-style)) var(--bitmark-details-image-instruction-border-color, var(--bitmark-bit-item-border-color));
  border-right: var(--bitmark-details-image-instruction-border-right-width, var(--bitmark-bit-item-border-right-width)) var(--bitmark-details-image-instruction-border-style, var(--bitmark-bit-item-border-style)) var(--bitmark-details-image-instruction-border-color, var(--bitmark-bit-item-border-color));
  border-bottom: var(--bitmark-details-image-instruction-border-bottom-width, var(--bitmark-bit-item-border-bottom-width)) var(--bitmark-details-image-instruction-border-style, var(--bitmark-bit-item-border-style)) var(--bitmark-details-image-instruction-border-color, var(--bitmark-bit-item-border-color));
  border-left: var(--bitmark-details-image-instruction-border-left-width, var(--bitmark-bit-item-border-left-width)) var(--bitmark-details-image-instruction-border-style, var(--bitmark-bit-item-border-style)) var(--bitmark-details-image-instruction-border-color, var(--bitmark-bit-item-border-color));
  border-radius: var(--bitmark-details-image-instruction-border-radius, var(--bitmark-bit-item-border-radius, y));
  padding: var(--bitmark-details-image-instruction-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-details-image-instruction-size-margin, var(--bitmark-x-size-margin, 0));
  flex-grow: 1;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
