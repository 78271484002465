import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatchBit} from './match.models';
import {BitResource} from '../bits.models';

@Component({
  selector: 'bitmark-match',
  templateUrl: './match.component.html',
  styleUrls: ['../bits.scss']
})
export class MatchComponent {
  @Input() bit?: MatchBit;
  @Input() isReverse = false;
  @Input() readOnly = false;
  @Input() hasFeedback = false;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() changed = new EventEmitter<any>();
}
