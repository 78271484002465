import {Component, EventEmitter, HostListener, Inject, Input, OnInit, Output} from '@angular/core';
import {VirtualHandInReviewBit} from './virtual-hand-in-review.models';
import {HandInStatus, ProductFamily} from "../../shared/models/bitmark.models";
import {BitbookMqService} from "../../reader/bitbook-mq.service";
import {BitbookApiService} from "../../reader/bitbook-api.service";
import {BitmarkConfig} from "../../bitmark.module";
import {HandInData} from "../../reader/reader.models";
import {TranslateService} from "@ngx-translate/core";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'bitmark-virtual-hand-in-review',
  templateUrl: './virtual-hand-in-review.component.html',
  styleUrls: ['./virtual-hand-in-review.component.scss', '../bits.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '*',  // Use '*' to adapt to content size
        opacity: 1
      })),
      state('closed', style({
        height: '0px',
        opacity: 0
      })),
      transition('open <=> closed', [
        animate('0.3s')
      ]),
    ]),
    trigger('fadeOut', [
      state('visible', style({
        opacity: 1
      })),
      state('invisible', style({
        opacity: 0
      })),
      transition('visible => invisible', [
        animate('0.5s')
      ])
    ])
  ]
})
export class VirtualHandInReviewComponent implements OnInit {
  @Input() handInBit?: VirtualHandInReviewBit;
  @Output() navigateToBook: EventEmitter<{
    bookId: string,
    fragment: string,
    family?: ProductFamily
  }> = new EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily, queryParams?: any }>();
  @Output() assignHandIn: EventEmitter<{ handInId: number, isSelfAssign?: boolean }> = new EventEmitter<{
    handInId: number,
    isSelfAssign?: boolean
  }>();

  status: HandInStatus = HandInStatus.HandedIn;
  handInDate: Date;
  expertUser: any;
  studentUser: any;
  handInApproved: boolean;
  currentUserIsAssignedExpert = false;
  isDescriptionOpen = true;

  @HostListener('document:gmbUserDetailsDelivered', ['$event'])
  onUserDetailsDelivered(event: any) {
    if (event?.detail?.id == this.handInBit.handIn?.mainExpert?.externalId) {
      this.expertUser = event?.detail;
    } else if (event?.detail?.email === this.handInBit?.handIn?.mainExpert?.email || event?.detail?.ssoEmail === this.handInBit?.handIn?.mainExpert?.email) {
      this.expertUser = event?.detail;
    }
    if (this.expertUser?.email && (this.bitmarkConfig?.userEmails?.email === this.expertUser?.email || this.bitmarkConfig?.userEmails?.ssoEmail === this.expertUser?.email)) {
      this.currentUserIsAssignedExpert = true;
    }
    if (event?.detail?.id == this.handInBit.handIn?.student?.externalId) {
      this.studentUser = event?.detail;
    } else if (event?.detail?.email === this.handInBit?.handIn?.student?.email || event?.detail?.ssoEmail === this.handInBit?.handIn?.student?.email) {
      this.studentUser = event?.detail;
    }
  }

  constructor(@Inject('BitmarkConfig') private bitmarkConfig: BitmarkConfig,
              private bitbookApiService: BitbookApiService,
              private bitbookMqService: BitbookMqService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    console.log('Hand in review bit: ', this.handInBit);
    this.getHandInUsers();
    this.bitbookMqService.onHandInIsAssigned()
      .subscribe((data) => {
        this.handleHandInAssignment(data);
      });
    this.bitbookMqService.onHandInIsReAssigned()
      .subscribe((data) => {
        this.handleHandInReAssignment(data);
      });
  }

  assignToMe() {
    this.assignHandIn.emit({
      handInId: this.handInBit.handIn?.id,
      isSelfAssign: true
    })
    this.status = HandInStatus.Assigned;
  }

  assignToOther() {
    this.assignHandIn.emit({
      handInId: this.handInBit.handIn?.id,
      isSelfAssign: false
    })
  }

  handleHandInReAssignment(data: { handInId: number, expertUser: { email: string, ssoEmail: string, id: number } }) {
    console.log('handle hand in assignment');
    if (data.handInId === this.handInBit?.handIn?.id) {
      this.expertUser = data?.expertUser;
      if(this.handInBit.handIn.mainExpert){
        this.handInBit.handIn.mainExpert.email = data.expertUser.email || data.expertUser.ssoEmail;
        this.handInBit.handIn.mainExpert.externalId = data.expertUser.id;
      } else{
        this.handInBit.handIn.mainExpert = {
          email: data.expertUser.email || data.expertUser.ssoEmail,
          externalId: data.expertUser.id.toString()
        }
      }
      if (this.bitmarkConfig.userEmails.email === this.expertUser.email || this.bitmarkConfig.userEmails.ssoEmail === this.expertUser.email) {
        this.currentUserIsAssignedExpert = true;
      } else {
        this.currentUserIsAssignedExpert = false;
      }
    }
  }

  handleHandInAssignment(data: { handInId: number, expertUser: { email: string, ssoEmail: string, id: number } }) {
    console.log('handle hand in assignment');
    if (data.handInId === this.handInBit?.handIn?.id) {
      this.status = HandInStatus.Assigned;
      if (this.handInBit.handIn) {
        this.handInBit.handIn.status === HandInStatus.Assigned;
      }
      this.expertUser = data?.expertUser;
      if(this.handInBit.handIn.mainExpert){
        this.handInBit.handIn.mainExpert.email = data.expertUser.email || data.expertUser.ssoEmail;
        this.handInBit.handIn.mainExpert.externalId = data.expertUser.id;
      } else{
        this.handInBit.handIn.mainExpert = {
          email: data.expertUser.email || data.expertUser.ssoEmail,
          externalId: data.expertUser.id.toString()
        }
      }
      if (this.bitmarkConfig.userEmails.email === this.expertUser.email || this.bitmarkConfig.userEmails.ssoEmail === this.expertUser.email) {
        this.currentUserIsAssignedExpert = true;
      } else {
        this.currentUserIsAssignedExpert = false;
      }
    }
  }

  resolveHandIn(approvalStatus: boolean) {
    this.handInApproved = approvalStatus;
    if (approvalStatus) {
      this.bitbookApiService.approveHandIn(this.handInBit.handIn?.id).subscribe((handInResult) => {
        this.status = HandInStatus.Approved;
        this.handInDate = new Date();
        this.handInBit.handIn.status = HandInStatus.Approved;
        this.sendHandInResultToStudent(approvalStatus, handInResult);
      }, (err) => {
        console.error('There was an error resolving the hand-in: ', err);
        alert(this.translate.instant('HandIn.ThereWasAnErrorAccepting'));
      })
    } else {
      this.bitbookApiService.rejectHandIn(this.handInBit.handIn?.id).subscribe((handInResult) => {
        this.status = HandInStatus.Rejected;
        this.handInDate = new Date();
        this.handInBit.handIn.status = HandInStatus.Rejected;
        this.sendHandInResultToStudent(approvalStatus, handInResult);
      }, (err) => {
        console.error('There was an error resolving the hand-in: ', err);
        alert('There was an error rejecting the hand-in');
      })
    }
  }

  //TODO: move to mq service
  sendHandInResultToStudent(approvalStatus: boolean, handInResult: HandInData) {
    const ev = new CustomEvent('gmbHandInReview', {
      detail: {
        userId: this.studentUser?.id,
        handIn: handInResult,
        handInApproved: approvalStatus
      }, bubbles: true,
    });
    document.dispatchEvent(ev);
  }

  getHandInUsers() {
    this.status = this.handInBit?.handIn?.status;
    this.handInDate = (this.handInBit?.handIn?.status === HandInStatus.Approved || this.handInBit?.handIn?.status === HandInStatus.Rejected) ?
      this.handInBit?.handIn?.returnedAt : this.handInBit?.handIn?.handedInAt;
    if (this.handInBit?.handIn?.mainExpert?.email) {
      const ev = new CustomEvent('gmbUserDetailsRequested', {
        detail: {
          userEmail: this.handInBit?.handIn?.mainExpert?.email
        }, bubbles: true,
      });
      document.dispatchEvent(ev);
    }
    if (this.handInBit?.handIn?.student?.email) {
      const evs = new CustomEvent('gmbUserDetailsRequested', {
        detail: {
          userEmail: this.handInBit?.handIn?.student?.email
        }, bubbles: true,
      });
      document.dispatchEvent(evs);
    }
  }

  openAccordion() {
    this.isDescriptionOpen = !this.isDescriptionOpen;
  }

  protected readonly HandInStatus = HandInStatus;
}
