// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedback-button-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.feedback-timestamp {
  --bitmark-survey-rating-once-rating-timestamp-default-font-align: center;
  font-family: var(--bitmark-survey-rating-once-rating-timestamp-font-family, var(--bitmark-survey-rating-once-rating-timestamp-default-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-survey-rating-once-rating-timestamp-font-size, var(--bitmark-survey-rating-once-rating-timestamp-default-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-survey-rating-once-rating-timestamp-font-weight, var(--bitmark-survey-rating-once-rating-timestamp-default-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-survey-rating-once-rating-timestamp-font-style, var(--bitmark-survey-rating-once-rating-timestamp-default-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-survey-rating-once-rating-timestamp-font-text-decoration, var(--bitmark-survey-rating-once-rating-timestamp-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-survey-rating-once-rating-timestamp-font-text-decoration, var(--bitmark-survey-rating-once-rating-timestamp-default-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-survey-rating-once-rating-timestamp-font-line-height, var(--bitmark-survey-rating-once-rating-timestamp-default-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-survey-rating-once-rating-timestamp-font-color, var(--bitmark-survey-rating-once-rating-timestamp-default-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-survey-rating-once-rating-timestamp-font-align, var(--bitmark-survey-rating-once-rating-timestamp-default-font-align, var(--bitmark-survey-rating-once-rating-timestamp-default-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-survey-rating-once-rating-timestamp-font-align, var(--bitmark-survey-rating-once-rating-timestamp-default-font-align, var(--bitmark-survey-rating-once-rating-timestamp-default-font-align, var(--bitmark-main-font-alignt, left))));
}

.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

@keyframes fadeInDown {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 70px;
    opacity: 1;
  }
}
.fadeInDown {
  animation-name: fadeInDown;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
