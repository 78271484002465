// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.conversation-text-wrapper {
  border-top: var(--bitmark-conversation-left-1-thought-bubble-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-conversation-left-1-thought-bubble-border-style, var(--bitmark-x-border-style)) var(--bitmark-conversation-left-1-thought-bubble-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-conversation-left-1-thought-bubble-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-conversation-left-1-thought-bubble-border-style, var(--bitmark-x-border-style)) var(--bitmark-conversation-left-1-thought-bubble-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-conversation-left-1-thought-bubble-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-conversation-left-1-thought-bubble-border-style, var(--bitmark-x-border-style)) var(--bitmark-conversation-left-1-thought-bubble-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-conversation-left-1-thought-bubble-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-conversation-left-1-thought-bubble-border-style, var(--bitmark-x-border-style)) var(--bitmark-conversation-left-1-thought-bubble-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-conversation-left-1-thought-bubble-border-radius, var(--bitmark-x-border-radius, y));
  padding: var(--bitmark-conversation-left-1-thought-bubble-content-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-conversation-left-1-thought-bubble-content-size-margin, var(--bitmark-x-size-margin, 0));
  background-color: var(--bitmark-conversation-left-1-thought-bubble-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-conversation-left-1-thought-bubble-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-conversation-left-1-thought-bubble-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-conversation-left-1-thought-bubble-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-conversation-left-1-thought-bubble-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-conversation-left-1-thought-bubble-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-conversation-left-1-thought-bubble-background-animation-name) var(--bitmark-conversation-left-1-thought-bubble-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-conversation-left-1-thought-bubble-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-conversation-left-1-thought-bubble-background-backdrop-filter);
}
.conversation-text-wrapper.with-partner {
  margin-left: -36px;
}
.conversation-text-wrapper.with-icon {
  margin-bottom: 70px;
}
.conversation-text-wrapper.without-icon {
  margin-bottom: 60px;
}

.user-avatar {
  font-family: var(--bitmark-conversation-left-1-thought-partner-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-conversation-left-1-thought-partner-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-conversation-left-1-thought-partner-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-conversation-left-1-thought-partner-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-conversation-left-1-thought-partner-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-conversation-left-1-thought-partner-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-conversation-left-1-thought-partner-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-conversation-left-1-thought-partner-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-conversation-left-1-thought-partner-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-conversation-left-1-thought-partner-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
}

.rectangle-speech-bubble:before {
  content: "";
  position: absolute;
  height: 3px;
  width: 3px;
  bottom: -20px;
  background-color: var(--bitmark-conversation-left-1-thought-bubble-background-color, var(--bitmark-reader-content-background-color));
  border-radius: 100%;
  left: 85px;
  box-shadow: 0 0 0 7px var(--bitmark-conversation-left-1-thought-bubble-background-color, var(--bitmark-reader-content-background-color)), 0 0 0 10px var(--bitmark-conversation-left-1-thought-bubble-border-color, #5a8f00), -22px 10px 0 5px var(--bitmark-conversation-left-1-thought-bubble-background-color, var(--bitmark-reader-content-background-color)), -22px 10px 0 8px var(--bitmark-conversation-left-1-thought-bubble-border-color, #5a8f00), -40px 15px 0 2px var(--bitmark-conversation-left-1-thought-bubble-background-color, var(--bitmark-reader-content-background-color)), -40px 15px 0 5px var(--bitmark-conversation-left-1-thought-bubble-border-color, #5a8f00);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
