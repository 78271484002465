// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bits-wrapper {
  max-width: var(--bitmark-reader-content-width);
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}
.bits-wrapper.themed {
  background-color: var(--bitmark-reader-content-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-reader-content-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-reader-content-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-reader-content-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-reader-content-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-reader-content-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-reader-content-background-animation-name) var(--bitmark-reader-content-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-reader-content-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-reader-content-background-backdrop-filter);
  border-top: var(--bitmark-reader-content-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-reader-content-border-style, var(--bitmark-x-border-style)) var(--bitmark-reader-content-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-reader-content-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-reader-content-border-style, var(--bitmark-x-border-style)) var(--bitmark-reader-content-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-reader-content-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-reader-content-border-style, var(--bitmark-x-border-style)) var(--bitmark-reader-content-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-reader-content-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-reader-content-border-style, var(--bitmark-x-border-style)) var(--bitmark-reader-content-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-reader-content-border-radius, var(--bitmark-x-border-radius, y));
  margin-bottom: 60px;
}
.bits-wrapper.themed-no-margin {
  margin-bottom: 0;
}

.bit-shadow {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
}

.reader-summary {
  display: block;
  max-width: var(--bitmark-reader-content-width);
  width: 100%;
  margin: auto;
}

.book-padding {
  padding: 0 10px;
}
@media (min-width: 680px) {
  .book-padding {
    padding: 0 30px;
  }
}

.reader-editor-status {
  right: 0;
  bottom: 80px;
  z-index: 2;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.reader-title-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1px;
  width: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  opacity: var(--bitmark-reader-title-bar-show);
}
.reader-title-wrapper .reader-title-area {
  transform: rotate(-90deg);
  transform-origin: top right;
  font-family: "Inter", serif;
  z-index: 1;
  height: 40px;
}
.reader-title-wrapper .reader-title-area .reader-title-content {
  display: inline-block;
  background-color: #000000;
  color: #ffffff;
  border-bottom-left-radius: 20px;
  height: 41px;
}
.reader-title-wrapper .reader-title-area .book-title {
  font-size: 11px;
}
.reader-title-wrapper .reader-title-area .book-selected-chapter {
  font-size: 16px;
  transform: rotate(180deg);
  padding: 10px 20px 10px 40px;
}

.reader-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100vh;
}

.reader-content {
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.reader-content::-webkit-scrollbar {
  width: 0;
  background: transparent; /* Chrome/Safari/Webkit */
}

.absolute-dropdown {
  position: fixed !important;
  right: 40px !important;
  bottom: 50px !important;
  z-index: 100;
}

.absolute-menu-padding {
  height: 50px;
  min-height: 50px;
  display: block;
}

.page-scroll-action {
  bottom: 10px;
  right: 10px;
  box-shadow: 0 0 10px 0 #000000;
}

.page-scroll-top {
  transition: transform 0.5s;
  transform: rotate(0deg);
}

.page-scroll-bottom {
  transition: transform 0.5s;
  transform: rotate(180deg);
}

.overflow-visible {
  overflow: visible !important;
}

.reader-note-editor {
  background-color: #ffffff;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: 1px solid #dddddd;
  border-bottom: none !important;
  min-height: 48px;
}

.quick-note-close {
  position: absolute;
  top: 8px;
  right: 48px;
  z-index: 2;
}

.bit-skeleton-loader {
  width: var(--bitmark-bit-size-width, 100%);
  margin: var(--bitmark-bit-item-size-margin, 0);
  padding: var(--bitmark-bit-item-size-padding, 0);
  background-color: var(--bitmark-article-external-background-color, var(--bitmark-bit-external-background-color, var(--bitmark-article-background-color, var(--bitmark-article-background-color, var(--bitmark-reader-content-background-color)))));
  background-image: var(--bitmark-article-external-background-image, var(--bitmark-bit-external-background-image, var(--bitmark-article-background-image)));
  background-size: var(--bitmark-article-external-background-size, var(--bitmark-bit-external-background-size, var(--bitmark-article-background-size, cover)));
  background-position: var(--bitmark-article-external-background-position, var(--bitmark-bit-external-background-position, var(--bitmark-article-background-position, center)));
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 680px) {
  .reader-body {
    max-height: 99vh;
    height: 99vh;
  }
}
.annotations-menu {
  position: absolute;
}
.annotations-menu .annotations-menu-popup {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: relative;
  z-index: 2;
}
.annotations-menu .annotations-menu-popup .annotation-btn-img {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
.annotations-menu .annotations-menu-popup .separator {
  height: 1px;
  width: 100%;
  background-color: #d9d9d9;
}

.hand-in-sticky {
  position: sticky;
  left: 0;
  right: 0;
  top: -5px;
  z-index: 1000;
}

::ng-deep .reader-read-only {
  pointer-events: none !important;
  -webkit-user-select: none !important;
          user-select: none !important;
}
::ng-deep .reader-read-only input, ::ng-deep .reader-read-only textarea {
  background-color: transparent;
  border: none;
  color: inherit;
}
::ng-deep .reader-read-only .hand-in-sticky .bit-wrapper1 {
  pointer-events: auto !important;
  -webkit-user-select: auto !important;
          user-select: auto !important;
}
::ng-deep .reader-large-bottom-padding {
  padding-bottom: 70px !important;
}
::ng-deep .reader-content-shop .bit1.is-annotation-bookmark, ::ng-deep .reader-content-self-learning .bit1.is-annotation-bookmark {
  left: 0 !important;
}
::ng-deep .reader-content-shop .bit-type-bit-book-summary, ::ng-deep .reader-content-self-learning .bit-type-bit-book-summary {
  height: 0 !important;
}
::ng-deep .reader-content-shop .bit-type-bit-book-ending, ::ng-deep .reader-content-self-learning .bit-type-bit-book-ending {
  display: none;
  height: 0 !important;
}

.chip {
  background-color: var(--bitmark-reader-primary-color, #543199);
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
  min-width: 30px;
  font-size: 12px;
  padding: 1px 6px;
}

.reader-chip-container {
  margin-top: 7px;
}

.search-results-title {
  font-family: var(--bitmark-reader-search-results-title-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-reader-search-results-title-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-reader-search-results-title-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-reader-search-results-title-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-reader-search-results-title-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-reader-search-results-title-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-reader-search-results-title-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-reader-search-results-title-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-reader-search-results-title-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-reader-search-results-title-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
