// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scheduled-date {
  width: 72px;
  min-height: 68px;
  background-color: var(--bitmark-learning-path-calendar-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-learning-path-calendar-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-learning-path-calendar-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-learning-path-calendar-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-learning-path-calendar-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-learning-path-calendar-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-learning-path-calendar-background-animation-name) var(--bitmark-learning-path-calendar-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-learning-path-calendar-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-learning-path-calendar-background-backdrop-filter);
  font-family: var(--bitmark-learning-path-calendar-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-learning-path-calendar-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-learning-path-calendar-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-learning-path-calendar-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-learning-path-calendar-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-learning-path-calendar-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-learning-path-calendar-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-learning-path-calendar-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-learning-path-calendar-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-learning-path-calendar-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
  border-top-left-radius: 0.2rem;
}
.scheduled-date div {
  min-height: var(--bitmark-learning-path-calendar-font-line-height);
}
.scheduled-date .date-text-lg {
  font-size: calc(var(--bitmark-learning-path-calendar-font-size) * 2);
  line-height: calc(var(--bitmark-learning-path-calendar-font-line-height) * 2);
}

.scheduled-date-edit-input {
  visibility: hidden;
  width: 1px;
  height: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.time-display {
  max-width: 140px;
  white-space: nowrap;
}

.body-container-edit-mode {
  background-color: var(--bitmark-learning-path-settings-color);
}
.body-container-edit-mode > div {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  background-color: var(--bitmark-learning-path-background-color);
}

.bit-button {
  background-color: var(--bitmark-learning-path-button-background-color) !important;
  justify-content: center !important;
  font-size: 20px !important;
  padding: 0 20px !important;
  text-align: center !important;
}

.edit-container {
  background-color: var(--bitmark-learning-path-settings-color);
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.edit-container .state-button {
  padding-left: 15px;
  padding-right: 15px;
  height: 30px;
  min-height: 30px;
  font-size: 16px !important;
  min-width: 90px;
  font-weight: 500;
}
.edit-container .state-save-button {
  background-color: var(--bitmark-reader-primary-color) !important;
  color: #ffffff;
}
.edit-container .state-cancel-button {
  color: var(--bitmark-reader-primary-color);
}

.learning-path-bit-image {
  width: 60px;
  height: 80px;
}
.learning-path-bit-image img {
  max-height: 100%;
}
.learning-path-bit-image .image-placeholder {
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.7;
  color: var(--bitmark-reader-primary-color);
}
.learning-path-bit-image .image-container {
  height: 100%;
}
.learning-path-bit-image .image-editable {
  cursor: pointer;
}
.learning-path-bit-image .image-editable .image-container {
  background-color: white;
  opacity: 0.5;
}
.learning-path-bit-image .remove-image {
  position: absolute;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--bitmark-reader-primary-color);
}

.learning-path-item, .learning-path-header-details {
  font-size: 14px;
}

.learning-path-instruction {
  font-size: 20px;
  max-height: calc(2 * var(--bitmark-learning-path-header-font-line-height, inherit) * 20px);
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
