import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {PageBuyButtonBit} from './page-buy-button.models';
import {BitbookMqService} from '../../reader/bitbook-mq.service';
import {Subscription} from 'rxjs';
import {ProductFamily} from "../../shared/models/bitmark.models";

@Component({
  selector: 'bitmark-page-buy-button',
  templateUrl: './page-buy-button.component.html',
  styleUrls: ['./page-buy-button.component.scss', '../bits.scss']
})
export class PageBuyButtonComponent implements OnInit, OnDestroy {
  @Input() bit?: PageBuyButtonBit;
  @Input() content2buy;
  @Input() simulatedPrice;
  @Input() catalogPrice;
  @Input() currency;
  @Input() branding;
  @Output() navigateToBook: EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily, queryParams?: any }> = new EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily, queryParams?: any }>();
  private _operationId;
  private productFamily: ProductFamily;
  isAlreadyOwned;
  priceSubscription: Subscription;

  @HostListener('document:gmbProductPriceDelivered', ['$event'])
  onProductPriceDelivered(event: any) {
    if (event?.detail?.operationId === this._operationId) {
      this.productFamily = event.detail?.family;
      this.simulatedPrice = event.detail?.simulatedPrice;
      this.catalogPrice = event.detail?.catalogPrice;
      this.currency = event.detail?.currency;
      this.isAlreadyOwned = event.detail?.isAlreadyOwned;
    }
  }

  constructor(private bitbookMqService: BitbookMqService) {
  }

  ngOnInit() {
    this._operationId = (this.bit?.content2Buy || this.content2buy) + (Math.random() + 1).toString(36).substring(7);
    if (!this.simulatedPrice) {
      const event = new CustomEvent('gmbProductPriceRequested', { detail: { productId: this.bit?.content2Buy || this.content2buy, operationId: this._operationId} , bubbles: true });
      document.dispatchEvent(event);
    }
    this.priceSubscription = this.bitbookMqService.onShopProductPurchased().subscribe((bookId: string) => {
      if (this.content2buy === bookId || this.bit?.content2Buy === bookId) {
        this.isAlreadyOwned = true;
      }
    });
  }

  openBook() {
    this.navigateToBook.emit({bookId: this.bit?.content2Buy || this.content2buy, fragment: 'start', family: this.productFamily});
  }

  buyProduct() {
    if (this.bit?.content2Buy || this.content2buy) {
      const event = new CustomEvent('gmbBuyClicked', { detail: this.bit?.content2Buy || this.content2buy, bubbles: true });
      document.dispatchEvent(event);
    }
  }

  ngOnDestroy() {
    if (this.priceSubscription) {
      this.priceSubscription.unsubscribe();
    }
  }
}
