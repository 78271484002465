import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Subject} from 'rxjs';
import {cloneDeep} from 'lodash';
import {DebounceService, DropdownItemModel, ObjectService, SubSink, UrlRewriteService} from '../shared';
import {BitmarkBitFeedback, BitmarkBitFeedbackStepsTwoSteps, BitmarkFormat, ProductFamily} from '../shared/models';
import {BaseBit, BitApiAnnotation, BitApiWrapper, BitFeedback, BitFeedbackRes, BitResource, BitResourceSize, BitsViewPortVisibility, BitType} from './bits.models';
import {BrandingRenderService} from '../reader/branding-render.service';
import {BitbookApiService} from '../reader/bitbook-api.service';
import {ReaderSimpleTextEditorComponent, ReaderTextEditorComponent, TocItem} from '../reader';
import {ReaderContext, ReaderModes} from '../reader/reader.models';
import {BitbookMqService} from '../reader/bitbook-mq.service';
import {BitBrandingJsCustomizerService} from '../shared/bit-branding-js-customizer/bit-branding-js-customizer.service';
import BitUtilsService from '../shared/utils/bit-utils.service';
import {AnalyticsService} from '../shared/analytics/analytics.service';
import {BitEvalService} from './bit-eval.service';
import {
  articleAltBitTypes,
  articleBitResourceTypes,
  articleBitTypes,
  cardBitTypes,
  cookIngredientsBitTypes,
  detailsBitTypes,
  pageBitTypes,
  quoteBitTypes,
  stepImageScreenshotWithPointerBitTypes,
  tableBitTypes,
  tableImageBitTypes
} from './bit-like-types';
import {TranslateService} from '@ngx-translate/core';
import {BitmarkPipe} from './bitmark.pipe';
import {BitmarkConfig} from '../bitmark.module';
import {ReaderTipTapTapService} from '../reader/tiptap/reader-tiptap.service';
import {JSONContent} from '@tiptap/core';

@Component({
  selector: 'bitmark-bit',
  templateUrl: './bit.component.html',
  styleUrls: ['./bit.component.scss', './bits.scss'],
})
export class BitComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
  @Input() bitWrapper?: BitApiWrapper;
  @Input() isEditable = false;
  @Input() allowAnswerSubmit = true;
  @Input() actions: Array<DropdownItemModel> = [];
  @Input() actionsCssClass = '';
  @Input() fontScale?: 80 | 100 | 110 | 120 | 140 = null;
  @Input() context: ReaderContext;
  @Input() options?: any;
  @Input() queryParams?: any;
  @Input() readerMode?: ReaderModes;
  @Input() saveUserEdit: boolean;
  @Input() fullScreen = false;
  @Input() focusedBit: string = null;
  @Input() showFeedback = true;
  @Input() shouldProtectAgainstUserCopy = false;
  @Input() peerUser?: any;
  @Input() isNavigateDirectlyToBook = false;
  @Output() loaded = new EventEmitter<any>();
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() measureDisplaySize = new EventEmitter<BitResourceSize>();
  @Output() toggleTocEvent = new EventEmitter<any>();
  @Output() isBitBeingEdited = new EventEmitter<any>();
  @Output() isBitAnnotationBeingEdited = new EventEmitter<any>();
  @Output() editClosed = new EventEmitter<any>();
  @Output() answerSubmitted = new EventEmitter<BaseBit>();
  @Output() navigateToBook = new EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily, queryParams?: any }>();
  @Output() closeBook = new EventEmitter<any>();
  @Output() assignHandIn = new EventEmitter<{ handInId: number, isSelfAssign?: boolean }>();
  @Output() isBookUnaccessibleToUser = new EventEmitter<{ bookId: string }>();
  @Output() navigateToProduct = new EventEmitter<{ productId: string, family?: ProductFamily }>();
  @Output() bitEditInProgress = new EventEmitter<any>();
  @Output() bitEditCompleted = new EventEmitter<{ success: boolean, tocEntry?: TocItem }>();
  @Output() editorCreated = new EventEmitter<ReaderTextEditorComponent | ReaderSimpleTextEditorComponent>();
  @Output() connectWithUser: EventEmitter<{ email: string }> = new EventEmitter<{ email: string }>();

  private _isBeingEditedByUser?: boolean;

  @Input()
  set isBeingEditedByUser(value: boolean) {
    this._isBeingEditedByUser = value;

    if (value) {
      this.prevBit = cloneDeep(this.bitWrapper.bit);
    }
  }

  get isBeingEditedByUser(): boolean {
    return this._isBeingEditedByUser;
  }

  @ViewChild('rootElement') rootElement: ElementRef;
  @ViewChild('placeToRender', {read: ViewContainerRef, static: true}) placeToRender: ViewContainerRef;

  isInViewPort = false;
  isGettingFeedback = false;

  BitmarkFormat = BitmarkFormat;
  BitType = BitType;
  brandingClass: string;
  hasFeedback = false;
  feedbackInProgress = false;
  showInterimFeedback = false;
  isFeedbackStreaming = false;
  bitEvaluationFeedbackMessages: Array<BitFeedback> = [];
  hasAnswer = false;
  feedbackId = null;
  hasSolution = false;
  canSave = false;
  showItemLeadInstruction = false;
  canHaveItemLeadInstruction = false;
  isArticleResourceType = false;
  updateInstructionEvent?: Subject<any> = new Subject<any>();
  footnotes: Array<{ indexContent: any, content: any, autoNumbered: boolean }> = [];
  private prevBit?: BaseBit;
  private sub = new SubSink();

  protected readonly articleBitTypes = articleBitTypes;
  protected readonly articleAltBitTypes = articleAltBitTypes;
  protected readonly articleBitResourceTypes = articleBitResourceTypes;
  protected readonly cookIngredientsBitTypes = cookIngredientsBitTypes;
  protected readonly tableBitTypes = tableBitTypes;
  protected readonly tableImageBitTypes = tableImageBitTypes;
  protected readonly cardBitTypes = cardBitTypes;
  protected readonly detailsBitTypes = detailsBitTypes;
  protected readonly pageBitTypes = pageBitTypes;
  protected readonly quoteBitTypes = quoteBitTypes;
  protected readonly stepImageScreenshotWithPointerBitTypes = stepImageScreenshotWithPointerBitTypes;

  private bitmarkPipe: BitmarkPipe;

  constructor(@Inject('BitmarkConfig') private bitmarkConfig: BitmarkConfig,
              private bitBookApiService: BitbookApiService,
              private brandingRenderService: BrandingRenderService,
              private objectService: ObjectService,
              private bitUtilsService: BitUtilsService,
              private debounceService: DebounceService,
              private bitbookMqService: BitbookMqService,
              private bitBrandingJsCustomizerService: BitBrandingJsCustomizerService,
              private ngZone: NgZone,
              private analyticsService: AnalyticsService,
              private bitEvalService: BitEvalService,
              private translateService: TranslateService,
              readerTipTapService: ReaderTipTapTapService) {
    this.bitmarkPipe = new BitmarkPipe(readerTipTapService, new UrlRewriteService());
  }

  ngOnChanges(changes: SimpleChanges) {
    const keys = Object.keys(changes);

    if (['bitWrapper', 'isBeingGenerated']
      .find(c => keys.indexOf(c) !== -1)) {
      this.refresh();
    }
  }

  ngOnInit() {
    if (this.bitWrapper?.bit?.resource?.article) {
      if ([null, undefined, ''].indexOf(this.bitWrapper?.bit?.resource?.article?.format) !== -1) {
        this.bitWrapper.bit.resource.article.format = this.bitWrapper.bit.format;
      }
    }

    const t0 = performance.now();
    this.computeFootnotes((this.bitWrapper?.bit as any)?.body);
    console.log(`FOOTNOTES COMPUTATION TIME: ${performance.now() - t0} milliseconds.`);

    if (this.articleBitResourceTypes.includes(this.bitWrapper?.bit?.type)) {
      this.isEditable = false;
      this.isArticleResourceType = true;
    }
    this.isInViewPort = this.context === 'timeline' || this.context === 'shop-section' || this.context === 'shop-section-readonly';

    this.sub.sink = this.bitbookMqService.onBitsVisibility()
      .subscribe((visibleBits: BitsViewPortVisibility) => {
        const localIsInViewport = this.context === 'timeline' || this.context === 'shop-section' || this.context === 'shop-section-readonly' || this.context === 'self-learning'
          || !!visibleBits.bitsTouchingViewPort.find((x: BitApiWrapper) => x.id === this.bitWrapper.id)
          || !!visibleBits.bitAnnotationsTouchingViewPort.find((x: BitApiAnnotation) => x.id.toString() === this.bitWrapper.id);
        const isInViewportChanged = localIsInViewport !== this.isInViewPort;
        if (localIsInViewport) {
          this.ngZone.run(() => {
            this.isInViewPort = localIsInViewport;
          });
        } else {
          this.isInViewPort = localIsInViewport;
        }
        if (isInViewportChanged) {
          this.analyticsService.record(localIsInViewport ? 'bit-show' : 'bit-hide', {
            bitId: this.bitWrapper.id,
            bitType: this.bitWrapper.bit?.type,
            language: this.bitWrapper.bit?.lang || this.bitWrapper.meta?.language,
            learningLanguage: this.bitWrapper.meta?.learningLanguage,
            chapterPath: this.bitWrapper.chapterPath,
            tag: this.bitWrapper.tags,
            analyticsTag: this.bitWrapper.analyticsTag,
            reductionTag: this.bitWrapper.reductionTag
          });
        }
      });

    this.brandingClass = this.brandingRenderService.getBitThemeClass(this.bitWrapper);
    this.loaded.emit();
  }

  ngAfterViewInit() {
    // const bitWrapper = document.getElementById(`bit-${this.bitWrapper.id}`);
    const bitWrappers = document.querySelectorAll(`#bit-${this.bitWrapper.id}`);
    const bitWrapper = bitWrappers[bitWrappers.length - 1] as HTMLElement;
    if (!bitWrapper) {
      return;
    }
    bitWrapper.style.setProperty('visibility', 'hidden');
    setTimeout(() => {
      this.bitBrandingJsCustomizerService.setNegativePadding(bitWrapper);
      // TODO: find maybe better alternatives to removing the shadow
      this.bitBrandingJsCustomizerService.removeBitShadowIfBackdrop(bitWrapper);

      this.bitBrandingJsCustomizerService.adjustForBitWallpaper(bitWrapper);
      if (this.bitWrapper.bit?.icon) {
        this.bitBrandingJsCustomizerService.setCustomIcon(bitWrapper, this.bitWrapper.bit.icon);
      }
      if (this.bitWrapper.bit?.type !== BitType.Message) {
        this.bitBrandingJsCustomizerService.compensateBitIconMargins(bitWrapper);
        this.bitBrandingJsCustomizerService.positionBitIcon(bitWrapper);
        this.bitBrandingJsCustomizerService.alignByContext(bitWrapper);
      }
      bitWrapper.style.setProperty('visibility', '');
    }, 500);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private refresh() {
    this.handleFeedbackStep();
    this.hasAnswer = this.objectService.hasNonNullValue(this.bitWrapper.bit, 'answer', ['text', 'choice', 'responses']);
    this.feedbackId = this.bitWrapper?.bit?.feedbackEngine?.feedbackId
      || (this.bitWrapper?.bit?.feedbackId && this.bitWrapper?.bit?.feedbackId[0]);
    this.hasSolution = this.feedbackId
      || this.objectService.hasNonNullValue(this.bitWrapper.bit, 'quizzes', ['responses'])
      || this.objectService.hasNonNullValue(this.bitWrapper.bit, 'quizzes', ['choices'])
      || this.objectService.hasNonNullValue(this.bitWrapper.bit, '{', ['solutions'])
      || this.objectService.hasNonNullValue(this.bitWrapper.bit, '{', ['options'])
      || this.objectService.hasNonNullValue(this.bitWrapper.bit, 'pairs', ['values'])
      || this.objectService.hasNonNullValue(this.bitWrapper.bit, 'cells', ['values'])
      || this.objectService.hasNonNullValue(this.bitWrapper.bit, 'statements', ['statement'])
      || this.objectService.hasNonNullValue(this.bitWrapper.bit, 'isCorrect', ['isCorrect'], 'isCorrect')
      || this.objectService.hasNonNullValue(this.bitWrapper.bit, 'choices', ['choice'])
      || this.objectService.hasNonNullValue(this.bitWrapper.bit, 'responses', ['response']);
    this.canSave = this.context !== 'new-release' && this.hasSolution
      || [BitType.Essay,
        BitType.Assignment,
        BitType.SelfAssessment,
        BitType.PreparationNote,
        BitType.Survey,
        BitType.Interview,
        BitType.InterviewInstructionGrouped,
        BitType.LearningPathLti,
        BitType.LearningPathClosing,
        BitType.LearningPathVideoCall,
        BitType.LearningPathBook,
        BitType.LearningPathClassroomEvent,
        BitType.LearningPathExternalLink,
        BitType.LearningPathStep,
        BitType.LearningPathLearningGoal,
        BitType.TakePicture,
        BitType.RecordAudio,
        BitType.RecordVideo,
        BitType.AppFlashcards,
        BitType.CookIngredients,
        BitType.Recipe,
        BitType.SurveyRating,
        BitType.SurveyRatingOnce,
        BitType.PrototypeImages,
        BitType.SurveyMatrix,
        BitType.SurveyMatrixMe].indexOf(this.bitWrapper?.bit?.type) !== -1;
    this.canHaveItemLeadInstruction = ![
      BitType.Page,
      BitType.Chapter,
      BitType.BotActionSend,
      BitType.BotActionResponse,
      BitType.LearningPathLti,
      BitType.LearningPathClosing,
      BitType.LearningPathVideoCall,
      BitType.LearningPathBook,
      BitType.LearningPathClassroomEvent,
      BitType.LearningPathExternalLink,
      BitType.LearningPathStep,
      BitType.LearningPathLearningGoal,
      BitType.ButtonCopyText,
      BitType.SampleSolution,
      BitType.ReleaseNote,
      BitType.LangAudioScript,
      BitType.LangVideoScript,
      BitType.DetailsImage,
      BitType.PageBuyButtonPromotion,
      BitType.Module,
      ...this.articleAltBitTypes,
      ...this.detailsBitTypes
    ].includes(this.bitWrapper?.bit?.type);
    this.showItemLeadInstruction = (this.bitWrapper?.bit?.item || this.bitWrapper?.bit?.lead || this.bitWrapper?.bit?.instruction)
      && this.canHaveItemLeadInstruction;
  }

  private handleFeedbackStep() {
    this.feedbackInProgress = false;
    this.hasFeedback = false;
    this.showInterimFeedback = false;
    this.bitEvaluationFeedbackMessages = [];

    if (!this.bitWrapper?.bit?.feedback) {
      return;
    }

    if ([BitmarkBitFeedback.TwoStep, BitmarkBitFeedback.TwoStepAi].includes(this.bitWrapper?.bit?.feedbackType)) {
      this.showInterimFeedback = [BitmarkBitFeedbackStepsTwoSteps.InterimFeedback].includes(this.bitWrapper?.bit?.feedbackAnswer?.step);
      this.feedbackInProgress = this.bitWrapper?.bit?.feedbackAnswer?.step > BitmarkBitFeedbackStepsTwoSteps.Redo;
      if (this.feedbackInProgress) {
        const evaluation = this.bitEvalService.evalAnswer(this.bitWrapper);
        let messages: Array<string>;
        if (this.bitWrapper?.bit?.feedbackAnswer?.message) {
          messages = this.bitWrapper.bit.feedbackAnswer.message.split('\n').filter(x => x);
        } else {
          messages = [this.translateService.instant('Bits.FeedbackRetryOrSubmitMessage', {correct: evaluation.correctAnswers, total: evaluation.totalAnswers})];
        }

        const timestamp = new Date().getTime();
        this.bitEvaluationFeedbackMessages = messages.map(x => {
          return {
            timeStamp: timestamp,
            message: x
          };
        });
      }
      this.hasFeedback = this.bitWrapper?.bit?.feedbackAnswer?.step === BitmarkBitFeedbackStepsTwoSteps.FinalFeedback;
    } else {
      this.feedbackInProgress = true;
      this.hasFeedback = true;
      this.showInterimFeedback = false;
    }
  }

  private computeFootnotes(body: any): any[] {
    if (!body) {
      return [];
    }

    let parsedBody: JSONContent;
    if (typeof body === 'string') {
      parsedBody = this.bitmarkPipe.transform(body, this.bitWrapper?.bit?.format, 'json') as JSONContent;
    } else {
      parsedBody = body;
    }

    if (parsedBody?.content) {
      this.aggregateFootnotes(parsedBody.content);
    }
  }

  private aggregateFootnotes(content: JSONContent[]) {
    content?.forEach(data => {
      const footnoteMark = data?.marks?.find(x => x.type === 'footnote' || x.type === 'footnote*');
      if (footnoteMark) {
        const {marks, ...restData} = data;
        this.footnotes.push({indexContent: [restData], content: footnoteMark.attrs.content, autoNumbered: !!data?.marks?.find(x => x.type === 'footnote*')});
      }
      if (data.content?.length) {
        this.aggregateFootnotes(data.content);
      }
    });
  }

  onIsBitBeingEdited(bit: any) {
    this.isBitBeingEdited.emit(bit);
  }

  onIsAnnotationBeingEdited(bit: any) {
    this.isBitAnnotationBeingEdited.emit(bit);
  }

  isFeedbackButtonVisible(): boolean {
    if (this.hasFeedback || [BitType.BotActionResponse].includes(this.bitWrapper?.bit?.type) || this.bitWrapper?.bit?.disableFeedback) {
      return false;
    }

    return this.allowAnswerSubmit && this.hasAnswer && this.hasSolution && !this.showInterimFeedback && !this.isFeedbackStreaming;
  }

  changed(answerSavedSub?: Subject<BaseBit>) {
    this.debounceService.debounce(1000, () => {
      if (!this.canSave) {
        // allow to get feedback but not save
        this.hasAnswer = this.bitUtilsService.hasBitAnswer(this.bitWrapper?.bit);
        return;
      }
      this.hasAnswer = this.bitUtilsService.hasBitAnswer(this.bitWrapper?.bit);
      if (!this.hasAnswer) {
        return;
      }
      if (!(this.bitWrapper?.id || this.bitWrapper?.bitInstanceId)) {
        return;
      }

      if (!this.isFeedbackButtonVisible()) {
        const answerEval = this.bitEvalService.evalAnswer(this.bitWrapper);
        if (answerEval) {
          this.analyticsService.record('bit-answered', {
            bitId: this.bitWrapper.id,
            bitType: this.bitWrapper.bit?.type,
            bitInstanceId: this.bitWrapper?.bitInstanceId,
            bookId: this.bitWrapper?.meta?.thisBook?.id || this.bitWrapper?.meta?.originBook?.id,
            bookExternalId: this.bitWrapper?.meta?.thisBook?.externalId || this.bitWrapper?.meta?.originBook?.externalId,
            answer: answerEval,
            language: this.bitWrapper.bit?.lang || this.bitWrapper.meta?.language,
            learningLanguage: this.bitWrapper.meta?.learningLanguage,
            chapterPath: this.bitWrapper.chapterPath,
            tag: this.bitWrapper.tags,
            analyticsTag: this.bitWrapper.analyticsTag,
            reductionTag: this.bitWrapper.reductionTag
          });
        }
      }

      this.bitBookApiService.saveAnswer(this.bitWrapper?.id, this.bitWrapper?.bit, this.bitWrapper?.bitInstanceId)
        .subscribe((res: { bit: BaseBit, id: number, bitId: any }) => {
          if (!res.bit?.id) {
            res.bit.id = res.bitId;
          }
          this.bitWrapper.bitInstanceId = res.id;
          this.answerSubmitted.emit(res.bit);
          answerSavedSub?.next(res.bit);
        }, (err: HttpErrorResponse) => {
          console.error(err);
          this.answerSubmitted.emit(this.bitWrapper?.bit);
          answerSavedSub?.next(this.bitWrapper?.bit);
        });
    });
  }

  async getFeedback() {
    this.feedbackInProgress = true;
    switch (this.bitWrapper?.bit?.feedbackType) {
      case BitmarkBitFeedback.TwoStep: {
        this.getTwoStepFeedback();
        break;
      }

      case BitmarkBitFeedback.TwoStepAi: {
        if (this.bitmarkConfig.hasChatGpt) {
          await this.getTwoStepAiFeedback();
        } else {
          this.getTwoStepFeedback();
        }
        break;
      }

      default: {
        this.submitAnswer();
      }
    }
  }

  getTwoStepFeedback() {
    this.bitWrapper.bit.feedbackAnswer = {
      step: BitmarkBitFeedbackStepsTwoSteps.InterimFeedback
    };
    this.submitAnswer();
  }

  async getTwoStepAiFeedback() {
    const answerEval = this.bitEvalService.evalAnswer(this.bitWrapper);
    if (answerEval) {
      this.isFeedbackStreaming = true;

      let readerContent: ReadableStreamReadResult<Uint8Array>;
      let reader: ReadableStreamDefaultReader<Uint8Array>;
      try {
        const response = await this.bitBookApiService.generateAiFeedback(this.bitmarkConfig.userLanguage, answerEval.correctAnswers, answerEval.totalAnswers);

        if (!response.ok) {
          console.error('Error generating AI Feedback', response.status);
          this.getTwoStepFeedback();
          return;
        }

        reader = response.body.getReader();
        readerContent = await reader.read();
      } catch (ex) {
        console.error(ex);
        this.getTwoStepFeedback();
        return;
      }

      const timestamp = new Date().getTime();
      let buffer = '';
      while (!readerContent.done) {
        const message = new TextDecoder().decode(readerContent.value);
        buffer += message;

        this.bitEvaluationFeedbackMessages = buffer
          .split('\n')
          .filter(x => x)
          .map((x, i, arr) => {
            return {
              timeStamp: timestamp,
              message: x,
              streaming: i + 1 === arr.length
            };
          });

        readerContent = await reader.read();
      }

      this.bitWrapper.bit.feedbackAnswer = {
        step: BitmarkBitFeedbackStepsTwoSteps.InterimFeedback,
        message: buffer
      };
      this.submitAnswer();
    }
  }

  doItAgainFeedback() {
    this.bitWrapper.bit.feedbackAnswer = {
      step: BitmarkBitFeedbackStepsTwoSteps.Redo
    };
    this.saveFeedbackStep();
  }

  submitResultsFeedback() {
    if (!this.bitWrapper?.bit?.feedbackAnswer) {
      this.bitWrapper.bit.feedbackAnswer = {};
    }
    this.bitWrapper.bit.feedbackAnswer.step = BitmarkBitFeedbackStepsTwoSteps.FinalFeedback;

    this.saveFeedbackStep();
  }

  submitAnswer() {
    if (this.isGettingFeedback) {
      return;
    }
    if (!this.bitWrapper?.id) {
      alert('Please save the bit first.');
      return;
    }
    this.isGettingFeedback = true;
    this.bitWrapper.bit.feedbackEngine = Object.assign({}, this.bitWrapper?.bit?.feedbackEngine, {
      feedbackId: this.feedbackId,
      userId: 'empty',
      timeOnTask: 0
    });

    this.bitBookApiService.getFeedback(this.bitWrapper?.id, this.bitWrapper?.bit, this.bitWrapper?.bitInstanceId)
      .subscribe((res: BitFeedbackRes) => {
        this.isGettingFeedback = false;
        this.isFeedbackStreaming = false;
        this.bitWrapper.bit = res.bit;

        if (!res.bit?.id) {
          res.bit.id = res.bitId;
        }
        this.bitWrapper.bitInstanceId = res.id;

        this.answerSubmitted.emit(res.bit);
        this.refresh();
        const answerEval = this.bitEvalService.evalAnswer(this.bitWrapper);
        if (answerEval) {
          this.analyticsService.record('bit-answered', {
            bitId: this.bitWrapper.id,
            bitType: this.bitWrapper.bit?.type,
            bitInstanceId: this.bitWrapper?.bitInstanceId,
            bookId: this.bitWrapper?.meta?.thisBook?.id || this.bitWrapper?.meta?.originBook?.id,
            bookExternalId: this.bitWrapper?.meta?.thisBook?.externalId || this.bitWrapper?.meta?.originBook?.externalId,
            answer: answerEval,
            attempts: res.attempts,
            language: this.bitWrapper.bit?.lang || this.bitWrapper.meta?.language,
            learningLanguage: this.bitWrapper.meta?.learningLanguage,
            chapterPath: this.bitWrapper.chapterPath,
            tag: this.bitWrapper.tags,
            analyticsTag: this.bitWrapper.analyticsTag,
            reductionTag: this.bitWrapper.reductionTag,
          });
        }
      }, (err: HttpErrorResponse) => {
        console.error(err);
        this.isGettingFeedback = false;
        this.isFeedbackStreaming = false;
        this.bitWrapper.bit.feedback = [{
          correctness: 'Unknown',
          message: `Instance API returned an error: ${err.message}`,
          timeStamp: Date.now()
        }];
        this.answerSubmitted.emit(this.bitWrapper?.bit);
        this.refresh();
      });
  }

  saveBitJson(bit: BaseBit, tags?: Array<string>) {
    if (this.context === 'editor') {
      return;
    }

    const {answer, ...newBit} = bit;

    this.bitEditInProgress.emit();

    const newTags = [...new Set([...(this.bitWrapper.meta.tags || []), ...(tags || [])])];

    this.bitBookApiService.editBitJson(newBit, bit?.id || this.bitWrapper.id, newTags, this.options?.courseId, this.queryParams)
      .subscribe((data: { tocEntry: TocItem, bit: BitApiWrapper }) => {
        if (this.bitWrapper) {
          this.bitWrapper.bitmark = data.bit?.bitmark || '';
          if (this.bitWrapper?.meta) {
            this.bitWrapper.meta.tags = data.bit?.meta?.tags;
          }
        }
        this.refresh();
        this.editClosed.emit();
        this.bitEditCompleted.emit({success: true, tocEntry: data?.tocEntry});
      }, () => {
        this.bitEditCompleted.emit({success: false});
      });
  }

  revertBitChanges($event: any) {
    if (this.prevBit) {
      this.bitWrapper.bit = cloneDeep(this.prevBit);
    }
    this.refresh();
    this.editClosed.emit($event);
  }

  private saveFeedbackStep() {
    if (this.isGettingFeedback) {
      return;
    }
    this.isGettingFeedback = true;
    this.bitBookApiService.saveAnswer(this.bitWrapper?.id, this.bitWrapper?.bit, this.bitWrapper?.bitInstanceId)
      .subscribe((res: { bit: BaseBit, id: number, bitId: any }) => {
        this.isGettingFeedback = false;

        if (!res.bit?.id) {
          res.bit.id = res.bitId;
        }
        this.bitWrapper.bitInstanceId = res.id;
        this.refresh();
        this.answerSubmitted.emit(res.bit);
      }, (err: HttpErrorResponse) => {
        console.error(err);
        this.isGettingFeedback = false;
      });
  }
}
