import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {of} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BitmarkPipe} from '../../../bits';
import {BitbookMqService} from '../../bitbook-mq.service';
import {BitbookApiService} from '../../bitbook-api.service';
import {BookPosition, ReaderTrackingService} from '../../reader-tracking.service';
import {ReaderTocService} from '../../reader-toc.service';
import {BookEntity, ReaderModes} from '../../reader.models';
import {ModalTouchSwipeService, SubSink, UrlRewriteService} from '../../../shared';
import {ReaderContentService} from '../reader-content/reader-content.service';
import {BitApiWrapper, BitsViewPortVisibility, BitType} from '../../../bits/bits.models';
import {
  BitAnnotationColor,
  BitmarkFormat,
  BookEntityToc,
  BookType,
  DefaultBitAnnotationColors,
} from '../../../shared/models/bitmark.models';
import {memoize} from '../../../shared/decorators/memoize.decorator';
import {ReaderNotebookBinComponent} from '../../reader-bin/reader-notebook-bin/reader-notebook-bin.component';
import {ReaderTipTapTapService} from '../../tiptap/reader-tiptap.service';

export interface TocItemAnnotation {
  type: string;
  id: number;
  title: string;
  color: {
    primary: string;
    secondary: string;
  };
  isExpert?: boolean;
  user?: any;
}

export interface TocItem {
  ref?: string;
  index?: number;
  item?: string;
  type?: BitType;
  title?: string;
  icon?: string;
  image?: string;
  level?: number;
  isVisible?: boolean;
  label?: string;
  isSeparator?: boolean;
  isHidden?: boolean;
  displayLead?: string;
  displayElement?: string;
  isBookTitle?: boolean;
  trashedAt?: string;
  isPlaceholder?: boolean;
  isGenericTitle?: boolean;
  isTrashed?: boolean;
  annotations?: Array<TocItemAnnotation>;
  externalAnnotations?: Array<TocItemAnnotation>;
  allAnnotations?: Array<TocItemAnnotation>;
  annotationsObj?: {
    prefixAnnotations?: Array<TocItemAnnotation>,
    postfixAnnotations?: Array<TocItemAnnotation>
  };
  resourceType?: 'website' | 'websiteLink';
}

@Component({
  selector: 'bitmark-reader-toc-sidebar',
  templateUrl: './reader-toc-sidebar.component.html',
  styleUrls: ['./reader-toc-sidebar.component.scss'],
})
export class ReaderTocSidebarComponent implements OnInit, OnDestroy {
  @Input() bitBook: BookEntity;
  @Input() readerMode: ReaderModes;
  @Output() close = new EventEmitter<any>();
  @Output() toggleSearch = new EventEmitter<any>();

  private sub = new SubSink();

  private chapterIndexesInToc: Array<TocItem> = [{ ref: null, index: 0 }];
  private allBitsTableOfContents: Array<TocItem> = [];
  private topChapters: Array<TocItem> = [];
  tableOfContents: Array<TocItem> = [];
  chapterMenuItems = [];
  selectedChapter: TocItem;
  selectedColor: string;
  nextChapter: TocItem;
  previousChapter: TocItem;
  annotationColors: Array<BitAnnotationColor>;
  annotationTypes: Array<string | BitType> = [
    BitType.AnnotationBookmark,
    BitType.AnnotationFavorite,
    BitType.AnnotationNote,
    BitType.AnnotationHandwritten,
  ];
  BitType = BitType;
  private bitmarkPipe: BitmarkPipe;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private translate: TranslateService,
              private bitbookMqService: BitbookMqService,
              private bitBookApiService: BitbookApiService,
              private readerTrackingService: ReaderTrackingService,
              private readerTocService: ReaderTocService,
              private readerContentService: ReaderContentService,
              private ngbModal: NgbModal,
              private modalTouchSwipeService: ModalTouchSwipeService,
              private readerTipTapService: ReaderTipTapTapService) {
    this.bitmarkPipe = new BitmarkPipe(readerTipTapService, new UrlRewriteService());
  }

  ngOnInit() {
    // this.sub.sink = this.bitbookMqService.onBitVisibilityChanged()
    //   .subscribe(this.changeBitVisibility.bind(this));
    // this.sub.sink = this.bitbookMqService.onReaderScrolledToBit()
    //   .subscribe(this.markOutsideBitsAsNotVisible.bind(this));
    this.sub.sink = this.bitbookMqService.onReaderContentBitsScrollEnd()
      .subscribe(this.markOutsideNeighboringBitsAsNotVisible.bind(this));
    this.sub.sink = this.bitbookMqService.onReaderBitRemoved()
      .subscribe(this.removeBitFromToc.bind(this));
    this.sub.sink = this.bitbookMqService.onReaderEditorAction()
      .subscribe(this.reloadToc.bind(this));
    this.sub.sink = this.bitbookMqService.onSetTocAndReload()
      .subscribe(this.setTocAndReload.bind(this));
    this.sub.sink = this.bitbookMqService.onReaderRefreshNotebook()
      .subscribe(this.refreshTocIfNeeded.bind(this));
    this.sub.sink = this.bitbookMqService.onReaderRefreshToc()
      .subscribe(this.refreshTocIfNeeded.bind(this));
    this.sub.sink = this.bitbookMqService.onReaderRefreshBit()
      .subscribe(this.refreshBit.bind(this));

    this.sub.sink = this.bitbookMqService.onBitsVisibility()
      .subscribe(this.handleBitsVisibilityChange.bind(this));
    this.sub.sink = this.bitbookMqService.onBitsScrollVisibility()
      .subscribe(this.handleBitsVisibilityChange.bind(this));

    this.readerTocService.getIsTocReloadNeeded(this.bitBook.externalId)
      .subscribe((isNeeded) => {
        if (isNeeded) {
          this.reloadToc();
          this.readerTocService.setIsTocReloadNeeded(this.bitBook.externalId, false);
        } else {
          this.loadToc();
        }
      });
    const clrs = JSON.parse(JSON.stringify(DefaultBitAnnotationColors));
    clrs?.forEach((c) => c.isSelected = false);
    this.annotationColors = [{
      primary: '#fff',
      secondary: '#000',
      isSelected: true,
      isDefaultFilter: true,
    }].concat(clrs);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  refreshBit(data: { id: string, tocEntry?: TocItem }) {
    if (this.bitBook.id !== data.id || !data.id) {
      return;
    }
    const tocBitIndex = this.allBitsTableOfContents.findIndex((b: TocItem) => b.ref === data.tocEntry.ref);
    if (tocBitIndex === -1) {
      return;
    }
    const bookTocIndex = this.bitBook?.toc.findIndex((b) => b.ref === data.tocEntry.ref);
    if (bookTocIndex !== -1) {
      this.bitBook.toc[bookTocIndex] = data?.tocEntry;
    }
    const newBit = this.setDisplayContentForBit(Object.assign({}, data.tocEntry, {
      index: this.allBitsTableOfContents[tocBitIndex]?.index,
      isVisible: this.allBitsTableOfContents[tocBitIndex]?.isVisible,
    }));
    if (newBit.allAnnotations?.length) {
      newBit.allAnnotations?.forEach((a) => {
        if (!a?.color) {
          a = this.setDefaultColorForAnnotation(a);
        }
      });
      newBit.annotationsObj = {
        prefixAnnotations: newBit.allAnnotations?.filter((a) => a.type === BitType.AnnotationBookmark || a.type === BitType.AnnotationFavorite),
        postfixAnnotations: newBit.allAnnotations?.filter((a) => a.type === BitType.AnnotationNote || a.type === BitType.AnnotationHandwritten)
      };
    }
    this.allBitsTableOfContents[tocBitIndex] = newBit;
    const displayedTocBitIndex = this.tableOfContents.findIndex((b) => b.ref === data.tocEntry.ref);
    if (displayedTocBitIndex !== -1) {
      this.tableOfContents[displayedTocBitIndex] = newBit;
    }
    this.allBitsTableOfContents = this.getFilteredTocItems(this.allBitsTableOfContents); // #4558
    this.tableOfContents = this.getFilteredTocItems(this.tableOfContents); // #4558

    this.chapterMenuItems = this.createChapterMenuItems();
    if (this.selectedChapter.type) {
      // refresh selected chapter title if in one of the annotations category
      this.selectChapter(this.chapterMenuItems.find((c) => c.type === this.selectedChapter.type));
    }
  }

  createChapterMenuItems() {
    const cmi = this.topChapters
      .map((c: TocItem) => {
        return Object.assign({}, {
          handler: () => this.selectChapter(c, true),
        }, c);
      })
      .concat(
        [{
          isSeparator: true,
          isHidden: this.topChapters?.length < 2,
          handler: () => {
          },
        }, {
          image: 'assets/images/annotations/annotation-bookmark-menu-item.svg',
          ref: BitType.AnnotationBookmark,
          type: BitType.AnnotationBookmark,
          title: `Bookmarks (${this.getAnnotationsCategoryNrOfItems(BitType.AnnotationBookmark)})`,
          label: `Bookmarks (${this.getAnnotationsCategoryNrOfItems(BitType.AnnotationBookmark)})`,
          handler: () => this.selectCategory(BitType.AnnotationBookmark),
        }, {
          image: 'assets/images/annotations/annotation-favorite-menu-item.svg',
          ref: BitType.AnnotationFavorite,
          type: BitType.AnnotationFavorite,
          title: `Favorites (${this.getAnnotationsCategoryNrOfItems(BitType.AnnotationFavorite)})`,
          label: `Favorites (${this.getAnnotationsCategoryNrOfItems(BitType.AnnotationFavorite)})`,
          handler: () => this.selectCategory(BitType.AnnotationFavorite),
        }, {
          image: 'assets/images/annotations/annotation-note-menu-item.svg',
          ref: BitType.AnnotationNote,
          type: BitType.AnnotationNote,
          title: `Notes (${this.getAnnotationsCategoryNrOfItems(BitType.AnnotationNote)})`,
          label: `Notes (${this.getAnnotationsCategoryNrOfItems(BitType.AnnotationNote)})`,
          handler: () => this.selectCategory(BitType.AnnotationNote),
        }, {
          image: 'assets/images/annotations/annotation-handwritten-menu-item.svg',
          ref: BitType.AnnotationHandwritten,
          type: BitType.AnnotationHandwritten,
          title: `Scribbles (${this.getAnnotationsCategoryNrOfItems(BitType.AnnotationHandwritten)})`,
          label: `Scribbles (${this.getAnnotationsCategoryNrOfItems(BitType.AnnotationHandwritten)})`,
          handler: () => this.selectCategory(BitType.AnnotationHandwritten),
        }, {
          isSeparator: true,
          isHidden: false,
          handler: () => {
          },
        }, {
          image: 'assets/images/trash_icon.svg',
          title: `${this.translate.instant('System.Bin')} (${this.getTrashedBitsCount()})`,
          label: `${this.translate.instant('System.Bin')} (${this.getTrashedBitsCount()})`,
          handler: () => this.openBin(),
        }],
      );
    cmi.splice(1, 0, {
      isSeparator: true, handler: () => {
      },
    });
    return cmi;
  }

  refreshTocIfNeeded(data: { id: string, shouldInitializeBitVisibility: boolean }) {
    if (this.bitBook.id === data.id) {
      this.reloadToc(data);
    }
  }

  setTocAndReload(toc: Array<TocItem>) {
    this.bitBook.toc = toc;
    this.loadToc({
      shouldNotScrollToc: true,
      shouldInitializeBitVisibility: true,
    });
  }

  reloadToc(options?: { shouldNotScrollToc?: boolean, shouldInitializeBitVisibility?: boolean }) {
    const req = this.bitBook.type === BookType.Collection
      ? this.bitBookApiService.getNotebookById(this.bitBook.id)
      : this.bitBookApiService.getBookById(this.bitBook.externalId, [{handInId: this.route.snapshot.queryParams.handInId}]);
    return req
      .subscribe(async (book: BookEntity) => {
        book.externalId = book.externalId ? book.externalId : this.bitBook.id;
        this.bitBook = book;
        this.bitBook.toc = book?.toc;
        this.loadToc({
          shouldNotScrollToc: true,
          shouldInitializeBitVisibility: options?.shouldInitializeBitVisibility,
        });
      }, err => {
        console.error(err);
      });
  }

  loadToc(options?: { shouldNotScrollToc?: boolean, shouldInitializeBitVisibility?: boolean }) {
    console.log('loading toc');
    this.allBitsTableOfContents = this.getFilteredTocItems(this.bitBook.toc as Array<TocItem>); // #4343
    this.readerTocService.storeTocForBook(this.bitBook.externalId, this.bitBook.toc).subscribe();
    this.bitbookMqService.notifyReaderTocHasReloaded();

    if (!this.allBitsTableOfContents.find(x => x.ref === 'start')) {
      this.allBitsTableOfContents.unshift({
        index: -1,
        ref: 'start',
        item: '',
        type: BitType.BitBookSummary,
        title: this.bitBook.title,
        level: 1,
        isBookTitle: true,
      });
    }

    this.allBitsTableOfContents?.forEach((bit: TocItem, idx) => {
      bit.index = idx - 1;
      if (bit.level === 1 && !bit.isTrashed) {
        this.chapterIndexesInToc.push({ ref: bit.ref, index: idx });
      }
      if (bit.allAnnotations?.length) {
        bit.allAnnotations?.forEach((a) => {
          if (!a?.color) {
            a = this.setDefaultColorForAnnotation(a);
          }
        });
        bit.annotationsObj = {
          prefixAnnotations: bit.allAnnotations?.filter((a) => a.type === BitType.AnnotationBookmark || a.type === BitType.AnnotationFavorite),
          postfixAnnotations: bit.allAnnotations?.filter((a) => a.type === BitType.AnnotationNote || a.type === BitType.AnnotationHandwritten)
        };
      }
    });
    this.displayOnlyCurrentChapterContent(options);
    if (options?.shouldInitializeBitVisibility) {
      this.initializeBitVisibility();
    }
  }

  initializeBitVisibility(lastPosition?: { bitId: any, index: number }) {
    const lastPos = lastPosition
      ? of(lastPosition)
      : this.readerTrackingService.getLastPositionForBook(this.bitBook.externalId);
    lastPos.subscribe((lastPositionForBook: BookPosition) => {
      // todo optimize this to only span like 10 bits, index - 5 -> index + 5 once you find the index difference issue reason
      if (lastPositionForBook?.bitId) {
        const visibleBits = [];
        for (let i = 0; i < this.allBitsTableOfContents.length; i++) {
          const bit = this.allBitsTableOfContents[i];
          if (this.readerContentService.isBitInViewport(bit.ref)) {
            visibleBits.push(bit.ref);
          }
        }
        visibleBits?.forEach((v) => {
          const tocBit = this.allBitsTableOfContents.find((b: TocItem) => b.ref.toString() === v.toString());
          if (tocBit) {
            tocBit.isVisible = true;
          }
        });
      } else {
        if (!this.tableOfContents?.length) {
          return;
        }
        for (let i = 0; i < this.tableOfContents?.length; i++) {
          if (this.readerContentService.isBitInViewport(this.tableOfContents[i].ref)) {
            this.tableOfContents[i].isVisible = true;
          } else {
            break;
          }
        }
      }
    });
  }

  removeBitFromToc(removedBitId: string) {
    this.bitBook.toc = this.bitBook.toc.filter((b: BookEntityToc) => b.ref != removedBitId);
    this.loadToc();
  }

  displayOnlyCurrentChapterContent(options?: {
    shouldNotScrollToc?: boolean,
    shouldInitializeBitVisibility?: boolean
  }) {
    console.log('displaying current chapter content');
    this.topChapters = this.allBitsTableOfContents
      .filter((c: TocItem) => c.level === 1 && !c.isTrashed)
      .map((c: TocItem) => {
        return {
          ref: c.ref,
          index: c.index,
          label: c.isGenericTitle ? c.item : c.item ? `${c.item} ${c.title}` : c.title,
        };
      });
    this.chapterMenuItems = this.createChapterMenuItems();
    this.topChapters = this.topChapters.filter((c: TocItem) => !c.isSeparator);
    if (this.topChapters.length && this.selectedChapter?.type !== BitType.AnnotationBookmark) {
      let lastBit;
      const fragment = this.route.snapshot?.fragment && this.route.snapshot.fragment.toString();
      if (fragment) {
        lastBit = {
          bitId: fragment,
          index: this.allBitsTableOfContents.find((b: TocItem) => b.ref == fragment)?.index,
        };
      }
      const q = lastBit
        ? of(lastBit)
        : this.readerTrackingService.getLastPositionForBook(this.bitBook.externalId);
      q.subscribe((lastBt: BookPosition) => {
        const nextChapterIdx = this.topChapters.findIndex((c: TocItem) => c.index > lastBt?.index);
        if (nextChapterIdx !== -1) {
          this.selectedChapter = this.topChapters[nextChapterIdx - 1];
        } else if (this.topChapters?.length > 0) {
          this.selectedChapter = this.topChapters[0];
        } else {
          this.selectedChapter = this.topChapters[this.topChapters.length - 1];
        }
        this.updateToC(lastBt, true, options);
      });
    }
    if (this.annotationTypes.indexOf(this.selectedChapter?.type) !== -1) {
      this.selectCategory(this.selectedChapter.type);
    }
  }

  getAnnotationsCategoryNrOfItems(category: string): number {
    return this.allBitsTableOfContents.reduce((prev: number, curr: TocItem) => {
      if (curr?.allAnnotations?.length) {
        return prev + curr.allAnnotations.filter((a) => {
          return a.type === category;
        }).length;
      }
      return prev;
    }, 0);
  }

  getTrashedBitsCount(): number {
    return this.allBitsTableOfContents.filter(x => x.isTrashed).length;
  }

  openBin() {
    const binModalRef = this.ngbModal.open(ReaderNotebookBinComponent, {
      windowClass: 'reader-modal',
      backdrop: 'static',
      backdropClass: 'd-none',
      keyboard: true,
      animation: false,
    });
    const readerBasketComponentInstance = binModalRef.componentInstance as ReaderNotebookBinComponent;
    readerBasketComponentInstance.notebook = this.bitBook;
    this.modalTouchSwipeService.applyTouchSwipe(binModalRef);
  }

  selectCategory(category: BitType) {
    if (!category) {
      return;
    }
    this.tableOfContents = this.allBitsTableOfContents.filter((b) => {
      return b?.allAnnotations?.length && b.allAnnotations.some((a) => {
        return a.type === category;
      });
    }).map(x => this.setDisplayContentForBit(x));

    this.scrollTocToTop();
    this.previousChapter = null;
    this.nextChapter = null;
    this.selectChapter(this.chapterMenuItems.find((c) => c.type === category));
  }

  selectChapter(c: TocItem, navigateToChapter = false) {
    if (!c) {
      return;
    }
    this.selectedChapter = {
      ref: c.ref,
      label: c.label
        ? c.label
        : c.item
          ? `${c.item} ${c.title}`
          : c.title,
      type: c.type,
    };
    if (c.type !== BitType.AnnotationBookmark && c.type !== BitType.AnnotationNote && c.type !== BitType.AnnotationFavorite && c.type !== BitType.AnnotationHandwritten) {
      this.updateToC();
      if (navigateToChapter) {
        this.selectBit(c);
      }
    }
  }

  selectBit(b: TocItem, annotationId?: number) {
    if (annotationId) {
      (window as any).annotationId = annotationId;
    }
    const visibleBits = this.allBitsTableOfContents.filter(x => x.isVisible);
    if (visibleBits?.length && visibleBits[0].ref === b.ref && this.readerContentService.isBitInTopViewport(b.ref, 5)) {
      return;
    }
    console.log(`Selecting bit ${b.ref}|${b.index}`);
    this.readerContentService.isTocSelectingBit = true;
    this.bitbookMqService.notifyReaderBitSelected(b.ref);
  }

  private updateNextChapters() {
    const currSelectedChapterIndex = this.topChapters.findIndex(i => i.ref === this.selectedChapter.ref);
    this.previousChapter = currSelectedChapterIndex > 0 ? this.topChapters[currSelectedChapterIndex - 1] : null;
    this.nextChapter = currSelectedChapterIndex < this.topChapters.length - 1 ? this.topChapters[currSelectedChapterIndex + 1] : null;
  }

  private handleBitsVisibilityChange(visibleBits: BitsViewPortVisibility) {
    for (let i = 0; i < this.allBitsTableOfContents.length; i++) {
      this.allBitsTableOfContents[i].isVisible = visibleBits.bitsWithContentInViewPort.findIndex((b: BitApiWrapper) => b.id === this.allBitsTableOfContents[i].ref) !== -1;
    }

    let firstVisibleBit = visibleBits.bitsWithContentInViewPort[0]; // || visibleBits.bitsTouchingViewPort[0];
    if (!firstVisibleBit) {
      return;
    }
    let visibleTocBits = this.allBitsTableOfContents.filter((b: TocItem) => b.isVisible && !b.isHidden);
    if (!visibleTocBits?.length) {
      const currentBitIndex = this.tableOfContents.findIndex((b: TocItem) => b.ref === firstVisibleBit.id);
      this.markNeighboringBitsAsVisible(currentBitIndex);
      visibleTocBits = this.allBitsTableOfContents.filter((b: TocItem) => b.isVisible && !b.isHidden);
    }
    const firstVisibleTocBit = visibleTocBits[0];
    if (firstVisibleTocBit) {
      firstVisibleBit = visibleBits.bitsTouchingViewPort.find((b: BitApiWrapper) => b.id === firstVisibleTocBit.ref);
    }

    if (firstVisibleTocBit && !firstVisibleTocBit.isHidden) {
      this.scrollToCToBit(firstVisibleTocBit.ref);
    }

    if (firstVisibleBit) {
      const topChapter = this.getTopChapter(firstVisibleBit.chapterPath);
      if (topChapter?.ref !== this.selectedChapter?.ref && this.annotationTypes.indexOf(this.selectedChapter?.ref) === -1) {
        this.selectChapter(topChapter);
      }
    }

    this.readerContentService.isTocSelectingBit = false;
  }

  private getTopChapter(chapterPath: Array<TocItem>): TocItem {
    if (!chapterPath) {
      return null;
    }
    const selectedTopChapters = chapterPath.filter((c: TocItem) => c.level === 1 || c.level === 0);
    const selectedTopChapter = selectedTopChapters[selectedTopChapters.length - 1];
    return this.topChapters.find((c: TocItem) => c.ref === (selectedTopChapter?.ref || 'start'));
  }

  private scrollToCToBit(bitId: string) {
    const headerHeight = 80;

    let element = document.getElementById(`toc-bit-${bitId}`);
    if (!element) {
      return setTimeout(() => {
        this.scrollToCToBit(bitId);
      }, 500);
    }

    const myContainer = document.querySelector('.toc-content');
    if (!this.isElementInViewport(element, headerHeight)) {
      const visibleBits = this.allBitsTableOfContents.filter(x => x.isVisible || x.ref === bitId);
      const firstVisibleBit = visibleBits?.length ? visibleBits[0] : null;

      if (firstVisibleBit) {
        element = document.getElementById(`toc-bit-${firstVisibleBit.ref}`);
      }
      if (element) {
        const topPos = element.offsetTop;
        myContainer.scrollTop = topPos - headerHeight - 10;
      }
    }
  }

  @memoize()
  private displayTocLead(item: TocItem) {
    if (item?.resourceType === 'website' || item?.resourceType === 'websiteLink') {
      return null;
    }

    let ret = item.item; //this.parseMM(item.item);

    if (item?.type === 'page' || item.type === 'extractor-page' || item.type === 'extractor-page-with-blocks') {
      ret = `${this.translate.instant('Shared.Page')} ${item?.item ? item.item  : ''}`;
    }

    return ret;
  }

  @memoize()
  private displayTocElement(item: TocItem) {

    if (item?.level && item?.isGenericTitle) {
      return '';
    }

    if (item?.resourceType === 'website' || item?.resourceType === 'websiteLink') {
      return 'Website';
    }

    let ret = '';

    if (item.title) {
      ret += item.title; //this.parseMM(item.title);
    }
    if (!ret?.length && item?.type !== BitType?.Page && item?.type !== BitType?.ExtractorPage && item?.type !== BitType?.ExtractorPageWithBlocks) {
      ret = item.type;
    }
    if (item.type === BitType.AnnotationHandwritten) {
      ret = 'Scribble';
    }
    return ret;
  }

  parseMM(text: string): string {
    return this.bitmarkPipe.transform(text, BitmarkFormat.MM, 'html') as string;
  }

  private updateToC(lastPositionForBook?: { bitId: any, index: number }, isInitial?: boolean, options?: {
    shouldNotScrollToc?: boolean
  }) {
    let currSelectedChapterIndex;
    const currSelectedChapter = this.chapterIndexesInToc.find(i => i.ref === this.selectedChapter.ref);
    if (currSelectedChapter) {
      currSelectedChapterIndex = currSelectedChapter.index;
    }
    currSelectedChapterIndex = currSelectedChapterIndex !== -1 ? currSelectedChapterIndex : 0;
    this.tableOfContents = this.allBitsTableOfContents
      .slice(currSelectedChapterIndex)
      .reduce((prev, curr, i, arr) => {
        if (curr.level !== 1 || (curr.level === 1 && ((curr.ref === this.selectedChapter.ref && i === 0) || !curr.title))) {
          return prev.concat(curr);
        } else {
          arr.splice(i);
          return prev;
        }
      }, [])
      .map((x: TocItem) => {
        x.displayLead = this.displayTocLead(x);
        x.displayElement = this.displayTocElement(x);
        return x;
      });

    if (isInitial) {
      this.initializeBitVisibility(lastPositionForBook);
    }
    this.updateNextChapters();
    if (lastPositionForBook) {
      if (!options?.shouldNotScrollToc) {
        setTimeout(() => {
          const element = document.getElementById('toc-bit-' + lastPositionForBook.bitId);
          if (element) {
            element.scrollIntoView({ block: 'center', behavior: 'auto' });
          }
        });
      }
    } else {
      this.scrollTocToTop();
    }
  }

  private scrollTocToTop() {
    const toc = document.querySelector('.toc-content');
    if (toc) {
      toc.scrollTop = 0;
    }
  }

  // private markOutsideBitsAsNotVisible(event: { bitId: string, bitIndex: number }) {
  //   const visibleBits = this.allBitsTableOfContents.filter((b: TocItem) => b.isVisible);
  //   visibleBits?.forEach((b: TocItem) => {
  //     if (!this.readerContentService.isBitInViewport(b.ref)) {
  //       b.isVisible = false;
  //     }
  //   });
  // }

  bitIsVisible(b: TocItem) {
    return !(!this.readerContentService.isBitInViewport(b.ref) && (!b?.allAnnotations?.length || b.allAnnotations?.every((a: TocItemAnnotation) => !this.readerContentService.isBitInViewport(a.id.toString()))));
  }

  private markOutsideNeighboringBitsAsNotVisible(bitIndex: number) {
    const visibleBits = this.allBitsTableOfContents.filter((b) => b.isVisible);

    if (visibleBits?.length > 2) {
      visibleBits.slice(1, -1).forEach((b) => {
        if (!this.bitIsVisible(b)) {
          b.isVisible = false;
        }
      });
      if (this.bitIsVisible(visibleBits[0]) || this.bitIsVisible(visibleBits[visibleBits.length - 1])) {
        if (!this.bitIsVisible(visibleBits[0])) {
          visibleBits[0].isVisible = false;
        }
        if (!this.bitIsVisible(visibleBits[visibleBits.length - 1])) {
          visibleBits[visibleBits.length - 1].isVisible = false;
        }
      }
    }
    if (visibleBits.length === 2) {
      const vb = visibleBits.filter((b) => this.bitIsVisible(b));
      if (vb.length > 0) {
        visibleBits?.forEach((b) => {
          if (!this.bitIsVisible(b)) {
            b.isVisible = false;
          }
        });
      }
    }

    if (visibleBits?.length && !visibleBits?.some((b) => b.isVisible && (!b.isHidden || b.allAnnotations?.length))) {
      this.markNeighboringBitsAsVisible(bitIndex);
    }
  }

  private markNeighboringBitsAsVisible(bitIndex: number) {
    for (let i = bitIndex; i < this.tableOfContents?.length; i++) {
      if (this.tableOfContents[i] && (!this.tableOfContents[i].isHidden || this.tableOfContents[i].allAnnotations?.length)) {
        this.tableOfContents[i].isVisible = true;
        break;
      }
    }
    for (let i = bitIndex; i >= 0; i--) {
      if (this.tableOfContents[i] && (!this.tableOfContents[i].isHidden || this.tableOfContents[i].allAnnotations?.length)) {
        this.tableOfContents[i].isVisible = true;
        break;
      }
    }
  }

  private isElementInViewport(el, absoluteTopDistance) {
    const rect = el.getBoundingClientRect();
    return rect.top >= absoluteTopDistance && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
  }

  selectAnnotationFilterColor(color) {
    this.annotationColors?.forEach((c) => c.isSelected = false);
    color.isSelected = true;
    this.selectedColor = color.isDefaultFilter ? null : color.primary;
  }

  setDisplayContentForBit(x: TocItem) {
    if (x.level) {
      const lead = this.displayTocLead(x);
      x.displayElement = x.isGenericTitle ? `${lead}` : lead?.trim().length ? `${lead} - ${this.displayTocElement(x)}` : `${this.displayTocElement(x)}`;
    } else {
      x.displayLead = this.displayTocLead(x);
      x.displayElement = this.displayTocElement(x);
    }
    return x;
  }

  setDefaultColorForAnnotation(a: any) {
    if (a?.type === BitType.AnnotationFavorite) {
      a.color = DefaultBitAnnotationColors[3];
    }
    if (a?.type === BitType.AnnotationNote) {
      a.color = DefaultBitAnnotationColors[0];
    }
    if (a?.type === BitType.AnnotationBookmark) {
      a.color = DefaultBitAnnotationColors[2];
    }
    if (a?.type === BitType.AnnotationHandwritten) {
      a.color = DefaultBitAnnotationColors[0];
    }
    return a;
  }

  private getFilteredTocItems(tocItems: Array<TocItem>): Array<TocItem> {
    return tocItems.map(tocItem => {
      if (tocItem.type === 'chapter' && tocItem.level > 6) {
        tocItem.isHidden = true;
      }
      return tocItem;
    });
  }
}
