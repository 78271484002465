// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.person-card {
  width: var(--bitmark-app-flashcards-card-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-app-flashcards-card-size-height, var(--bitmark-x-size-height, inherit));
  position: relative;
  text-align: center;
  border-radius: 30px !important;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
}
.person-card .person-avatar {
  border-radius: 50% !important;
  width: 95px;
  height: 95px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  bottom: -70px;
  box-shadow: 0 5px 18px 0 rgba(0, 0, 0, 0.5);
}
.person-card .person-name {
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
}
.person-card .person-occupation {
  font-size: 11px;
  line-height: 13px;
  font-weight: normal;
  color: #adadad;
}
.person-card .person-description {
  font-size: 13px;
  line-height: 15px;
  font-weight: normal;
}
.person-card .person-follow .person-follow-label {
  font-size: 11px;
  line-height: 13px;
  font-weight: normal;
  color: #adadad;
}
.person-card .person-follow .person-follow-value {
  letter-spacing: -1px;
  font-size: 22px;
  font-weight: bold;
  line-height: 22px;
  color: dodgerblue;
}
.person-card .btn-follow-person {
  color: #000;
  font-weight: bold;
  width: 100%;
  background-color: #fff459;
  border: none;
  border-radius: 30px;
  line-height: 1.5;
  font-size: 13px;
  padding: 10px;
}
.person-card .person-card-front {
  padding: var(--bitmark-app-flashcards-card-size-padding, var(--bitmark-x-size-padding, 0));
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 15px;
  overflow: hidden;
}
.person-card .person-card-front {
  background-color: var(--bitmark-app-flashcards-card-front-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-app-flashcards-card-front-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-app-flashcards-card-front-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-app-flashcards-card-front-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-app-flashcards-card-front-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-app-flashcards-card-front-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-app-flashcards-card-front-background-animation-name) var(--bitmark-app-flashcards-card-front-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-app-flashcards-card-front-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-app-flashcards-card-front-background-backdrop-filter);
  font-family: var(--bitmark-app-flashcards-card-front-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-app-flashcards-card-front-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-app-flashcards-card-front-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-app-flashcards-card-front-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-app-flashcards-card-front-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-app-flashcards-card-front-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-app-flashcards-card-front-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-app-flashcards-card-front-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-app-flashcards-card-front-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-app-flashcards-card-front-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  text-align: center;
  justify-content: center;
}
.person-card .person-card-front > bitmark-text {
  flex: 1;
  max-height: calc(100% - 20px);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
