import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatchSolutionGroupedBit, MatchPair} from './match-solution-grouped.models';
import {BitResource} from '../bits.models';
import {BitEvalResult} from "../bit-eval.service";

@Component({
  selector: 'bitmark-match-solution-grouped',
  templateUrl: './match-solution-grouped.component.html',
  styleUrls: ['./match-solution-grouped.component.scss', '../bits.scss']
})
export class MatchSolutionGroupedComponent implements OnInit {
  @Input() bit?: MatchSolutionGroupedBit;
  @Input() readOnly = false;
  @Input() hasFeedback = false;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() changed = new EventEmitter<any>();

  solutions: Array<string> = [];

  ngOnInit() {
    this.solutions = this.bit.pairs
      .map((p: MatchPair) => p.values[0])
      .sort()
      .filter(x => x)
      .filter((x, idx, self) => self.indexOf(x) === idx);
  }

  static evalAnswer(bit: MatchSolutionGroupedBit): BitEvalResult {
    return {
      totalAnswers: bit.pairs.length,
      answeredAnswers: bit.pairs.filter(p => !!p.answer?.text).length,
      notAnsweredAnswers: bit.pairs.filter(p => !p.answer?.text).length,
      correctAnswers: bit.pairs.filter(p => p.answer?.text && p.values.includes(p.answer?.text)).length,
      incorrectAnswers: bit.pairs.filter(p => (p.answer?.text && !p.values.includes(p.answer?.text)) || !p.answer?.text).length
    };
  }
}
