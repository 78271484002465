// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview-landscape {
  max-width: 320px;
  filter: drop-shadow(0 0 5px #656769);
}

.preview-portrait {
  max-width: 180px;
  filter: drop-shadow(0 0 5px #656769);
}

.download-btn-container {
  background: none !important;
  line-height: initial;
}

.resource-document-wrapper {
  width: var(--bitmark-resource-document-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-resource-document-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-resource-document-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-resource-document-size-margin, var(--bitmark-x-size-margin, 0));
}
.resource-document-wrapper .resource-document-icon {
  padding: var(--bitmark-resource-document-icon-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-resource-document-icon-size-margin, var(--bitmark-x-size-margin, 0));
}
.resource-document-wrapper .resource-document-name {
  font-family: var(--bitmark-resource-document-name-font-family, var(--bitmark-main-font-family, var(--bitmark-x-font-family, var(--bitmark-main-font-family))));
  font-size: var(--bitmark-resource-document-name-font-size, var(--bitmark-main-font-size, var(--bitmark-x-font-size, var(--bitmark-main-font-size))));
  font-weight: var(--bitmark-resource-document-name-font-weight, var(--bitmark-main-font-weight, var(--bitmark-x-font-weight, var(--bitmark-main-font-weight))));
  font-style: var(--bitmark-resource-document-name-font-style, var(--bitmark-main-font-style, var(--bitmark-x-font-style, var(--bitmark-main-font-style))));
  -webkit-text-decoration: var(--bitmark-resource-document-name-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
          text-decoration: var(--bitmark-resource-document-name-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-x-font-text-decoration, var(--bitmark-main-font-text-decoration))));
  line-height: var(--bitmark-resource-document-name-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-x-font-line-height, var(--bitmark-main-font-line-height))));
  color: var(--bitmark-resource-document-name-font-color, var(--bitmark-main-font-color, var(--bitmark-x-font-color, var(--bitmark-main-font-color))));
  text-align: var(--bitmark-resource-document-name-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))));
  justify-content: var(--bitmark-resource-document-name-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))));
  padding: var(--bitmark-resource-document-name-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-resource-document-name-size-margin, var(--bitmark-x-size-margin, 0));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
